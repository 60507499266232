import './Arsia.css';
import React, { useState, useRef, useEffect } from 'react';
import ArsiaFooter from './ArsiaFooter';
import ArsiaHeader from './ArsiaHeader';
import { Link } from "react-router-dom";
import ArsiaHeaderMobile from './Arsia-header-mobile';
import FooterMobile from './Arsia--footer--mobile';
import axios from 'axios';
import { IMAGE_PATH } from '../utils/constant';


export default function ArsiaAbout() {
    const [isSearchCardOpen, setIsSearchCardOpen] = useState(false);
    const [aboutData, setAboutData] = useState({});
    const handleSearchIconClick = () => {
        setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
    };
    const handleArrowLeftClick = () => {
        setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
    };
    const [sidebarVisible, setSidebarVisible] = useState(false);

    const showSidebar = () => {
        setSidebarVisible(true);
    };

    const hideSidebar = () => {
        setSidebarVisible(false);
    };
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get('https://admin.arshiyasehgal.com/API/Web/getAbout');
                setAboutData(response.data.data[0]);
                console.log("data", response.data.data[0].des)
                console.log("jgskghsghsghs", response)
                console.log("jgskghsghsghs", `${IMAGE_PATH}/${aboutData.thumbnail}`)
                // setLoading(false);
            } catch (error) {
                console.log(error)
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <div className="Arsia--website--design">
                <ArsiaHeader />
                <div className="Arsia--about--flex--card">
                    <div className='Arsia--about--us--page about--us--left'>
                        <h1>{aboutData.title}</h1>
                        {/* <p>Arshiya Sehgal is a unique blend of specially designed, ethnic, and western wear. We are a fashion label, born in 2023. We are registered with the GST 09IXBPS8602G1Z3 and our homebase is Building No./Flat No.: 54, Road/Street: BLOCK C, Locality/Sub Locality: Sector 40, City/Town/Village: Noida, District: Gautambuddha Nagar, State: Uttar Pradesh – 201303.</p>
                        <p>At Arshiya Sehgal, we strive to create timeless pieces that evoke an aura of glamour, elegance, and ethereal charm. We offer you luxury items that are strikingly exquisite and irresistible. As a fashion label, we are committed to creating an everlasting experience for our customers and ensure each woman looks her stunning best.</p>
                        <p>
                            We are presently focused on womenswear and offer occasional wear. We are pleased to announce that we will be introducing our Men’s collection by the end of the next year.</p>
                   
                    */}
                        <div dangerouslySetInnerHTML={{ __html: aboutData && aboutData.des }} />
                        {/* <div>{aboutData.des}</div> */}

                    </div>
                    <div className='about--us--right'>
                        <img src={`${IMAGE_PATH}${aboutData.thumbnail}`}></img>
                    </div>
                </div>
                {/* <div className='arsia-about-second-section-images'>
                    <img className='img' src={`${IMAGE_PATH}/${aboutData.gallery5}`} />
                    <img className='img' src={`${IMAGE_PATH}${aboutData.gallery1}`} />
                    <img className='img' src={`${IMAGE_PATH}${aboutData.gallery2}`} />
                    <img className='img' src={`${IMAGE_PATH}${aboutData.gallery3}`} />
                    <img className='img' src={`${IMAGE_PATH}${aboutData.gallery4}`} />
                </div> */}
                <div className="Arsia--below--content--1--content">
                    <div className="Arsia--below--content--card--carousal">
                        <div className="Arsia--below--content--card--carousal--1--collection">
                            <img src={`${IMAGE_PATH}${aboutData.gallery5}`} alt="" style={{ width: "212px", height: "212px" }} />
                        </div>
                        <div className="Arsia--below--content--card--carousal--1--collection">
                            <img src={`${IMAGE_PATH}${aboutData.gallery1}`} alt="" style={{ width: "212px", height: "212px" }} />
                        </div>
                        <div className="Arsia--below--content--card--carousal--1--collection">
                            <img src={`${IMAGE_PATH}${aboutData.gallery2}`} alt="" style={{ width: "212px", height: "212px" }} />
                        </div>
                        <div className="Arsia--below--content--card--carousal--1--collection">
                            <img src={`${IMAGE_PATH}${aboutData.gallery3}`} alt="" style={{ width: "212px", height: "212px" }} />
                        </div>
                        <div className="Arsia--below--content--card--carousal--1--collection">
                            <img src={`${IMAGE_PATH}${aboutData.gallery4}`} alt="" style={{ width: "212px", height: "212px" }} />
                        </div>
                    </div>
                </div>
                <ArsiaFooter />
            </div>

            {/* mobile--view */}

            <div className="Arsia--website--design--mobile--view">


                <ArsiaHeaderMobile />


                <div className="Arsia--about--flex--card">
                <div className='Arsia--about--us--page about--us--left'>
                        <h1>{aboutData.title}</h1>
                        {/* <p>Arshiya Sehgal is a unique blend of specially designed, ethnic, and western wear. We are a fashion label, born in 2023. We are registered with the GST 09IXBPS8602G1Z3 and our homebase is Building No./Flat No.: 54, Road/Street: BLOCK C, Locality/Sub Locality: Sector 40, City/Town/Village: Noida, District: Gautambuddha Nagar, State: Uttar Pradesh – 201303.</p>
                        <p>At Arshiya Sehgal, we strive to create timeless pieces that evoke an aura of glamour, elegance, and ethereal charm. We offer you luxury items that are strikingly exquisite and irresistible. As a fashion label, we are committed to creating an everlasting experience for our customers and ensure each woman looks her stunning best.</p>
                        <p>
                            We are presently focused on womenswear and offer occasional wear. We are pleased to announce that we will be introducing our Men’s collection by the end of the next year.</p>
                   
                    */}
                        <div dangerouslySetInnerHTML={{ __html: aboutData && aboutData.des }} />
                        {/* <div>{aboutData.des}</div> */}

                    </div>
                    <div className='about--us--right'>
                        <img src={`${IMAGE_PATH}${aboutData.thumbnail}`}></img>
                    </div>
                </div>
                {/* 
   #Arshiya footer mobile view
 */}
                {/* <div className='arsia-about-second-section-images_mobile'>
                    <img className='img' src={`${IMAGE_PATH}${aboutData.gallery5}`} />
                    <img className='img' src={`${IMAGE_PATH}${aboutData.gallery1}`} />
                    <img className='img' src={`${IMAGE_PATH}${aboutData.gallery2}`} />
                    <img className='img' src={`${IMAGE_PATH}${aboutData.gallery3}`} />
                    <img className='img' src={`${IMAGE_PATH}${aboutData.gallery4}`} />
                </div> */}
                <div className="Arsia--below--content--1--content">
                    <div className="Arsia--below--content--card--carousal">
                        <div className="Arsia--below--content--card--carousal--1--collection">
                            <img src={`${IMAGE_PATH}${aboutData.gallery5}`} alt="" style={{ width: "140px", height: "140px" }} />
                        </div>
                        <div className="Arsia--below--content--card--carousal--1--collection">
                            <img src={`${IMAGE_PATH}${aboutData.gallery1}`} alt="" style={{ width: "140px", height: "140px" }} />
                        </div>
                        <div className="Arsia--below--content--card--carousal--1--collection">
                            <img src={`${IMAGE_PATH}${aboutData.gallery2}`} alt="" style={{ width: "140px", height: "140px" }} />
                        </div>
                        <div className="Arsia--below--content--card--carousal--1--collection">
                            <img src={`${IMAGE_PATH}${aboutData.gallery3}`} alt="" style={{ width: "140px", height: "140px" }} />
                        </div>
                        <div className="Arsia--below--content--card--carousal--1--collection">
                            <img src={`${IMAGE_PATH}${aboutData.gallery4}`} alt="" style={{ width: "140px", height: "140px" }} />
                        </div>
                    </div>
                </div>

                <FooterMobile />
            </div>
        </>
    );
}