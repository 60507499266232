import React, { useState, useEffect } from "react";
import "./Arsia.css";
import ArsiaHeader from "./ArsiaHeader";

import { Link } from "react-router-dom";
import ArsiaFooter from "./ArsiaFooter";

import ArsiaHeaderMobile from "./Arsia-header-mobile";
import FooterMobile from "./Arsia--footer--mobile";

import axios from "axios";
import { IMAGE_PATH } from "../utils/constant";
import { useHomePage } from "../features/siteManage/useHomepage";
import Loader from "../ui/Loader";

export default function ArsiaCollections() {
  const { isLoading, error, homepage } = useHomePage();

  console.log(homepage);

  const [collectionData, setCollectionData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://admin.arshiyasehgal.com/API/Web/getCollections"
        );
        setCollectionData(response.data.data);
        console.log("jgskghsghsghs", response.data.data);
        // setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  if (isLoading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  if (!homepage || homepage.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div>
      <div className="Arsia--website--design">
        <ArsiaHeader />

        <div className="Arsia--banner--collections--page">
          <div className="new_banners_collections_page">
            {collectionData?.map((collection) => (
              <Link
                key={collection.name}
                to={`/Collections/${encodeURIComponent(collection.name)}`}
                className="arsia--product--link arsia--product--link_new_banner"
              >
                  <img
                  className="new_image_1"
                    src={`${IMAGE_PATH}${collection.desk_img}`}
                    alt="collectionImageNotFound"
                  />
                  {/* <p
                    style={{ marginTop: "12px" }}
                    className="Arsia--below--content--card--carousal--1--price--p1--2--1"
                  >
                    {collection.name}
                  </p> */}
              </Link>
            ))}
          </div>
        </div>
        {/* <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                          <img src={hazelbanner} alt='collectionImageNotFound'  />
                          <div>title</div>
                       </div>
                       <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                          <img src={hazelbanner} alt='collectionImageNotFound'  />
                          <div>title</div>
                       </div>
                       <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                          <img src={hazelbanner} alt='collectionImageNotFound'  />
                          <div>title</div>
                       </div>
                       <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                          <img src={hazelbanner} alt='collectionImageNotFound'  />
                          <div>title</div>
                       </div> */}

        {/* <Link to={"/allproducts"} className="arsia--product--link">
                        <img className="image_1" src={hazelbanner} style={{marginTop:"10px"}}></img>
                    </Link>
                    <Link to={"/allproducts"} className="arsia--product--link">
                        <img className="image_1" src={hazelbanner}></img>
                    </Link>
                    <Link to={"/allproducts"} className="arsia--product--link">
                        <img className="image_1" src={hazelbanner}></img>
                    </Link> */}
        {/* <img className="image_2" src={coming_soon_banner}></img>
                    <img className="image_2" src={coming_soon_banner}></img> */}

        {/* <div className='Arsia--banner--2--collections--page'>
                        <img className="image_1" src={accesories_banner}></img>
                </div> */}

        {/* <div className='Arsia--collection--banner--below--content'>
                <p className='Arsia--collection--banner--below--content--p1'>Transitional styles that work according to your mood. </p>
            </div> */}
        <div className="Arsia--collection--below--content"></div>
        <div className="Arsia--mobile--content--1">
          <div className="Arsia--below--content--5">
            <p className="Arsia--below--content--1">Most Bought Pieces</p>
            <p className="Arsia--below--content--2">The love of every woman</p>
          </div>
          <div className="Arsia--below--content--1--content">
            <div className="Arsia--below--content--card--carousal">
              {homepage[0] &&
                homepage[0]?.most_bought_pieces?.map((product) => (
                  <Link to={product.url} className="arsia--product--link">
                    <div className="Arsia--below--content--card--carousal--1--collection">
                      <img
                        src={`${IMAGE_PATH}${product.img}`}
                        alt=""
                        height={"270px"}
                        width={"100%"}
                      />
                      <div className="Arsia--below--content--card--carousal--1--price">
                        <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                          {product.title}
                        </p>
                        {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                      </div>
                    </div>
                  </Link>
                ))}
              {/* <Link to={"/Sitare-Hazel"} className="arsia--product--link">
                                <div className="Arsia--below--content--card--carousal--1--collection">
                                    <img src={sitaremost1}></img>
                                    <div className="Arsia--below--content--card--carousal--1--price">
                                        <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                                            Sitare
                                        </p>
                                        <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to={"/laila-Hazel"} className="arsia--product--link">
                                <div className="Arsia--below--content--card--carousal--1--collection">
                                    <img src={lailamostbought}></img>
                                    <div className="Arsia--below--content--card--carousal--1--price">
                                        <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                                            Laila
                                        </p>
                                        <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to={"/Lady-In_red-Hazel"} className="arsia--product--link">
                                <div className="Arsia--below--content--card--carousal--1--collection">
                                    <img src={redmost1}></img>
                                    <div className="Arsia--below--content--card--carousal--1--price">
                                        <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                                            Lady in Red
                                        </p>
                                        <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to={"/Moonlight-Hazel"} className="arsia--product--link">
                                <div className="Arsia--below--content--card--carousal--1--collection">
                                    <img src={moonlightmost2}></img>
                                    <div className="Arsia--below--content--card--carousal--1--price">
                                        <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                                            Moonlight
                                        </p>
                                        <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                                    </div>
                                </div>
                            </Link> */}
            </div>
          </div>
        </div>

        <ArsiaFooter />
      </div>

      {/* Arsia--Mobile--view */}
      <div className="Arsia--website--design--mobile--view">
        <ArsiaHeaderMobile />
        <div className="Arsia--banner--1">
        {collectionData?.map((collection) => (
              <Link
                key={collection.name}
                to={`/Collections/${encodeURIComponent(collection.name)}`}
                className="arsia--product--link"
              >
                  <img
                  className="new_image_1"
                    src={`${IMAGE_PATH}${collection.desk_img}`}
                    alt="collectionImageNotFound"
                  />
                  {/* <p
                    style={{ marginTop: "12px" }}
                    className="Arsia--below--content--card--carousal--1--price--p1--2--1"
                  >
                    {collection.name}
                  </p> */}
              </Link>
            ))}
        </div>
        {/* <div className='Arsia--banner--1'>
                    <Link to={"/allproducts"} className="arsia--product--link">
                        <img src={hazelbanner_mob}></img>
                    </Link>
                    <img className='image_2' src={coming_soon_banner_mob}></img>
                    <img className='image_2' src={accesories_banner_mob}></img>
                </div> */}

        {/* <div className='Arsia--collection--banner--below--content'>
                    <p className='Arsia--collection--banner--below--content--p1'>Transitional styles that work according</p>
                </div> */}
        <div className="Arsia--mobile--content--1">
          <div className="Arsia--below--content--5">
            <p className="Arsia--below--content--1">Most Bought Pieces</p>
            <p className="Arsia--below--content--2">The love of every woman</p>
          </div>
          <div className="Arsia--below--content--1--content">
            <div className="Arsia--below--content--card--carousal">
              {homepage[0] &&
                homepage[0]?.most_bought_pieces?.map((product) => (
                  <Link to={product.url} className="arsia--product--link">
                    <div className="Arsia--below--content--card--carousal--1--collection">
                      <img
                        src={`${IMAGE_PATH}${product.img}`}
                        alt=""
                        height={"370px"}
                        width={"100%"}
                      />
                      <div className="Arsia--below--content--card--carousal--1--price">
                        <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                          {product.title}
                        </p>
                        {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                      </div>
                    </div>
                  </Link>
                ))}
              {/* <Link to={"/Sitare-Hazel"} className="arsia--product--link">
                <div className="Arsia--below--content--card--carousal--1--collection">
                  <img src={sitaremost1}></img>
                  <div className="Arsia--below--content--card--carousal--1--price">
                    <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                      Sitare
                    </p>
                    <p className="Arsia--below--content--card--carousal--1--price--p2--2">
                      Xs S M L XL XXL{" "}
                    </p>
                    <p className="Arsia--below--content--card--carousal--1--price--p3--2">
                      ₹15,699
                    </p>
                  </div>
                </div>
              </Link>
              <Link to={"/laila-Hazel"} className="arsia--product--link">
                <div className="Arsia--below--content--card--carousal--1--collection">
                  <img src={lailamostbought}></img>
                  <div className="Arsia--below--content--card--carousal--1--price">
                    <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                      Laila
                    </p>
                    <p className="Arsia--below--content--card--carousal--1--price--p2--2">
                      Xs S M L XL XXL{" "}
                    </p>
                    <p className="Arsia--below--content--card--carousal--1--price--p3--2">
                      ₹15,699
                    </p>
                  </div>
                </div>
              </Link>
              <Link to={"/Lady-In_red-Hazel"} className="arsia--product--link">
                <div className="Arsia--below--content--card--carousal--1--collection">
                  <img src={redmost1}></img>
                  <div className="Arsia--below--content--card--carousal--1--price">
                    <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                      Lady in Red
                    </p>
                    <p className="Arsia--below--content--card--carousal--1--price--p2--2">
                      Xs S M L XL XXL{" "}
                    </p>
                    <p className="Arsia--below--content--card--carousal--1--price--p3--2">
                      ₹15,699
                    </p>
                  </div>
                </div>
              </Link>
              <Link to={"/Moonlight-Hazel"} className="arsia--product--link">
                <div className="Arsia--below--content--card--carousal--1--collection">
                  <img src={moonlightmost2}></img>
                  <div className="Arsia--below--content--card--carousal--1--price">
                    <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                      Moonlight
                    </p>
                    <p className="Arsia--below--content--card--carousal--1--price--p2--2">
                      Xs S M L XL XXL{" "}
                    </p>
                    <p className="Arsia--below--content--card--carousal--1--price--p3--2">
                      ₹15,699
                    </p>
                  </div>
                </div>
              </Link> */}
            </div>
          </div>
        </div>

        <FooterMobile />
      </div>
      {/* Arsia--Mobile--view--end */}
    </div>
  );
}
