import React, { useState } from 'react';
import './Arsia--vertical--carousal.css';
import laila1 from '../image/laila1.png';
import laila2 from '../image/laila2.png';
import laila3 from '../image/laila3.png';
import verticar2 from './image/verticar2.png';
import verticar3 from './image/verticar3.png';




export default function ArsiaVerticalCarousal() {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };
    return (
        <div className="Arsia--carousal--1">
            <div className="Arsia--vertical--carousal--left">
                <div className="Arsia--vertical--carousal--left--card">
                <img src={laila1} onClick={() => handleImageClick(laila1)} />
                </div>
                <div  className="Arsia--vertical--carousal--left--card">
                <img src={laila2} onClick={() => handleImageClick(laila2)} />
                </div>
                <div  className="Arsia--vertical--carousal--left--card">
                <img src={laila3} onClick={() => handleImageClick(laila3)} />
                </div>
                <div  className="Arsia--vertical--carousal--left--card">
                <img src={laila2} onClick={() => handleImageClick(laila2)} />
                </div>
                <div  className="Arsia--vertical--carousal--left--card">
                <img src={laila3} onClick={() => handleImageClick(laila3)} />
                </div>
               
            </div>
            <div className="Arsia--vertical--carousal--right">
                {selectedImage ? (
                    <img src={selectedImage} alt="Selected" />
                ) : (
                    <img src={laila1} alt="Default" />
                )}
              

            </div>
        </div>
    );
}