import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addCart as apiAddCart } from "../../services/apiCart";

export const useAddCart = () => {
  const queryClient = useQueryClient();

  const { mutate: addCart, isPending: isAdding } = useMutation({
    mutationFn: (id) => apiAddCart(id),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["cart"],
      });
    },
    onError: (err) => {
      console.log(err);
    },
  });

  return { addCart, isAdding };
};
