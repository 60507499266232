import { useQuery } from "@tanstack/react-query";
import { getHomepage } from "../../services/apiManageSite";

export const useHomePage = () => {
  const { isLoading, error, data } = useQuery({
    queryKey: ["homepage"],
    queryFn: () => getHomepage(),
  });

  const { data: homepage } = data || {};

  return { isLoading, error, homepage };
};
