import './Arsia.css';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import heart2 from './image/heart2.svg';
import face from './image/face.svg';
import pintaa from './image/pintaa.svg';
import twitta from './image/twitta.svg';
import instaa from './image/instaa.svg';
import ArsiaVerticalCarousal from './Arsia-vetical-caraousal/Arsia--vertical--carousal';
import ArsiaHeader from './ArsiaHeader';
import Frame from './image/Frame.png';
import play from './image/play.png';
import { Carousel } from "antd";

import sitareproduct from './image/sitareproduct.png';
import sitareproduct1 from './image/sitareproduct1.png';
import sitareproduct2 from './image/sitareproduct2.png';
import sitareproduct3 from './image/sitareproduct3.png';
import ArsiaFooter from './ArsiaFooter';
import ArsiaHeaderMobile from './Arsia-header-mobile';
import FooterMobile from './Arsia--footer--mobile';
import sitarewidth from './image/sitarewidth.png';
import sitarethin from './image/sitarethin.png';
import sitarethin1 from './image/sitarethin1.png';
import sitarethin3 from './image/sitarethin3.png';
import sitaremobile from './image/sitaremobile.jpg';
import sitaremobile1 from './image/sitaremobile1.jpg';
import sitaremobile2 from './image/sitaremobile2.jpg';
import sitaremobile3 from './image/sitaremobile3.jpg';
import ArsiaSizePopup from './Arsia-size-popup';
import lailamostbought from './image/lailamostbought.png';
import moonlightmost2 from './image/moonlightmost2.png';
import sitaremost1 from './image/sitaremost1.png';
import redmost1 from './image/redmost1.png';

export default function ArsiaSitare() {
    useEffect(() => {
        // Scroll to the top of the page when navigating to the About page
        window.scrollTo(0, 0);
      }, []);
    
    const [isContentVisible, setIsContentVisible] = useState(false);

    const handleDescriptionClick = () => {
        setIsContentVisible(!isContentVisible);
    };
    const [isContentVisible1, setIsContentVisible1] = useState(false);

    const handleDescriptionClick1 = () => {
        setIsContentVisible1(!isContentVisible1);
    };
    const [isContentVisible2, setIsContentVisible2] = useState(false);

    const handleDescriptionClick2 = () => {
        setIsContentVisible2(!isContentVisible2);
    };
    const [isContentVisible3, setIsContentVisible3] = useState(false);

    const handleDescriptionClick3 = () => {
        setIsContentVisible3(!isContentVisible3);
    };
    const [isSearchCardOpen, setIsSearchCardOpen] = useState(false);
    const handleSearchIconClick = () => {
        setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
    };
    const handleArrowLeftClick = () => {
        setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
    };
    const [sidebarVisible, setSidebarVisible] = useState(false);

    const showSidebar = () => {
        setSidebarVisible(true);
    };

    const hideSidebar = () => {
        setSidebarVisible(false);
    };
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };
    const [isAddToCartClicked, setIsAddToCartClicked] = useState(false);

   
    const handleAddToCartClick = () => {
        if (selectedSize !== '') {
          setIsAddToCartClicked(!isAddToCartClicked); // Toggle the state
        } else {
          alert('Please select a size before adding to cart.');
        }
      };
    const [selectedSize, setSelectedSize] = useState('');
      const handleSizeSelect = (size) => {
        setSelectedSize(size);
      };
    return (
        <div>
            <div className='Arsia--website--design'>

                <ArsiaHeader />
                <div className='Arsia--carausal--and--cart'>
                    <div className='Arsia--caraousal'>
                        <div className="Arsia--carousal--1">
                            <div className="Arsia--vertical--carousal--left">
                                <div className='Arsia--below--content--card--carousal--left'>
                                    <div className="Arsia--vertical--carousal--left--card">
                                        <img src={sitarethin} onClick={() => handleImageClick(sitareproduct)} />
                                    </div>
                                    <div className="Arsia--vertical--carousal--left--card1">
                                        <img src={sitarewidth} onClick={() => handleImageClick(sitareproduct3)} />
                                    </div>
                                    <div className="Arsia--vertical--carousal--left--card">
                                        <img src={sitarethin1} onClick={() => handleImageClick(sitareproduct1)} />
                                    </div>
                                    <div className="Arsia--vertical--carousal--left--card1">
                                        <img src={sitarewidth} onClick={() => handleImageClick(sitareproduct3)} />
                                    </div>
                                    <div className="Arsia--vertical--carousal--left--card">
                                        <img src={sitarethin3} onClick={() => handleImageClick(sitareproduct3)} />
                                    </div>

                                </div>
                            </div>
                            <div className="Arsia--vertical--carousal--right">
                                {selectedImage ? (
                                    <img src={selectedImage} alt="Selected" />
                                ) : (
                                    <img src={sitareproduct} alt="Default" />
                                )}


                            </div>
                        </div>

                        <div className='Arsia--caraousal--video'>
                            <img src={sitareproduct3}></img>
                            <div className='Arsia--caraousal--video--1'>
                                <img src={play}></img>
                            </div>
                        </div>

                    </div>
                    <div className='Arsia--cart'>
                        <div className='Arsia--cart--1--card'>
                            <div className='Arsia--cart--1'>
                                <img src={heart2}></img>
                                <p>Add to wishlist</p>
                            </div>
                            <div className='Arsia--cart--1'>
                                <img src={instaa}></img>
                                <img src={face}></img>
                                <img src={twitta}></img>
                                <img src={pintaa}></img>

                            </div>
                        </div>
                        <div className='Arsia--cart--caraousal--card'>
                            <p>Sitaare</p>
                        </div>
                        <div className='Arsia--cart--1--card'>
                            <div className='Arsia--cart--1--2'>
                                <p>Rs 15,490</p>
                            </div>

                        </div>
                        <div className='Arsia--cart--1--card'>
                            <div className='Arsia--cart--1--2--text'>
                                <p>Featuring Sitaare, a limited-edition statement piece. Get your hands on this sequin coordinated set, for an extra dose of sparkle.


                                </p>
                            </div>

                        </div>
                        <div className='Arsia--cart--size--usa'>
                            <div className='Arsia--cart--size--usa--card'>
                                <p className='Arsia--cart--size--usa--card--p'>Size</p>
                               
                            </div>
                            <div className='Arsia--cart--size--usa--button'>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'Xs' ? 'selected' : ''}`} onClick={() => handleSizeSelect('Xs')}>Xs</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'S' ? 'selected' : ''}`} onClick={() => handleSizeSelect('S')}>S</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'M' ? 'selected' : ''}`} onClick={() => handleSizeSelect('M')}>M</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'L' ? 'selected' : ''}`} onClick={() => handleSizeSelect('L')}>L</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'XL' ? 'selected' : ''}`} onClick={() => handleSizeSelect('XL')}>XL</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'XXL' ? 'selected' : ''}`} onClick={() => handleSizeSelect('XXL')}>XXL</p>
                            </div>
                            {/* <div className='Arsia--cart--size--usa--card'>
                                <p className='Arsia--cart--size--usa--card--p'>Qty:</p>
                                <div className='Arsia--cart--size--usa--card--1--add'>
                                    <p >-</p>
                                    <p>0</p>
                                    <p >+</p>
                                </div>
                            </div>  */}
                            {/* <Link to={"https://api.whatsapp.com/send?phone=919810889012&text=Hi!%20I%20just%20saw%20this%20gorgeous%20dress%20at%20your%20website.%20How%20can%20I%20add%20it%20to%20my%20wardrobe?"} className='arsia--product--link'>
                                <div className='Arsia--cart--size--usa--card--price'>
                                    <p>Order on Demand</p>
                                </div>
                            </Link> */}
                             <div className="Arsia--add--to--cart--and--buy--now">
              <div>
      {isAddToCartClicked ? (
        <Link
        to={"/Cart"}
        className="arsia--product--link"
      >
        <div className="Arsia--cart--size--usa--card--price">
            
          <p>Go to cart</p>
        </div>
        </Link>
      ) : (
        <div className="Arsia--cart--size--usa--card--price" onClick={handleAddToCartClick}>
          <p>Add to cart</p>
        </div>
      )}
    </div>
                {/* <Link
                  to={"/paymentGatewayPage"}
                  className="arsia--product--link"
                >
                  <div className="Arsia--cart--size--usa--card--price">
                    <p>Buy Now</p>
                  </div>
                </Link> */}
              </div>
                            {/* <div className='Arsia--cart--size--usa--card--free--ship'>
                                <p>Free 3-5 day shipping </p>
                                <p> •  Tool-free assembly</p>
                                <p> •  30-day trial</p>
                            </div> */}
                        </div>
<ArsiaSizePopup/>
                        <div className='Arsia--cart--border--1'></div>
                        <div className='Arsia--cart--dropdown--discription'>
                            <div >
                                <div className='Arsia--cart--dropdown--discription--1' onClick={handleDescriptionClick}>
                                    <p>Description</p>
                                    <img src={Frame} alt="Description Icon" />
                                </div>
                                {isContentVisible && (
                                    <div className='Arsia--cart--1--2--text'>
                                        <p>
                                            Comes with a straight fit skirt, and a one-shoulder top.  
                                            To add to the drama, we have added a super cute bow that is stitched along with the skirt that gives you the ultimate red-carpet look.

                                            <br></br>
                                            <br></br>   Exclusive sequins fabric. <br></br>
                                            <br></br>  Comes with a nude or green lining. <br></br>
                                            <br></br>  This top is non padded. <br></br>
                                            <br></br>  Zipping on the left side of the skirt, and the silhouette. <br></br>
                                            <br></br>  High thigh slit. <br></br>
                                            <br></br>  The slit can be customised. <br></br>
                                            <br></br>  True to size.



                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='Arsia--cart--border--1'></div>

                        <div className='Arsia--cart--dropdown--discription'>
                            <div >
                                <div className='Arsia--cart--dropdown--discription--1' onClick={handleDescriptionClick1}>
                                    <p>Fabric</p>
                                    <img src={Frame} alt="Description Icon" />
                                </div>
                                {isContentVisible1 && (
                                    <div className='Arsia--cart--1--2--text'>
                                        <p>Sequin</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='Arsia--cart--border--1'></div>
                        <div className='Arsia--cart--dropdown--discription'>
                            <div >
                                <div className='Arsia--cart--dropdown--discription--1' onClick={handleDescriptionClick2}>
                                    <p>Product Care</p>
                                    <img src={Frame} alt="Description Icon" />
                                </div>
                                {isContentVisible2 && (
                                    <div className='Arsia--cart--1--2--text'>
                                        <p>We suggest dry clean, as the product is very delicate. The model is wearing the size ‘XS’. </p>

                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='Arsia--cart--border--1'></div>
                        <div className='Arsia--cart--dropdown--discription'>
                            <div >
                                <div className='Arsia--cart--dropdown--discription--1' onClick={handleDescriptionClick3}>
                                    <p>Disclaimer</p>
                                    <img src={Frame} alt="Description Icon" />
                                </div>
                                {isContentVisible3 && (
                                    <div className='Arsia--cart--1--2--text'>
                                        <p>The product colour can slightly vary due to lighting effects during photoshoots.  </p>

                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Arsia--below--content--5'>
          <p className='Arsia--below--content--1--3'>
            Most Bought Pieces
          </p>
          <p className='Arsia--below--content--2--3'>
            the love of every woman
          </p>
        </div>
        <div className='Arsia--collection--below--content--part'>
              <div className='Arsia--below--content--card--carousal'>
                    <Link to={"/Sitare-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection'>
                        <img src={sitaremost1}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2--1'>Sitare </p>
                            {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                        </div>
                    </div>
                    </Link>
                    <Link to={"/laila-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection'>
                        <img src={lailamostbought}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2--1'>Laila</p>
                            {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                        </div>
                    </div>
                    </Link>
                    <Link to={"/Lady-In_red-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection'>
                        <img src={redmost1}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2--1'>Lady in Red </p>
                            {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                        </div>
                    </div>
                    </Link>
                    <Link to={"/Moonlight-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection'>
                        <img src={moonlightmost2}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2--1'>Moonlight </p>
                            {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                        </div>
                    </div>
                    </Link>
                </div>
            </div>
                <ArsiaFooter />
            </div>

            {/* Arsia--Mobile--view */}
            <div className="Arsia--website--design--mobile--view">
               <ArsiaHeaderMobile/>
                <div className='Arsia--product--mobile--view'>
                    <Carousel autoplay dots={false}>

                        <div className="Arsia--product--mobile--view--img">
                            <img src={sitaremobile}></img>
                        </div>
                        <div className="Arsia--product--mobile--view--img">
                            <img src={sitaremobile1}></img>
                        </div>
                        <div className="Arsia--product--mobile--view--img">
                            <img src={sitaremobile2}></img>
                        </div>
                        <div className="Arsia--product--mobile--view--img">
                            <img src={sitaremobile3}></img>
                        </div>

                    </Carousel>
                </div>
                <div className='Arsia--carausal--and--cart'>
                    <div className='Arsia--caraousal'>
                        <ArsiaVerticalCarousal />
                        <div className='Arsia--caraousal--video'>
                            <img src={sitareproduct2}></img>
                            <div className='Arsia--caraousal--video--1'>
                                <img src={play}></img>
                            </div>
                        </div>

                    </div>
                    <div className='Arsia--cart'>
                        <div className='Arsia--cart--1--card'>
                            <div className='Arsia--cart--caraousal--card'>
                                <p>Sitaare</p>
                            </div>
                            <div className='Arsia--cart--1'>
                                <img src={instaa}></img>
                                <img src={face}></img>
                                <img src={twitta}></img>
                                <img src={pintaa}></img>
                            </div>
                        </div>
                        <div className='Arsia--cart--1--card'>
                            <div className='Arsia--cart--1--2'>
                                <p>Rs 15,490</p>
                            </div>
                        </div>
                        <div className='Arsia--cart--1--card'>
                            <div className='Arsia--cart--1--2--text'>
                                <p> Featuring Sitaare, a limited-edition statement piece. Get your hands on this sequin coordinated set, for an extra dose of sparkle.


                                    .</p>
                            </div>

                        </div>
                        <div className="Arsia--cart--size--usa">
              <div className='Arsia--cart--size--usa--card'>
                                <p className='Arsia--cart--size--usa--card--p'>Size</p>
              </div>
                           
              <div className='Arsia--cart--size--usa--button'>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'Xs' ? 'selected' : ''}`} onClick={() => handleSizeSelect('Xs')}>Xs</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'S' ? 'selected' : ''}`} onClick={() => handleSizeSelect('S')}>S</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'M' ? 'selected' : ''}`} onClick={() => handleSizeSelect('M')}>M</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'L' ? 'selected' : ''}`} onClick={() => handleSizeSelect('L')}>L</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'XL' ? 'selected' : ''}`} onClick={() => handleSizeSelect('XL')}>XL</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'XXL' ? 'selected' : ''}`} onClick={() => handleSizeSelect('XXL')}>XXL</p>
                            </div>
                            <ArsiaSizePopup />
              {/* <div className='Arsia--cart--1--card'>
                                <div className='Arsia--cart--size--usa--card'>
                                    <p className='Arsia--cart--size--usa--card--p'>Qty:</p>
                                    <div className='Arsia--cart--size--usa--card--1--add'>
                                        <p >-</p>
                                        <p>0</p>
                                        <p >+</p>
                                    </div>
                                </div>
                                <div className='Arsia--cart--1'>
                                    <img src={heart2}></img>
                                    <p>Add to wishlist</p>
                                </div>

                            </div> */}

              {/* 
                            <Link to={"https://api.whatsapp.com/send?phone=919810889012&text=Hi!%20I%20just%20saw%20this%20gorgeous%20dress%20at%20your%20website.%20How%20can%20I%20add%20it%20to%20my%20wardrobe?"} className='arsia--product--link'>
                            <div className='Arsia--cart--size--usa--card--price'>
                                <p>Order on Demand</p>
                            </div>
                            </Link> */}
               <div className="Arsia--add--to--cart--and--buy--now">
              <div>
      {isAddToCartClicked ? (
        <Link
        to={"/Cart"}
        className="arsia--product--link"
      >
        <div className="Arsia--cart--size--usa--card--price">
            
          <p>Go to cart</p>
        </div>
        </Link>
      ) : (
        <div className="Arsia--cart--size--usa--card--price" onClick={handleAddToCartClick}>
          <p>Add to cart</p>
        </div>
      )}
    </div>
                {/* <Link
                  to={"/paymentGatewayPage"}
                  className="arsia--product--link"
                >
                  <div className="Arsia--cart--size--usa--card--price">
                    <p>Buy Now</p>
                  </div>
                </Link> */}
              </div>
              {/* <div className="Arsia--cart--size--usa--card--free--ship">
                <p>Free 3-5 day shipping </p>
                <p> • Tool-free assembly</p>
                <p> • 30-day trial</p>
              </div> */}
            </div>
         
                       

                        <div className='Arsia--cart--border--1'></div>
                        <div className='Arsia--cart--dropdown--discription'>
                            <div >
                                <div className='Arsia--cart--dropdown--discription--1' onClick={handleDescriptionClick}>
                                    <p>Description</p>
                                    <img src={Frame} alt="Description Icon" />
                                </div>
                                {isContentVisible && (
                                    <div className='Arsia--cart--1--2--text'>
                                          <p>
                                            Comes with a straight fit skirt, and a one-shoulder top.  
                                            To add to the drama, we have added a super cute bow that is stitched along with the skirt that gives you the ultimate red-carpet look.

                                            <br></br>
                                            <br></br>   Exclusive sequins fabric. <br></br>
                                            <br></br>  Comes with a nude or green lining. <br></br>
                                            <br></br>  This top is non padded. <br></br>
                                            <br></br>  Zipping on the left side of the skirt, and the silhouette. <br></br>
                                            <br></br>  High thigh slit. <br></br>
                                            <br></br>  The slit can be customised. <br></br>
                                            <br></br>  True to size.



                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='Arsia--cart--border--1'></div>

                        <div className='Arsia--cart--dropdown--discription'>
                            <div >
                                <div className='Arsia--cart--dropdown--discription--1' onClick={handleDescriptionClick1}>
                                    <p>Fabric</p>
                                    <img src={Frame} alt="Description Icon" />
                                </div>
                                {isContentVisible1 && (
                                    <div className='Arsia--cart--1--2--text'>
                                        <p>Sequin</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='Arsia--cart--border--1'></div>
                        <div className='Arsia--cart--dropdown--discription'>
                            <div >
                                <div className='Arsia--cart--dropdown--discription--1' onClick={handleDescriptionClick2}>
                                    <p>Product Care</p>
                                    <img src={Frame} alt="Description Icon" />
                                </div>
                                {isContentVisible2 && (
                                    <div className='Arsia--cart--1--2--text'>

                                        <p>We suggest dry clean, as the product is very delicate. The model is wearing the size ‘XS’.   </p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='Arsia--cart--border--1'></div>
                        <div className='Arsia--cart--dropdown--discription'>
                            <div >
                                <div className='Arsia--cart--dropdown--discription--1' onClick={handleDescriptionClick3}>
                                    <p>Disclaimer</p>
                                    <img src={Frame} alt="Description Icon" />
                                </div>
                                {isContentVisible3 && (
                                    <div className='Arsia--cart--1--2--text'>

                                        <p>The product colour can slightly vary due to lighting effects during photoshoots. </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='Arsia--mobile--content--1'>
          <div className='Arsia--below--content--5'>
            <p className='Arsia--below--content--1'>
              Most Bought Pieces
            </p>
            <p className='Arsia--below--content--2'>
              The love of every woman
            </p>
          </div>
          <div className='Arsia--below--content--1--content'>
          <div className='Arsia--below--content--card--carousal'>
          <Link to={"/Sitare-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection'>
                        <img src={sitaremost1}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2--1'>Sitare </p>
                            {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                        </div>
                    </div>
                    </Link>
                    <Link to={"/laila-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection'>
                        <img src={lailamostbought}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2--1'>Laila</p>
                            {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                        </div>
                    </div>
                    </Link>
                    <Link to={"/Lady-In_red-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection'>
                        <img src={redmost1}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2--1'>Lady in Red </p>
                            {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                        </div>
                    </div>
                    </Link>
                    <Link to={"/Moonlight-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection'>
                        <img src={moonlightmost2}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2--1'>Moonlight </p>
                            {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                        </div>
                    </div>
                    </Link>
              </div>
          </div>
        </div>
                </div>
              <FooterMobile/>
            </div>
            {/* Arsia--Mobile--view--end */}
        </div>
    );
}