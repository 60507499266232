export const getHomepage = async () => {
  try {
    const res = await fetch(
      // "https://admin.arshiyasehgal.com/API/Homepage/getHomepage"
      "https://admin.arshiyasehgal.com/API/Homepage/getHomepage"
    );
    const data = await res.json();
    console.log(data);
    if (data.status !== true) throw new Error(data.message);
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};
