import React, { useRef, useEffect } from "react";
import "./Arsia.css";
import { Link } from "react-router-dom";

import ArsiaHeader from "./ArsiaHeader";
import ArsiaFooter from "./ArsiaFooter";

import asbelowmobile from "./image/asbelowmobile.png";
import ArsiaHeaderMobile from "./Arsia-header-mobile";
import FooterMobile from "./Arsia--footer--mobile";
import ArsiaVideoWeb from "./Arsia--video--popup/Arsia-video-web";
import { useHomePage } from "../features/siteManage/useHomepage";
import Loader from "../ui/Loader";
import { IMAGE_PATH } from "../utils/constant";

export default function Arsia() {
  const { isLoading, error, homepage } = useHomePage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  if (!homepage || homepage.length === 0) {
    return <div>No data available</div>;
  }

  const {
    top_header_title,
    top_banner_pc,
    top_banner_mobile,
    top_caption,
    bottom_banner,
    bottom_banner_url,
  } = homepage[0];

  const collectionData = homepage[0].collections;
  console.log(bottom_banner_url);

  const handleImageClick = () => {
    window.location.href = bottom_banner_url;
  };
  return (
    <div>
      <div className="Arsia--website--design">
        <ArsiaHeader topHeaderData={top_header_title} />
        <div className="Arsia--banner">
          <img src={`${IMAGE_PATH}${top_banner_pc}`} alt="img"></img>
        </div>
        <div className="Arsia--banner--below--content">
          <p> {top_caption}</p>
        </div>
        <div className="Arsia--below--content">
          <div className="Arsia--below--content--card--carousal--link--1">
            <div className="Arsia--below--content--card--carousal--link--1--5">
              {collectionData && collectionData.length !== 0 ? (
                collectionData?.map((collection) => (
                  <div className="Arsia--below--content--card--carousal--1--hm">
                    <Link
                      key={collection.name}
                      to={`/Collections/${encodeURIComponent(collection.name)}`}
                      style={{ textDecoration: "none" }}
                      className="arsia--product--link"
                    >
                      <img
                        src={`${IMAGE_PATH}${collection.desk_img}`}
                        alt="img"
                        style={{ objectFit: "fill" }}
                      ></img>
                      <div className="Arsia--below--content--card--carousal--1--price">
                        <p className="Arsia--below--content--card--carousal--1--price--p1--hm">
                          {collection.name}
                        </p>
                      </div>
                    </Link>
                    {/* <Link
                      to={"/A.S-limited-editions"}
                      className="arsia--product--link"
                    >
                      <div className="Arsia--below--content--card--carousal--1--price">
                        <p className="Arsia--below--content--card--carousal--1--price--p1--hm">
                          {collection.name}
                        </p>
                      </div>
                    </Link> */}
                  </div>
                ))
              ) : (
                <div>Collections Coming Soon.....</div>
              )}
              {/* <div className="Arsia--below--content--card--carousal--1--hm">
                <Link
                  to={"/A.S-limited-editions"}
                  className="arsia--product--link"
                >
                  <img src={`${IMAGE_PATH}${homepage[0].collections[0].image}`} alt="img"></img>
                </Link>
                <Link
                  to={"/A.S-limited-editions"}
                  className="arsia--product--link"
                >
                  <div className="Arsia--below--content--card--carousal--1--price">
                    <p className="Arsia--below--content--card--carousal--1--price--p1--hm">
                      {homepage[0].collections[0].name}
                    </p>
                  </div>
                </Link>
              </div> */}

              {/* <div className="Arsia--below--content--card--carousal--1--hm">
                <Link to={"/A.S-hand-picks"} className="arsia--product--link">
                <img src={`${IMAGE_PATH}${homepage[0].collections[1].image}`} alt="img"></img>
                </Link>
                <Link to={"/A.S-hand-picks"} className="arsia--product--link">
                  <div className="Arsia--below--content--card--carousal--1--price">
                    <p className="Arsia--below--content--card--carousal--1--price--p1--hm">
                    {homepage[0].collections[1].name}
                    </p>
                  </div>
                </Link>
              </div> */}
            </div>
          </div>
          <div className="Arsia--below--content--5">
            <p className="Arsia--below--content--1">ExposÉ</p>
            <p className="Arsia--below--content--2">Every Angle exposed</p>
          </div>
          <div className="Arsia--below--content--card--carousal--6">
            <ArsiaVideoWeb videoData={homepage[0].exposes} />
          </div>
        </div>

        {/* 
           #Arshiya image with Video Popup
        */}
        <div className="Arsia--below--content--card--carousal--8">
          <div className="Arsia--below--video--banner--1">
            {/* <a href={bottom_banner_url}> */}
            <img
              src={`${IMAGE_PATH}${bottom_banner}`}
              alt="img"
              style={{ cursor: "pointer" }} // Optional: to show pointer cursor on hover
              onClick={handleImageClick}
            />
            {/* </a> */}

            {/* <div className='Arsia--below--video--banner--1--exclusive'>
              <p className='Arsia--below--video--banner--1--exclusive--p'>A.S.</p>
              <p className='Arsia--below--video--banner--1--exclusive--p1'>Exclusives</p>
             </div> */}
          </div>
        </div>

        {/*
         #Arshiya most bought pieces 
         */}
        <div className="Arsia--below--content--5">
          <p className="Arsia--below--content--1--3">Most Bought Pieces</p>
          <p className="Arsia--below--content--2--3">the love of every woman</p>
        </div>
        <div className="Arsia--collection--below--content--part"> 
          <div className="Arsia--below--content--card--carousal">
            {homepage[0].most_bought_pieces?.map((product) => (
              <Link to={product.url} className="arsia--product--link">
                <div className="Arsia--below--content--card--carousal--1--collection">
                  <img
                    src={`${IMAGE_PATH}${product.img}`}
                    alt=""
                    height={"270px"}
                    width={"100%"}
                  />
                  <div className="Arsia--below--content--card--carousal--1--price">
                    <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                      {product.title}
                    </p>
                    {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                  </div>
                </div>
              </Link>
            ))}

            {/* <Link to={"/Sitare-Hazel"} className="arsia--product--link">
              <div className="Arsia--below--content--card--carousal--1--collection">
                <img src={sitaremost1} alt="" />
                <div className="Arsia--below--content--card--carousal--1--price">
                  <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                    Sitare
                  </p>
                  <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                </div>
              </div>
            </Link> */}
            {/* <Link to={"/laila-Hazel"} className="arsia--product--link">
              <div className="Arsia--below--content--card--carousal--1--collection">
                <img src={lailamostbought} alt="" />
                <div className="Arsia--below--content--card--carousal--1--price">
                  <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                    Laila
                  </p>
                  <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                </div>
              </div>
            </Link>
            <Link to={"/Lady-In_red-Hazel"} className="arsia--product--link">
              <div className="Arsia--below--content--card--carousal--1--collection">
                <img src={redmost1} alt="" />
                <div className="Arsia--below--content--card--carousal--1--price">
                  <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                    Lady in Red
                  </p>
                  <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                </div>
              </div>
            </Link>
            <Link to={"/Moonlight-Hazel"} className="arsia--product--link">
              <div className="Arsia--below--content--card--carousal--1--collection">
                <img src={moonlightmost2} alt="" />
                <div className="Arsia--below--content--card--carousal--1--price">
                  <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                    Moonlight
                  </p>
                  <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                </div>
              </div>
            </Link> */}
          </div>
        </div>

        {/* 
           #arshiya footer caraousal
        */}

        {/* <div>
          <ArsiaFooterCarousal />
        </div> */}

        {/* Arshiya  Footer */}
        <ArsiaFooter />
      </div>

      {/*                      */}
      {/* Arsia--Mobile--view  */}
      {/*                      */}

      <div className="Arsia--website--design--mobile--view">
        {/* 
           #Arsiya Header
        */}
        <ArsiaHeaderMobile />
        {/* 
       #Arshiya Banner
     */}
        <div className="Arsia--banner">
          <img src={`${IMAGE_PATH}${top_banner_mobile}`} alt="img"></img>
        </div>
        <div className="Arsia--banner--below--content">
          <p>{top_caption}</p>
        </div>

        {/* 
          #Arshiya Video Story
         */}
        {/* <ArsiaVideoTab /> */}

        {/* 
          #Arshiya limed edition clothes  */}
        <div className="Arsia--below--content--card--carousal--6--7">
          <div className="Arsia--below--content--card--carousal--6">
            {collectionData && collectionData.length !== 0 ? (
              collectionData?.map((collection) => (
                <div className="Arsia--below--content--card--carousal--1--hm">
                  <Link
                    key={collection.name}
                    to={`/Collections/${encodeURIComponent(collection.name)}`}
                    style={{ textDecoration: "none" }}
                    className="arsia--product--link"
                  >
                    <img
                      src={`${IMAGE_PATH}${collection.desk_img}`}
                      style={{ objectFit: "fill" }}
                      alt="img"
                    ></img>
                    <div className="Arsia--below--content--card--carousal--1--price">
                      <p className="Arsia--below--content--card--carousal--1--price--p1--hm">
                        {collection.name}
                      </p>
                    </div>
                  </Link>
                  {/* <Link
                      to={"/A.S-limited-editions"}
                      className="arsia--product--link"
                    >
                      <div className="Arsia--below--content--card--carousal--1--price">
                        <p className="Arsia--below--content--card--carousal--1--price--p1--hm">
                          {collection.name}
                        </p>
                      </div>
                    </Link> */}
                </div>
              ))
            ) : (
              <div>Collections Coming Soon.....</div>
            )}
          </div>
        </div>

        {/* 
         #Arshiya Video Carousal
      */}
        <div className="Arsia--mobile--content">
          <div className="Arsia--below--content--5">
            <p className="Arsia--below--content--1">ExposÉ</p>
            <p className="Arsia--below--content--2">Every Angle exposed</p>
          </div>
          <ArsiaVideoWeb videoData={homepage[0].exposes} />
        </div>
        <div>
          <div className="Arsia--below--video--banner--1">
            <img
              src={`${IMAGE_PATH}${bottom_banner}`}
              alt="img"
              style={{ cursor: "pointer" }} // Optional: to show pointer cursor on hover
              onClick={handleImageClick}
            />
          </div>
        </div>

        {/* 
         Arshiya most bought pieces
       */}
        <div className="Arsia--mobile--content--1">
          <div className="Arsia--below--content--5">
            <p className="Arsia--below--content--1">Most Bought Pieces</p>
            <p className="Arsia--below--content--2">The love of every woman</p>
          </div>
          <div className="Arsia--below--content--1--content">
            <div className="Arsia--below--content--card--carousal">
              {homepage[0].most_bought_pieces?.map((product) => (
                <Link to={product.url} className="arsia--product--link">
                  <div className="Arsia--below--content--card--carousal--1--collection">
                    <img
                      src={`${IMAGE_PATH}${product.img}`}
                      alt=""
                      height={"370px"}
                      width={"100%"}
                    />
                    <div className="Arsia--below--content--card--carousal--1--price">
                      <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                        {product.title}
                      </p>
                      {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                    </div>
                  </div>
                </Link>
              ))}
              {/* <Link to={"/Sitare-Hazel"} className="arsia--product--link">
                <div className="Arsia--below--content--card--carousal--1--collection">
                  <img src={sitaremost1}></img>
                  <div className="Arsia--below--content--card--carousal--1--price">
                    <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                      Sitare
                    </p>
                    <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                  </div>
                </div>
              </Link>
              <Link to={"/laila-Hazel"} className="arsia--product--link">
                <div className="Arsia--below--content--card--carousal--1--collection">
                  <img src={lailamostbought}></img>
                  <div className="Arsia--below--content--card--carousal--1--price">
                    <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                      Laila
                    </p>
                    <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                  </div>
                </div>
              </Link>
              <Link to={"/Lady-In_red-Hazel"} className="arsia--product--link">
                <div className="Arsia--below--content--card--carousal--1--collection">
                  <img src={redmost1}></img>
                  <div className="Arsia--below--content--card--carousal--1--price">
                    <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                      Lady in Red
                    </p>
                    <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                  </div>
                </div>
              </Link>
              <Link to={"/Moonlight-Hazel"} className="arsia--product--link">
                <div className="Arsia--below--content--card--carousal--1--collection">
                  <img src={moonlightmost2}></img>
                  <div className="Arsia--below--content--card--carousal--1--price">
                    <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                      Moonlight
                    </p>
                    <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                  </div>
                </div>
              </Link> */}
            </div>
          </div>
        </div>
        {/* <div>
          <ArsiaFooterCarousal />
        </div> */}

        {/* 
           #Arshiya footer mobile view
         */}

        <FooterMobile />
      </div>
      {/* Arsia--Mobile--view--end */}
    </div>
  );
}
