import React, { useState, useRef, useEffect } from 'react';
import './Arsi--video--popup.css';
import khoobsuratvideo from '../image/khoobsuratvideo.mp4';
import fizavideo from '../image/fizavideo.mp4';
import blackdrama from '../image/blackdrama.mp4';
import { Link } from "react-router-dom";
import scarlet from '../image/scarlet.mp4';
import nightowl from '../image/nightowl.mp4';
import { IMAGE_PATH } from '../../utils/constant';




export default function ArsiaVideoWeb({videoData}) {
    const [videosData,setVideosData] = useState([]);
   
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (videoData && videoData.length > 0) {
            setVideosData(videoData);
            console.log("fasfasfasfafa",videosData)
        }
    }, [videoData]);

    console.table(["ahsfgafasbhsafgb",videosData[0],videosData])

    if (videosData.length === 0) {
        return <div>Loading...</div>;
    }
    return (
        <div>
            <div  >
                <div className='Arsia--below--content--card--carousal'>
                    {/* <div>
                        <div className='Arsia--below--content--card--carousal--video--image--3' >
                            <video   controlspreload="auto" autoPlay muted loop playsinline
                                style={{ width: 'auto', height: '450px' }} controlsList='nodownload'>
                                <source src ={`${IMAGE_PATH}${videosData[0].video}`} type="video/mp4" />
                            </video>
                          
                        </div>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2--1'>{videosData[0].title}</p>
                        </div>
                    </div> */}

                    {
                        videosData && videosData.map((video) => (
                            <div className='Arsia--below--content--card--carousal--video--image--3'>
                            <video   controlspreload="auto" muted loop playsinline
                                style={{ width: 'auto', height: '450px' }} controls >
                                <source src ={`${IMAGE_PATH}${video.video}`} type="video/mp4" />
                            </video>
                            <div className='Arsia--below--content--card--carousal--1--price'>
                                <p className='Arsia--below--content--card--carousal--1--price--p1--2--1'>{video.title}</p>
                            </div>
                        </div>
                        ))
                    }
                    {/* <div className='Arsia--below--content--card--carousal--video--image--3'>
                        <video   controlspreload="auto" autoPlay muted loop playsinline
                            style={{ width: 'auto', height: '450px' }} >
                            <source src ={`${IMAGE_PATH}${videosData[1].video}`} type="video/mp4" />
                        </video>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2--1'>{videosData[1].title}</p>
                        </div>
                    </div>
                    <div className='Arsia--below--content--card--carousal--video--image--3'>
                        <video   controlspreload="auto" autoPlay muted loop playsinline
                            style={{ width: 'auto', height: '450px' }} >
                            <source src ={`${IMAGE_PATH}${videosData[1].video}`} type="video/mp4" />
                        </video>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2--1'>{videosData[1].title}</p>
                        </div>
                    </div>
                    <div className='Arsia--below--content--card--carousal--video--image--3'>
                        <video   controlspreload="auto" autoPlay muted loop playsinline
                            style={{ width: 'auto', height: '450px' }} >
                            <source src ={`${IMAGE_PATH}${videosData[1].video}`} type="video/mp4" />
                        </video>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2--1'>{videosData[1].title}</p>
                        </div>
                    </div> */}
                    {/* <div className='Arsia--below--content--card--carousal--video--image--3' >
                        <video   controlspreload="auto" autoPlay muted loop playsinline
                            style={{ width: 'auto', height: '450px' }} >
                            <source src ={`${IMAGE_PATH}${videosData[2].video}`} type="video/mp4" />
                        </video>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2--1'>{videosData[2].title}</p>

                        </div>

                    </div> */}
                    {/* <div className='Arsia--below--content--card--carousal--video--image--3' >
                        <video   controlspreload="auto" autoPlay muted loop playsinline
                            style={{ width: 'auto', height: '450px' }} >
                            <source src ={`${IMAGE_PATH}${videosData[3].video}`} type="video/mp4" />
                        </video>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2--1'>{videosData[3].title}</p>

                        </div>

                    </div> */}
                    {/* <div className='Arsia--below--content--card--carousal--video--image--3' >
                        <video   controlspreload="auto" autoPlay muted loop playsinline style={{ width: 'auto', height: '450px' }} >
                            <source src={nightowl} type="video/mp4" />
                        </video>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2--1'>Night Out | Hazel</p>

                        </div>

                    </div> */}
                </div>
            </div>

        </div>
    );
}