import React, { useState, useRef, useEffect } from 'react';
import './Arsia.css';
import ArsiaHeader from './ArsiaHeader';

import { Link } from "react-router-dom";
import ArsiaFooter from './ArsiaFooter';

import ladyred from './image/ladyred.jpg';
import sitare2 from './image/sitare2.jpg';
import blue1 from './image/blue1.jpg';
import ArsiaHeaderMobile from './Arsia-header-mobile';
import FooterMobile from './Arsia--footer--mobile';
import limitedmobile from './image/limitedmobile.jpg';
import limitedweb1 from './image/limitedweb1.png';
import scarletcoll from './image/scarletcoll.png';

export default function ArsiaLimited() {

    const [isSearchCardOpen, setIsSearchCardOpen] = useState(false);

    const handleSearchIconClick = () => {
        setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
    };

    const handleArrowLeftClick = () => {
        setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
    };
    const [sidebarVisible, setSidebarVisible] = useState(false);

    const showSidebar = () => {
        setSidebarVisible(true);
    };

    const hideSidebar = () => {
        setSidebarVisible(false);
    };
    useEffect(() => {
        // Scroll to the top of the page when navigating to the About page
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <div className='Arsia--website--design'>
                <div className='Arsia--header'>
                    <p>Orders at Arshiya  Seghal are packed with love. </p>
                </div>
                <ArsiaHeader />
                <div className='Arsia--banner--1'>
                    <img src={limitedweb1}></img>
                </div>
                <div className='Arsia--collection--banner--below--content'>
                    <p className='Arsia--collection--banner--below--content--p1'>Flaunt The Signature Styles.</p>
                </div>
                <div className='Arsia--collection--below--content'></div>
                {/* <div className='Arsia--collection--below--content--filter'>
                <div className='Arsia--collection--below--content--filter--1'>
                    <img src={Menu}></img>
                    <img src={grid}></img>
                    <div className='Arsia--collection--border-line'></div>
                </div>
                <div className='Arsia--collection--below--content--filter--2'>
                    <div className='Arsia--collection--below--content--filter--2--sort--by'>
                        <p>Sort By: Featured</p>
                    </div>
                    <div className='Arsia--collection--below--content--filter--2--feature'>
                        <p>Filter</p>
                    </div>
            </div>
            </div> */}
                <div className='Arsia--collection--below--content--part'>
                    <div className='Arsia--collection--all-cards--1'>

                        <Link to={"/Midnight-Hazel"} className='arsia--product--link'>
                            <div className='Arsia--below--content--card--carousal--1--collection'>
                                <img src={blue1}></img>
                                <div className='Arsia--below--content--card--carousal--1--price'>
                                    <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Midnight Blues | Hazel</p>
                                    {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                                    <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹19,500</p>
                                </div>
                            </div>
                        </Link>
                        <Link to={"/Lady-In_red-Hazel"} className='arsia--product--link'>
                            <div className='Arsia--below--content--card--carousal--1--collection'>
                                <img src={ladyred}></img>
                                <div className='Arsia--below--content--card--carousal--1--price'>
                                    <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Lady in red | Hazel</p>
                                    {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                                    <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹18,290</p>
                                </div>
                            </div>
                        </Link>
                        <Link to={"/Scarlet-Hazel"} className='arsia--product--link'>
                            <div className='Arsia--below--content--card--carousal--1--collection'>
                                <img src={scarletcoll}></img>
                                <div className='Arsia--below--content--card--carousal--1--price'>
                                    <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Scarlet | Hazel</p>
                                    {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                                    <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹16,490</p>
                                </div>
                            </div>
                        </Link>
                        <Link to={"/Sitare-Hazel"} className='arsia--product--link'>
                            <div className='Arsia--below--content--card--carousal--1--collection'>
                                <img src={sitare2}></img>
                                <div className='Arsia--below--content--card--carousal--1--price'>
                                    <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Sitaare | Hazel</p>
                                    {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                                    <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,490</p>
                                </div>
                            </div>
                        </Link>



                    </div>
                </div>

                <ArsiaFooter />
            </div>

            {/* Arsia--Mobile--view */}
            <div className="Arsia--website--design--mobile--view">
                <ArsiaHeaderMobile />
                <div className='Arsia--banner--1'>
                    <img src={limitedmobile}></img>
                </div>
                <div className='Arsia--collection--banner--below--content'>
                    <p className='Arsia--collection--banner--below--content--p1'>Flaunt The Signature Styles.</p>
                </div>
                {/* <div className='Arsia--collection--below--content--filter'>
                <div className='Arsia--collection--below--content--filter--1'>
                    <img src={Menu}></img>
                    <img src={grid}></img>
                    <div className='Arsia--collection--border-line'></div>
                </div>
                <div className='Arsia--collection--below--content--filter--2'>
                    <div className='Arsia--collection--below--content--filter--2--sort--by'>
                        <p>Sort By</p>
                    </div>
                    <div className='Arsia--collection--below--content--filter--2--feature'>
                        <p>Filter</p>
                    </div>
            </div>
            </div> */}
                <div className='Arsia--collection--all-cards--1'>
                    {/* <Link to={"/laila-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection--page--8'>
                        <img src={laila}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Laila | Hazel</p>
                            <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,200</p>
                        </div>
                    </div>
                    </Link>
                    <Link to={"/Black-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection--page--8'>
                        <img src={blackdrama}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Black Drama | Hazel</p>
                            <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹14,290</p>
                        </div>
                    </div></Link>
                    <Link to={"/Madhubala-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection--page--8'>
                        <img src={madhubala}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>MadhuBala | Hazel</p>
                            <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹14,290</p>
                        </div>
                    </div>
                    </Link>
                    <Link to={"/Belle-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection--page--8'>
                        <img src={belle}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Belle | Hazel</p>
                            <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹13,800</p>
                        </div>
                    </div>
                    </Link>
                    <Link to={"/Fiza-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection--page--8'>
                        <img src={fizacoll}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Fiza | Hazel</p>
                            <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,000</p>
                        </div>
                    </div>
                    </Link>
                    <Link to={"/Soaked-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection--page--8'>
                        <img src={soaked}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Soaked Sun | Hazel</p>
                            <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹14290</p>
                        </div>
                    </div>
                    </Link>
                    <Link to={"/Khubsurat-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection--page--8'>
                        <img src={khubsurat}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Khoobsurat | Hazel</p>
                            <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,000</p>
                        </div>
                    </div>
                    </Link> */}
                    {/* <Link to={"/Moonlight-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection--page--8'>
                        <img src={moonlight}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>MoonLight | Hazel</p>
                            <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,000</p>
                        </div>
                    </div></Link> */}
                    <Link to={"/Midnight-Hazel"} className='arsia--product--link'>
                        <div className='Arsia--below--content--card--carousal--1--collection--page--8'>
                            <img src={blue1}></img>
                            <div className='Arsia--below--content--card--carousal--1--price'>
                                <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Midnight Blues | Hazel</p>
                                {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                                <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹19,500</p>
                            </div>
                        </div></Link>
                    <Link to={"/Lady-In_red-Hazel"} className='arsia--product--link'>
                        <div className='Arsia--below--content--card--carousal--1--collection--page--8'>
                            <img src={ladyred}></img>
                            <div className='Arsia--below--content--card--carousal--1--price'>
                                <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Lady in red | Hazel</p>
                                {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                                <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹18,290</p>
                            </div>
                        </div></Link>
                    <Link to={"/Scarlet-Hazel"} className='arsia--product--link'>
                        <div className='Arsia--below--content--card--carousal--1--collection--page--8'>
                            <img src={scarletcoll}></img>
                            <div className='Arsia--below--content--card--carousal--1--price'>
                                <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Scarlet | Hazel</p>
                                {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                                <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹16,490</p>
                            </div>
                        </div>
                    </Link>
                    <Link to={"/Sitare-Hazel"} className='arsia--product--link'>
                        <div className='Arsia--below--content--card--carousal--1--collection--page--8'>
                            <img src={sitare2}></img>
                            <div className='Arsia--below--content--card--carousal--1--price'>
                                <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Sitaare | Hazel</p>
                                {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                                <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,490</p>
                            </div>
                        </div></Link>

                </div>


                <FooterMobile />
            </div>
            {/* Arsia--Mobile--view--end */}

        </div>

    );

}