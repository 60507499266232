import React, { useState, useRef, useEffect } from "react";
import "./Arsia.css";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { objectToFormData, priceFormat } from "../utils/helper";
import { IMAGE_PATH } from "../utils/constant";
import axios from "axios";
import { useSelector } from "react-redux";

export default function ArsiaCollectionListView({ productData }) {
  const { isLoading, error, products } = productData;
  // const [products,setProductData] = useState([]);
  // const { search } = useSelector((state) => state.user);

  // const SearchApiHandler = async () => {
  //   try {
  //     const formdata = objectToFormData({
  //       keyword: search,
  //     });

  //     const res = await axios.post("https://admin.arshiyasehgal.com/API/Product/searchProduct",formdata)
  //     setProductData(res?.data?.data);
  //    console.log(res);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  // useEffect(() => {
  //   // SearchApiHandler();
  //   // console.log("ghadgashg",search);
  // },[search])

  console.log("products", products);

  const [isSearchCardOpen, setIsSearchCardOpen] = useState(false);

  const handleSearchIconClick = () => {
    setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
  };

  const handleArrowLeftClick = () => {
    setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
  };
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const showSidebar = () => {
    setSidebarVisible(true);
  };

  const hideSidebar = () => {
    setSidebarVisible(false);
  };

  const priceStyle = {
    textDecoration: "line-through",
    color: "#888",
    marginLeft: "3px",
  };

  const discountPriceStyle = {
    color: "black",
    // fontWeight: "bold",
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <div className="Arsia--website--design">
        <div className="Arsia--collection--below--content--part">
          <div className="Arsia--collection--all-cards--1">
            {products
              ?.slice()
              .reverse()
              .map((product, i) => {
                return (
                  <Link
                    key={i}
                    to={`/${product.name}-Hazel/${product.id}`}
                    className="arsia--product--link"
                  >
                    <div className="Arsia--below--content--card--carousal--1--collection">
                      <Carousel interval={null} indicators={false}>
                        {product.images.map((image, i) => {
                          return (
                            <Carousel.Item className="Arsia--below--content--card--carousal--1--collection">
                              <img
                                src={`${IMAGE_PATH}${image.img}`}
                                alt="img"
                              />
                            </Carousel.Item>
                          );
                        })}
                      </Carousel>

                      <div className="Arsia--below--content--card--carousal--1--price">
                        <p className="Arsia--below--content--card--carousal--1--price--p1--2">
                          {product.name}
                        </p>
                        {
                          product.discount === "0" ?
                            <p className="Arsia--below--content--card--carousal--1--price--p1--2">
                              <span style={discountPriceStyle}>
                                {priceFormat(+product.price)}
                              </span>
                            </p>
                            :
                            <p className="Arsia--below--content--card--carousal--1--price--p1--2">
                              <span style={discountPriceStyle}>
                                {priceFormat(+product.price)}
                              </span>
                              {"  "}
                              <span style={priceStyle}>
                                {priceFormat(+product.mrp)}
                              </span>{" "}
                              <span style={{ color: "#E74C3C", marginLeft: "3px" }}>
                                {product.discount}% OFF
                              </span>
                            </p>
                        }
                      </div>
                    </div>
                  </Link>
                );
              })}
          </div>
        </div>
      </div>

      {/* Arsia--Mobile--view */}
      <div className="Arsia--website--design--mobile--view">
        <div className="Arsia--collection--all-cards--1">
          {products
            ?.slice()
            .reverse()
            .map((product, i) => {
              return (
                <Link
                  key={i}
                  to={`/${product.name}-Hazel/${product.id}`}
                  className="arsia--product--link"
                >
                  <div className="Arsia--below--content--card--carousal--1--collection--page--8">
                    <Carousel interval={null} indicators={false}>
                      {product.images.map((image, i) => {
                        return (
                          <Carousel.Item className="Arsia--below--content--card--carousal--1--collection--page--8">
                            <img src={`${IMAGE_PATH}${image.img}`} alt="img" />
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                    <div className="Arsia--below--content--card--carousal--1--price">
                      <p className="Arsia--below--content--card--carousal--1--price--p1--2--grid">
                        {product.name}
                      </p>
                      {
                        product.discount === "0" ?
                          <p className="Arsia--below--content--card--carousal--1--price--p1--2">
                            <span style={discountPriceStyle}>
                              {priceFormat(+product.price)}
                            </span>
                          </p>
                          :
                          <p className="Arsia--below--content--card--carousal--1--price--p1--2">
                            <span style={discountPriceStyle}>
                              {priceFormat(+product.price)}
                            </span>
                            {"  "}
                            <span style={priceStyle}>
                              {priceFormat(+product.mrp)}
                            </span>{" "}
                            <span style={{ color: "#E74C3C", marginLeft: "3px" }}>
                              {product.discount}% OFF
                            </span>
                          </p>
                      }

                    </div>
                  </div>
                </Link>
              );
            })}
        </div>
      </div>
      {/* Arsia--Mobile--view--end */}
    </div>
  );
}
