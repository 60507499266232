import { objectToFormData } from "../utils/helper";

export const addCart = async (cart) => {
  console.log(cart,"through api")

  const token = localStorage.getItem("authToken");
  if(!token){
    return
  }
  const myHeaders = new Headers();
  const formdata = objectToFormData({
    ...cart,
    Authorization: token,
  });
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  try {
    const res = await fetch(
      "https://admin.arshiyasehgal.com/API/User/addCart",
      requestOptions
    );
    const data = await res.json();
    console.log(data);

    if (data.status !== true && data.message !== "Data not found")
      throw new Error(data.message);
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};


export const RemoveQTY_Cart = async (cart) => {

  const token = localStorage.getItem("authToken");
  if(!token){
    return
  }
  const myHeaders = new Headers();
  const formdata = objectToFormData({
    ...cart,
    Authorization: token,
  });
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  try {
    const res = await fetch(
      "https://admin.arshiyasehgal.com/API/User/removeCartQuantity",
      requestOptions
    );
    const data = await res.json();
    

    if (data.status !== true && data.message !== "Data not found")
      throw new Error(data.message);
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getCart = async () => {
  const token = localStorage.getItem("authToken");
  if(!token){
    return
  }
  const myHeaders = new Headers();  
  const formdata = objectToFormData({
    Authorization: token,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      "https://admin.arshiyasehgal.com/API/User/getCart",
      requestOptions
    );
    const data = await res.json();
    if (data.status !== true && data.message !== "Data not found")
      throw new Error(data.message);
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const deleteCart = async (product_id) => {
  const token = localStorage.getItem("authToken");
  if(!token){
    return
  }
  const myHeaders = new Headers();
  const formdata = objectToFormData({ product_id, Authorization: token });
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      "https://admin.arshiyasehgal.com/API/User/deleteCart",
      requestOptions
    );
    const data = await res.json();
    console.log(data);
    if (data.status !== true) throw new Error(data.message);
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};
