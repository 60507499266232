import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

import { deleteAddress as apiDeleteAddress } from "../../services/apiAddress";

export const useDeleteAddress = () => {
  const queryClient = useQueryClient();

  const { mutate: deleteAddress, isPending: isdeleting } = useMutation({
    mutationFn: (id) => apiDeleteAddress(id),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["address"],
      });

      toast.success("Address delete successfully.");
    },
    onError: (err) => {
      console.log(err);
      toast.error(err.message);
    },
  });

  return { deleteAddress, isdeleting };
};
