import "./Arsia.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import icon1 from "./image/icon1.svg";
import icon3 from "./image/icon3.svg";
import search from "./image/search.svg";
import search1 from "./image/search.svg";

import { Link, useNavigate } from "react-router-dom";
import heart from "./image/heart.png";
import cross from "./image/cross.svg";
import Aslogo from "./image/Aslogo.png";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useForm } from "react-hook-form";
import { useLogin } from "../features/user/useLogin";
import { useOtpVerify } from "../features/user/useOtpVerify";
import { userAddCart, userAuthenticate, userSearchQuery } from "../features/user/userSlice";
import { useGetCart } from "../features/cart/useGetCart";
import { useSignUp } from "../features/user/useSignUp";
import AccountMenu from "../ui/AccountMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

export default function ArsiaHeader({ handleSearch, search }) {
  console.log("hfgahfgahfaa",search)
  const { login, isLogin } = useLogin();
  const { signUp, isSignUp } = useSignUp();
  const { verifyOtp, isVerify } = useOtpVerify();
  const [otpError, setOtpError] = useState("");
  const { cart, total } = useGetCart();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.user);
  const navigate = useNavigate();

  // const [searchQuery,setSearchQuery] = useState("");
  const handleSearchChange = (event) => {
    dispatch(userSearchQuery(event.target.value));
  };

  const handleOnFocus = () => {
    navigate("/search-results");
  }

  // const [searchQuery, setSearchQuery] = useState("");
  // const [filteredProducts, setFilteredProducts] = useState([]);

  // const handleSearch = (e) => {
  //   const inputValue = e.target.value.toLowerCase();
  //   setSearchQuery(inputValue);

  //   const filtered = productsData.filter((product) =>
  //     product.title.toLowerCase().includes(inputValue)
  //   );
  //   setFilteredProducts(filtered);
  // };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  useEffect(() => {
    const isAuthenticate = localStorage.getItem("isAuthenticate");
    if (isAuthenticate === "true") {
      dispatch(userAuthenticate(true));
    } else {
      dispatch(userAuthenticate(false));
    }

    //add cart globally
    if (!cart) return;
    dispatch(userAddCart({ cart, total }));
  }, [dispatch, cart, total]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOtp, setIsOtp] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [openLogin, setOpenLogin] = React.useState(false);
  const handleOpenLogin = () => {
    setOpenLogin(true);
  };
  const handleCloseLogin = () => {
    setOpenLogin(false);
  };
  const [showLogin, setShowLogin] = useState(true);

  const handleOpenSignup = () => {
    setShowLogin(false);
  };
  const handleContinueButtonClick = () => {
    // setShowLogin(!showLogin);
  };

  const [state, setState] = React.useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const signUpHandler = (data) => {
    const newData = { ...data, name: data.firstName + " " + data.lastName };
    signUp(newData, {
      onSuccess: () => {
        reset();
        setShowLogin(true);
        setIsOtp(true);
      },
    });
    // setShowLogin(!showLogin);
    // reset();
  };
  const signInHandler = (data) => {
    login(data, {
      onSuccess: () => {
        reset();
        setIsOtp(true);
      },
    });
  };

  const otpSubmitHandler = (data) => {
    verifyOtp(data, {
      onSuccess: () => {
        reset();
        setOtpError("");
        setState(false);
        setIsOtp(false);
        window.location.href = "/allproducts";
      },
      onError: (error) => {
        setOtpError("Invalid OTP");
      },
    });
  };

  const closeHandler = () => {
    setState(false);
    setIsOtp(false);
    setShowLogin(true);
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        width: "400px",
        height: "100%",
        background: "#000",
        color: "#fff",
      }}
      role="presentation"
    >
      <List>
        <ListItem disablePadding dense>
          <ListItemButton onClick={(e) => e.stopPropagation()}>
            <ListItemText
              primary={
                <li>
                  <img
                    onClick={closeHandler}
                    src="/images/close-square.svg"
                    alt="closeIcon"
                  />
                  <div>
                    {showLogin ? (
                      <div className="Arsia--login--pop--up">
                        <div className="Arsia--login--pop--up--1--p">
                          <p className="Arsia--login--pop--up--1--p--1">
                            CLOTHES ARE YOU!
                          </p>
                          <p className="Arsia--login--pop--up--1--p--2">
                            Login to your AS Label account
                          </p>
                        </div>
                        {!isOtp && (
                          <form onSubmit={handleSubmit(signInHandler)}>
                            <div className="Arsia--login--input--1">
                              <div className="Arsia--login--input">
                                <div className="Arsia--login--input-1">
                                  <span>
                                    <img
                                      src="/images/phoneCall.svg"
                                      alt="img"
                                    ></img>
                                  </span>
                                  <input
                                    type="text"
                                    placeholder="Mobile Number"
                                    {...register("mobile", {
                                      required:
                                        "Mobile number must be required.",
                                    })}
                                    maxLength={10}
                                  ></input>
                                </div>
                                <div className="Arsia--login--input-1--border--line"></div>
                                <span>
                                  {errors?.mobile?.message && (
                                    <span>{errors?.mobile?.message}</span>
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="Arsia--login--continue">
                              <button disabled={isLogin}>
                                {isLogin ? <p>Loading...</p> : <p>Get OTP</p>}
                              </button>
                            </div>
                            {/* <div className="Arsia--seaprator--box">
                              <img src="/images/seaprator.png" alt="img" />
                            </div>
                            <div className="Arsia--login--option--box">
                              <img src="/images/google.svg" alt="img" />
                              <img src="/images/fb.svg" alt="img" />
                              <img src="/images/apple.svg" alt="img" />
                            </div> */}
                          </form>
                        )}

                        {isOtp && (
                          <form onSubmit={handleSubmit(otpSubmitHandler)}>
                            <div className="Arsia--login--input--1">
                              <div className="Arsia--login--input">
                                <div className="Arsia--login--input-1">
                                  <span></span>
                                  <input
                                    type="text"
                                    placeholder="Enter OTP"
                                    {...register("otp", {
                                      required: "OTP must be required.",
                                    })}
                                  ></input>
                                </div>
                                <div className="Arsia--login--input-1--border--line"></div>
                                <span>
                                  {errors?.otp?.message && (
                                    <span>{errors?.otp?.message}</span>
                                  )}
                                </span>
                                <span>
                                  {!errors?.otp?.message && otpError && (
                                    <span>{otpError}</span>
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="Arsia--login--continue">
                              <button disabled={isVerify}>
                                {isVerify ? <p>Loading...</p> : <p>Submit</p>}
                              </button>
                            </div>
                            {/* <div className="Arsia--seaprator--box">
                              <img src="/images/seaprator.png" alt="img" />
                            </div>
                            <div className="Arsia--login--option--box">
                              <img src="/images/google.svg" alt="img" />
                              <img src="/images/fb.svg" alt="img" />
                              <img src="/images/apple.svg" alt="img" />
                            </div> */}
                          </form>
                        )}
                        {/* <div className="Arsia--login--continue--or">
                          <div className="Arsia--login--continue--or--border"></div>
                          <p>OR</p>
                          <div className="Arsia--login--continue--or--border"></div>
                        </div> */}
                        {/* <div className="Arsia--login--button--app">
                          <button>
                            <img src={google}></img>
                          </button>
                          <button>
                            <img src={fb}></img>
                          </button>
                          <button>
                            <img src={apple}></img>
                          </button>
                        </div> */}
                        {/* <p className="Arsia--login--account--login">
                          Don't have account?{" "}
                          <span onClick={handleOpenSignup}>Register Now</span>
                        </p> */}
                      </div>
                    ) : (
                      <div>
                        <form onSubmit={handleSubmit(signUpHandler)}>
                          <div className="Arsia--login--signup--page">
                            <p>Signup</p>

                            <div className="Arsia--login--signup--page1">
                              <div className="Arsia--login--signup--page--2--input--1--6">
                                <input
                                  type="text"
                                  placeholder="Mobile number..."
                                  {...register("mobileNumber", {
                                    required: "Mobile number must be required.",
                                  })}
                                ></input>
                                <div className="Arsia--cart--border--line--login"></div>
                                <span>
                                  {errors?.mobileNumber?.message && (
                                    <span>{errors?.mobileNumber?.message}</span>
                                  )}
                                </span>
                              </div>
                              <div className="Arsia--login--signup--page--2--input">
                                <div className="Arsia--login--signup--page--2--input--1">
                                  <input
                                    type="text"
                                    placeholder="First Name"
                                    {...register("firstName", {
                                      required: "First name must be required.",
                                    })}
                                  ></input>
                                  <div className="Arsia--cart--border--line--login"></div>
                                  <span>
                                    {errors?.firstName?.message && (
                                      <span>{errors?.firstName?.message}</span>
                                    )}
                                  </span>
                                </div>
                                <div className="Arsia--login--signup--page--2--input--1">
                                  <input
                                    type="text"
                                    placeholder="Last Name"
                                    {...register("lastName")}
                                  ></input>
                                  <div className="Arsia--cart--border--line--login"></div>
                                </div>
                              </div>
                              <div className="Arsia--login--signup--page--2--input--1--6">
                                <input
                                  type="email"
                                  placeholder="Email Address"
                                  {...register("email", {
                                    required: "Email address must be required.",
                                  })}
                                ></input>
                                <div className="Arsia--cart--border--line--login"></div>
                                <span>
                                  {errors?.email?.message && (
                                    <span>{errors?.email?.message}</span>
                                  )}
                                </span>
                              </div>
                              {/* <div className="Arsia--login--signup--page--2--input--1--6">
                                <input
                                  type="text"
                                  placeholder="Password"
                                  {...register("password", {
                                    required: "Password must be required.",
                                  })}
                                ></input>
                                <div className="Arsia--cart--border--line--login"></div>
                                <span>
                                  {errors?.password?.message && (
                                    <span>{errors?.password?.message}</span>
                                  )}
                                </span>
                              </div>
                              <div className="Arsia--login--signup--page--2--input--1--6">
                                <input
                                  type="text"
                                  placeholder="Re-enter Password"
                                  {...register("confirmPassword", {
                                    required:
                                      "Password confirm must be required",
                                    validate: (val) => {
                                      return (
                                        val === getValues().password ||
                                        "Password and Confirm password must be same."
                                      );
                                    },
                                  })}
                                ></input>
                                <div className="Arsia--cart--border--line--login"></div>
                                <span>
                                  {errors?.confirmPassword?.message && (
                                    <span>
                                      {errors?.confirmPassword?.message}
                                    </span>
                                  )}
                                </span>
                              </div> */}
                              <div className="Arsia--header--get--otp">
                                {/* <p type="submit">Get OTP</p> */}

                                {/* <button
                                  type="button"
                                  onClick={handleContinueButtonClick}
                                >
                                  Continue
                                </button> */}
                                <button type="submit">
                                  {isSignUp ? "Loading..." : "Get OTP"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </li>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const [data, setData] = useState({});
  useEffect(() => {
    axios
      .get("https://admin.arshiyasehgal.com/API/Homepage/getHomepage")
      .then((response) => {
        // Check if data is present before updating state
        if (response.data.status && response.data.data.length > 0) {
          setData(response.data.data[0]);
        }
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <div>
      <div className="Arsia--header--1">
        <div className="Arsia--header">
          {data && data.top_header_title && <p>{data.top_header_title}</p>}
        </div>
        <div className="Arsia--Navbar">
          <Link to={"/"}>
            <img className="Arsia--Navbar-img" src={Aslogo}></img>
          </Link>
          <div className="Arsia--Navbar--1">
            <Link to={"/allproducts"} style={{ textDecoration: "none" }}>
              <p>All Products</p>
            </Link>
            <Link to={"/Collections"} style={{ textDecoration: "none" }}>
              <p>Collections</p>
            </Link>
            {/* <Link to={"/About"} style={{ textDecoration: "none" }}>
              <p>About Us</p>
            </Link>
            <Link to={"/new-arrivals"} style={{ textDecoration: "none" }}>
              <p>New Arrivals</p>
            </Link> */}
          </div>
          <div className="Arsia--navbar--search">
            <div className="Arsia--navbar--search--input">
              <input
                type="text"
                placeholder="Search..."
                value={search}
                onChange={handleSearchChange}
                onFocus={handleOnFocus}
                className="Arsia--search--input"
              />
              <img src={search1} alt="img"></img>
            </div>
            <div className="Arsia--website--border--line"></div>
          </div>
          <div className="Arsia--icon1">
            {!isAuthenticated ? (
              ["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <img
                    alt="img"
                    src={icon1}
                    onClick={toggleDrawer(anchor, true)}
                    style={{ cursor: "pointer" }}
                  />

                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))
            ) : (
              <AccountMenu />
            )}

            {/* <img alt="img" src={heart} style={{ cursor: "pointer" }}></img> */}
            {/* {
              !isAuthenticated ? (
                ["right"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <img
                      alt="img"
                      src={icon3}
                      onClick={toggleDrawer(anchor, true)}
                      style={{ cursor: 'pointer' }}
                    />

                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                    >
                      {list(anchor)}
                    </Drawer>
                  </React.Fragment>
                ))
              ) : <Link to={"/Cart"} className="anchor--arshiya" >
                <div className="cart--count--number">
                  <img alt="img" src={icon3} style={{ cursor: 'pointer' }}></img>
                </div>
              </Link>
            } */}

            <Link to={"/Cart"} className="anchor--arshiya">
              <div className="cart--count--number">
                <img alt="img" src={icon3} style={{ cursor: "pointer" }}></img>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <Modal
        open={openLogin}
        onClose={handleCloseLogin}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className="responsive-modal--log--in">
          <div className="Arsia--Sehgal--login--signup--page1">
            <div className="Arsia--Sehgal--login--signup--page1--2">
              <img src={cross} onClick={handleCloseLogin}></img>
            </div>
            <div className="Arsia--login--signup--page">
              <p>Signup</p>
              <div className="Arsia--cart--border--line--login"></div>
              <div className="Arsia--login--signup--page1">
                <input type="text" placeholder="Enter email"></input>
                <div className="Arsia--cart--border--line--login"></div>
                <div className="Arsia--login--signup--page--2--input">
                  <div className="Arsia--login--signup--page--2--input--1">
                    <input type="text" placeholder="Enter email"></input>
                    <div className="Arsia--cart--border--line--login"></div>
                  </div>
                  <div className="Arsia--login--signup--page--2--input--1">
                    <input type="text" placeholder="Enter email"></input>
                    <div className="Arsia--cart--border--line--login"></div>
                  </div>
                </div>
                <input type="text" placeholder="Enter email"></input>
                <div className="Arsia--cart--border--line--login"></div>

                <input type="text" placeholder="Enter email"></input>
                <div className="Arsia--cart--border--line--login"></div>
                <input type="text" placeholder="Enter email"></input>
                <div className="Arsia--cart--border--line--login"></div>
                <button>Continue</button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
