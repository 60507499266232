export const getAllProduct = async () => {
  try {
    const res = await fetch(
      "https://admin.arshiyasehgal.com/API/Product/getAllProduct"
    );
    const data = await res.json();

    if (data.status !== true) {
      throw new Error(data.message);
    }

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getProduct = async (product_id) => {
  try {
    const res = await fetch(
      "https://admin.arshiyasehgal.com/API/Product/getSingleProduct",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ product_id }),
      }
    );
    const data = await res.json();

    if (!data.status) {
      throw new Error(data.msg); 
    }
    if (data.data.size) {
      data.data.size = data.data.size.split(',');
    }

    console.log("ghafahgfa",data)

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};
