import './Arsia.css';
import React, { useState, useRef, useEffect } from 'react';
import ArsiaFooter from './ArsiaFooter';
import ArsiaHeader from './ArsiaHeader';
import ArsiaHeaderMobile from './Arsia-header-mobile';
import FooterMobile from './Arsia--footer--mobile';
import axios from 'axios';

export default function ArsiaReturn() {
    const [isSearchCardOpen, setIsSearchCardOpen] = useState(false);
    const [returnData,setReturnData] = useState({});
    const handleSearchIconClick = () => {
        setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
    };
    const handleArrowLeftClick = () => {
        setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
    };
    const [sidebarVisible, setSidebarVisible] = useState(false);

    const showSidebar = () => {
        setSidebarVisible(true);
    };

    const hideSidebar = () => {
        setSidebarVisible(false);
    };
    useEffect(() => {
        // Scroll to the top of the page when navigating to the About page
        window.scrollTo(0, 0);
      }, []);

      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get('https://admin.arshiyasehgal.com/API/Web/getReturnExchange');
            setReturnData(response.data.data[0]);
            console.log("jgskghsghsghs",response.data.data[0].des)
            // setLoading(false);
          } catch (error) {
            console.log(error)
          }
        };
    
        fetchData();
      }, []); 
    

    return (
        <>
            <div className="Arsia--website--design">
                <ArsiaHeader />
                <div className='Arsia--about--us--page'>
                <div dangerouslySetInnerHTML={{ __html: returnData && returnData.des }} />
                    {/* <h1>Returns & Exchange</h1>
                    <p>We are committed to providing an everlasting experience for our customers. For us that includes satisfying our customer’s needs, and choices. We ensure you at @arshiyasehgalofficial we never compromise on the quality of our products. </p>
                    <p>However, if you find the need to exchange or return a product, we will be pleased to do so. </p>
                    <p>The policy shall only be applicable under the following conditions. </p>
                    <p>a.	The exchange or credit note must be initiated within the first 48 hours of order, once the order has been received by the customer. </p>
                    <p>b.	The product must remain undamaged or unfaulty and should retain its original tags. </p>
                    <p>c.	We are happy to exchange the product, given an error has been made our end. You shall be presented with the option of exchanging the product from many available options within the collection, or avail a credit note which can be used to make future purchases.</p>
                    <p>d.	It is also to be noted the credit note will have an expiry date, varying up to 3 to 6 months. </p>
                   
                    <h1>Process</h1>

                    <p>Please contact us on +919810889012 or email us on at arshiyasehgalofficial@gmail.com withing 48 hours of when the order has been received with the intention of returning or exchanging your product. </p>
                    <p>b.	Our team will guide you further on the process and will help you out regarding any queries.</p>
                    <p>c.	Once the item has been received and meets the exchange & returns conditions, we will process the exchange or issue a credit note.</p>
                    <h1>Cancellation</h1>
                    <p>We always try to provide the best service to our customers, and never compromise on the quality of our products. In case of any requests for order cancellation, please note that no orders will be cancelled as part of our policy, as products are specially made for our customers once placed the order.</p> */}
                </div>
                <ArsiaFooter />
            </div>

            {/* mobile--view */}

            <div className="Arsia--website--design--mobile--view">

                {/* 
   #Arsiya Header
*/}
                <ArsiaHeaderMobile />


                <div className='Arsia--about--us--page'>
                <div dangerouslySetInnerHTML={{ __html: returnData && returnData.des }} />
                    {/* <h1>Returns & Exchange</h1>
                    <p>We are committed to providing an everlasting experience for our customers. For us that includes satisfying our customer’s needs, and choices. We ensure you at @arshiyasehgalofficial we never compromise on the quality of our products. </p>
                    <p>However, if you find the need to exchange or return a product, we will be pleased to do so. </p>
                    <p>The policy shall only be applicable under the following conditions. </p>
                    <p>a.	The exchange or credit note must be initiated within the first 48 hours of order, once the order has been received by the customer. </p>
                    <p>b.	The product must remain undamaged or unfaulty and should retain its original tags. </p>
                    <p>c.	We are happy to exchange the product, given an error has been made our end. You shall be presented with the option of exchanging the product from many available options within the collection, or avail a credit note which can be used to make future purchases.</p>
                    <p>d.	It is also to be noted the credit note will have an expiry date, varying up to 3 to 6 months. </p>
                   
                    <h1>Process</h1>

                    <p>Please contact us on +919810889012 or email us on at arshiyasehgalofficial@gmail.com withing 48 hours of when the order has been received with the intention of returning or exchanging your product. </p>
                    <p>b.	Our team will guide you further on the process and will help you out regarding any queries.</p>
                    <p>c.	Once the item has been received and meets the exchange & returns conditions, we will process the exchange or issue a credit note.</p>
                    <h1>Cancellation</h1>
                    <p>We always try to provide the best service to our customers, and never compromise on the quality of our products. In case of any requests for order cancellation, please note that no orders will be cancelled as part of our policy, as products are specially made for our customers once placed the order.</p> */}
                </div>
                {/* 
   #Arshiya footer mobile view
 */}

                <FooterMobile />
            </div>
        </>
    );
}