import { useQuery } from "@tanstack/react-query";
import { getCart } from "../../services/apiCart";

export const useGetCart = () => {
  const { isLoading, error, data } = useQuery({
    queryKey: ["cart"],
    queryFn: () => getCart(),
  });

  const { data: cart, total } = data || {};

  return { isLoading, error, cart, total };
};
