import './Arsia.css';
import React, { useState, useRef, useEffect } from 'react';
import ArsiaFooter from './ArsiaFooter';
import ArsiaHeader from './ArsiaHeader';
import ArsiaHeaderMobile from './Arsia-header-mobile';
import FooterMobile from './Arsia--footer--mobile';
import axios from 'axios';

export default function ArsiaPrivacy() {
    const [isSearchCardOpen, setIsSearchCardOpen] = useState(false);
    const [privacyData,setPrivacyData] = useState({});
    const handleSearchIconClick = () => {
        setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
    };
    const handleArrowLeftClick = () => {
        setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
    };
    const [sidebarVisible, setSidebarVisible] = useState(false);

    const showSidebar = () => {
        setSidebarVisible(true);
    };

    const hideSidebar = () => {
        setSidebarVisible(false);
    };
    useEffect(() => {
        // Scroll to the top of the page when navigating to the About page
        window.scrollTo(0, 0);
      }, []);

      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get('https://admin.arshiyasehgal.com/API/Web/getPrivacyPolicy');
            setPrivacyData(response.data.data[0]);
            console.log("jgskghsghsghs",response.data.data[0].privacyPolicy)
            // setLoading(false);
          } catch (error) {
            console.log(error)
          }
        };
    
        fetchData();
      }, []); 
    
    return (
        <>
        <div className="Arsia--website--design">
            <ArsiaHeader />
            <div className='Arsia--about--us--page'>
                {/* <h1>Privacy Policy</h1>
                <p>At Arshiya Sehgal we take the privacy of our customers, very seriously. The privacy policy sets out how your personal details are used by us. Any personal details required by us, whilst logging on the website or placing any orders, we assure our customers, all information is protected and safe with us. All information is used in accordance with our privacy policy statement. This information is used by us for record keeping, gift vouchers, customer loyalty, email marketing, discounts and customer surveys. </p>
                 <p>Following information is required by us when placing an order.</p>
                 <p>1.	Name</p>
                 <p>2.	Contact information including your email address.</p>
                 <p>3.	Residential address for delivery of your product. </p>
                 <p>4.	Demographic information such as postcode, landmarks etc</p> */}
                    <div dangerouslySetInnerHTML={{ __html: privacyData && privacyData.privacyPolicy }} />
                    <div dangerouslySetInnerHTML={{ __html: privacyData && privacyData.termsConditions }} />
            </div>
            <ArsiaFooter />
            </div>

            {/* mobile--view */}

            <div className="Arsia--website--design--mobile--view">

                {/* 
   #Arsiya Header
*/}
               <ArsiaHeaderMobile/>

<div className='Arsia--about--us--page'>
                   {/* <h1>Privacy Policy</h1>
                <p>At Arshiya Sehgal we take the privacy of our customers, very seriously. The privacy policy sets out how your personal details are used by us. Any personal details required by us, whilst logging on the website or placing any orders, we assure our customers, all information is protected and safe with us. All information is used in accordance with our privacy policy statement. This information is used by us for record keeping, gift vouchers, customer loyalty, email marketing, discounts and customer surveys. </p>
                 <p>Following information is required by us when placing an order.</p>
                 <p>1.	Name</p>
                 <p>2.	Contact information including your email address.</p>
                 <p>3.	Residential address for delivery of your product. </p>
                 <p>4.	Demographic information such as postcode, landmarks etc</p> */}
                    <div dangerouslySetInnerHTML={{ __html: privacyData && privacyData.privacyPolicy }} />
                    <div dangerouslySetInnerHTML={{ __html: privacyData && privacyData.termsConditions }} />
            </div>
                {/* 
   #Arshiya footer mobile view
 */}

              <FooterMobile/>
            </div>
        </>
    );
}