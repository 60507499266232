import { useSelector, useDispatch } from "react-redux";
import FooterMobile from "./Arsia--footer--mobile";
import React, { useEffect, useState } from "react";
import ArsiaHeaderMobile from "./Arsia-header-mobile";
import "./Arsia.css";
import ArsiaFooter from "./ArsiaFooter";
import ArsiaHeader from "./ArsiaHeader";
import { Link, useNavigate } from "react-router-dom";
import { useGetCart } from "../features/cart/useGetCart";
import Loader from "../ui/Loader";
import { IMAGE_PATH } from "../utils/constant";
import { objectToFormData, priceFormat } from "../utils/helper"; 
import { useAddCart } from "../features/cart/useAddCart";
import { useDeleteCart } from "../features/cart/useDeleteCart";
import { usePlaceOrder } from "../features/order/usePlaceOrder";
import { deletingCart, qtyHandeling } from "../features/cart/cartSlice";
import { addingCart } from "../features/cart/cartSlice";
import { RemoveQTY_Cart, getCart } from "../services/apiCart";
// import { userOrderId } from "../features/user/userSlice";

export default function ArsiaCartPage() {
  const { isLoading, error, cart, total } = useGetCart();
  const { isdeleting, deleteCart } = useDeleteCart();
  const { isAdding, addCart } = useAddCart();
  const { isAuthenticated } = useSelector((state) => state.user);
  const { cart: localCart } = useSelector((state) => state.cart);
  const globalCart = isAuthenticated ? cart : localCart;

   const product_id = cart?.map((item) => item.product_id)?.toString();
  const qty = cart?.map((item) => item.qty)?.toString();
  
   const product_id_local = localCart
     ?.map((item) => item.product_id)
     ?.toString();
    const qty_local = localCart?.map((item) => item.qty)?.toString();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.table([localCart, cart,"hfgfghfghffjhfjgujrt"]);

  useEffect(() => {
    if (globalCart) sessionStorage.setItem("cart", JSON.stringify(globalCart));
  }, [globalCart]);


  const deleteCartHandler = (id) => {
    if (isAuthenticated) {
      return deleteCart(id);
    }
    if (!isAuthenticated) {
      return dispatch(deletingCart(id));
    }
  };

  const cartIncQtyHandler = (qty, productId) => {
    if (!isAuthenticated) dispatch(qtyHandeling(productId, qty));
    if (isAuthenticated) addCart({ product_id: productId, qty: 1 });
  };

  const cartDecQtyHandler = (qty, productId) => {
    if (qty <= 0) return;
    if (!isAuthenticated) {
      dispatch(qtyHandeling(productId, qty));
    }
    if (isAuthenticated) addCart({ product_id: productId, qty: "-1" });
  };

  if (isAuthenticated && isLoading) return <Loader />;
  if (isAuthenticated && error) return <div>{error.message}</div>;

  const cartData = isAuthenticated ? cart : globalCart;
  
  const totalPrice = globalCart?.reduce((accumulator, currentValue) => {
    return accumulator + +currentValue.price * +currentValue.qty;
  }, 0);


  const orderPlaceAPi = async (order) => {
    const token = localStorage.getItem("authToken");
    const myHeaders = new Headers();

    const formdata = objectToFormData({ ...order, Authorization: token });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      // mode: "no-cors",
      redirect: "follow",
    };

    try {
      const res = await fetch(
        "https://admin.arshiyasehgal.com/API/User/placeOrder",
        requestOptions
      );
      const data = await res.json();

      // Ensure the response has the expected structure
      const newOrderId = data?.data?.order_id;
      if (!newOrderId) {
        throw new Error("Invalid order response");
      }

      console.log("orderId", newOrderId);
      // dispatch(userOrderId(newOrderId));
      localStorage.setItem("orderId", newOrderId);

      navigate("/order-proceed");

      if (data.status !== true && data.message !== "Data not found")
        throw new Error(data.message);
      return data;
    } catch (err) {
      throw new Error(err.message);
    }
  };

    const priceStyle = {
      textDecoration: "line-through",
      color: "#888",
      marginLeft: "3px",  
    };

    const discountPriceStyle = {
      color: "black",
      // fontWeight: "bold",
    };


  return (
    <div>
      <div className="Arsia--contact--us--page--web--view">
        <ArsiaHeader />
        <div className="Arsia--contact--us--page--web--view--cart">
          {cartData !== undefined ? (
            <divss className="Arsia--cart--page--card">
              <div className="Arsia--cart--page--card--add-to-cart">
                <div className="Arsia--cart--item">
                  <p className="Arsia--cart--item--p1">Cart</p>
                  <p className="Arsia--cart--item--p2">
                    {globalCart?.length} ITEMS
                  </p>
                </div>
                <div className="Arsia--cart--page--card--box">
                  {globalCart?.map((item, i) => {
                    return (
                      <>
                        <div
                          key={i}
                          className="Arsia--cart--page--card--box--1"
                        >
                          <div className="Arsia--cart--page--card--box--1--1">
                            <img
                              className="Arsia--cart-page-product-img"
                              src={`${IMAGE_PATH}${item.image}`}
                              alt="img"
                            ></img>
                            <div className="Arsia--cart--page--card--box--1--1--content">
                              <p className="Arsia--dress--name--cart">
                                {item.productName || item.name}
                              </p>
                              <p className="Arsia--dress--name--cart">
                                Size:{" "}
                                <span style={{ textTransform: "uppercase" }}>
                                  {item.size}
                                </span>
                              </p>
                              <div className="Arsia--color--remove">
                                <p className="Arsia--color--remove--qty">
                                  Qty:
                                </p>
                                <div className="Arsia--number--page1">
                                  <div className="new--year--add--number--page1">
                                    <button
                                      className="new--year--add--number--page1--p1"
                                      onClick={() =>
                                        cartDecQtyHandler(
                                          +item.qty - 1,
                                          item.product_id
                                        )
                                      }
                                    >
                                      -
                                    </button>
                                  </div>
                                  <div className="new--year--add--number--page1--2">
                                    <p className="new--year--add--number--page1--p2">
                                      {item.qty}
                                    </p>
                                  </div>
                                  <div className="new--year--add--number--page1">
                                    <button
                                      onClick={() =>
                                        cartIncQtyHandler(
                                          +item.qty + 1,
                                          item.product_id
                                        )
                                      }
                                      className="new--year--add--number--page1--p1"
                                    >
                                      +
                                    </button>
                                  </div>
                                </div>
                                <p
                                  onClick={() =>
                                    deleteCartHandler(item.product_id)
                                  }
                                  className="new--year--add--number--page1--p1"
                                >
                                  {isdeleting ? "Loading..." : "Remove"}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="Arsia--cart--page--card--box--1--2">
                            {
                              item.discount==="0"?
                              <p className="Arsia--cart--page--card--box--1--2--price">
                              {" "}
                              <span style={discountPriceStyle}>
                                {priceFormat(+item.price)}
                              </span>
                            </p>
                            :
                            <p className="Arsia--cart--page--card--box--1--2--price">
                              {" "}
                              <span style={discountPriceStyle}>
                                {priceFormat(+item.price)}
                              </span>
                              {"  "}
                              <span style={priceStyle}>
                                {priceFormat(+item.mrp)}
                              </span>{" "}
                              <span
                                style={{
                                  color: "#E74C3C",
                                  marginLeft: "3px",
                                }}
                              >
                                {item.discount}% OFF
                              </span>
                            </p>
                            }
                            
                          </div>
                        </div>
                        {i < cartData.length - 1 && (
                          <div
                            key={i + 1}
                            className="Arsia--cart--border--line"
                          ></div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="Arsia--cart--page--card--order--summury">
                <div className="Arsia--cart--page--card--order--summury--card">
                  <p className="Arsia--cart--page--card--order--summury--card--p1">
                    Order Summary
                  </p>
                  <div className="Arsia--cart-order-summury--content">
                    <div className="Arsia--cart-order-summury--content1">
                      <p>Price</p>
                      <p>
                        {isAuthenticated
                          ? priceFormat(+total)
                          : priceFormat(+totalPrice)}
                      </p>
                    </div>
                    {/* <div className="Arsia--cart-order-summury--content1">
                    <p>Discount</p>
                    <p>Rs 00</p>
                  </div> */}
                    <div className="Arsia--cart-order-summury--content1">
                      <p>Shipping</p>
                      <p>Free</p>
                    </div>
                    {/* <div className="Arsia--cart-order-summury--content1">
                      <p>Coupon Applied</p>
                      <p><b>First20</b></p>
                    </div> */}
                  </div>
                  <div className="Arsia--cart--border--line"></div>
                  <div className="Arsia--cart-order-summury--content1-1">
                    <p className="Arsia--cart-order-summury--content1--p">
                      TOTAL
                    </p>
                    <p className="Arsia--cart-order-summury--content1--p2">
                      <p>
                        {isAuthenticated
                          ? priceFormat(+total)
                          : priceFormat(+totalPrice)}
                      </p>
                    </p>
                  </div>
                  <div className="Arsia--cart--coupon--code">
                    {/* <input type="text" className="coupon_input" placeholder="Coupon Code"/>
                    <button className="coupon_button" >Apply</button> */}
                    {/* <div className="Arsia--cart--coupon--code--1">
                      <p>Coupon Code</p>
                    </div>
                    <div className="Arsia--cart--coupon--code--2">
                      <p>Apply</p>
                    </div> */}
                  </div>
                  <Link to={"/order-proceed"}>
                    <button
                      // onClick={() =>
                      //   orderPlaceAPi(
                      //     isAuthenticated
                      //       ? { product_id, qty }
                      //       : { product_id_local, qty_local }
                      //   )
                      // }
                      className="Arsia--cart-order-summury--content1-1--button"
                    >
                      Proceed to Checkout
                    </button>
                  </Link>
                  {/* <button
                  disabled={isOder}
                  onClick={orderPlaceHandler}
                  className="Arsia--cart-order-summury--content1-1--button"
                >
                  {isOder ? "Loading..." : "Proceed to Checkout"}
                </button> */}
                </div>
              </div>
            </divss>
          ) : (
            <div className="empty_cart">Your Cart is Empty</div>
          )}
        </div>
        <ArsiaFooter />
      </div>

      <div className="Arsia--contact--us--page--mobile--view">
        <ArsiaHeaderMobile />
        <div className="Arsia--contact--us--page--web--view--cart">
          <div className="Arsia--cart--page--card">
            <div className="Arsia--cart--page--card--add-to-cart">
              <div className="Arsia--cart--item">
                <p className="Arsia--cart--item--p1">Cart</p>
                <p className="Arsia--cart--item--p2">
                  {cartData?.length} ITEMS
                </p>
              </div>

              <div className="Arsia--cart--page--card--box">
                {globalCart?.map((item, i) => {
                  return (
                    <>
                      <div key={i} className="Arsia--cart--page--card--box--1">
                        <div className="Arsia--cart--page--card--box--1--1">
                          <img
                            className="Arsia--cart-page-product-img"
                            src={`${IMAGE_PATH}${item.image}`}
                            alt="img"
                          ></img>
                          <div className="Arsia--cart--page--card--box--1--1--content">
                            <p className="Arsia--dress--name--cart">
                              {console.log(item)}
                              {item.productName || item.name}
                            </p>
                            <p className="Arsia--dress--name--cart">
                              Size:{" "}
                              <span style={{ textTransform: "uppercase" }}>
                                {item.size}
                              </span>
                            </p>
                            <div className="Arsia--cart--page--card--box--1--2">
                              {
                                item.discount==="0"?
                                <p className="Arsia--cart--page--card--box--1--2--price">
                              {" "}
                              <span style={discountPriceStyle}>
                                {priceFormat(+item.price)}
                              </span>
                            </p>
                            :
                            <p className="Arsia--cart--page--card--box--1--2--price">
                              {" "}
                              <span style={discountPriceStyle}>
                                {priceFormat(+item.price)}
                              </span>
                              {"  "}
                              <span style={priceStyle}>
                                {priceFormat(+item.mrp)}
                              </span>{" "}
                              <span
                                style={{
                                  color: "#E74C3C",
                                  marginLeft: "3px",
                                }}
                              >
                                {item.discount}% OFF
                              </span>
                            </p>
                              }
                               
                            </div>
                            <div className="Arsia--color--remove">
                              <div className="Arsia--number--page1">
                                <div
                                  onClick={() =>
                                    cartDecQtyHandler(
                                      +item.qty - 1,
                                      item.product_id
                                    )
                                  }
                                  className="new--year--add--number--page1"
                                >
                                  <p className="new--year--add--number--page1--p1">
                                    -
                                  </p>
                                </div>
                                <div className="new--year--add--number--page1--2">
                                  <p className="new--year--add--number--page1--p2">
                                    {item.qty}
                                  </p>
                                </div>
                                <div
                                  onClick={() =>
                                    cartIncQtyHandler(
                                      +item.qty + 1,
                                      item.product_id
                                    )
                                  }
                                  className="new--year--add--number--page1"
                                >
                                  <p className="new--year--add--number--page1--p1">
                                    +
                                  </p>
                                </div>
                              </div>
                              <p
                                onClick={() =>
                                  deleteCartHandler(item.product_id)
                                }
                                className="Arsia--color--cart--remove"
                              >
                                {isdeleting ? "Loading..." : "Remove"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {i < cartData.length - 1 && (
                        <div
                          key={i + 1}
                          className="Arsia--cart--border--line"
                        ></div>
                      )}
                    </>
                  );
                })}
                {/* <div className="Arsia--cart--discount--card">
                  <img src={percent}></img>
                  <p>
                    10% Instant Discount with Federal Bank Debit Cards on a min
                    spend of $150. TCA
                  </p>
                </div> */}
              </div>
            </div>
            <div className="Arsia--cart--page--card--order--summury">
              <div className="Arsia--cart--page--card--order--summury--card">
                <p className="Arsia--cart--page--card--order--summury--card--p1">
                  Order Summary
                </p>
                <div className="Arsia--cart-order-summury--content">
                  <div className="Arsia--cart-order-summury--content1">
                    <p>Price</p>
                    <p>
                      {isAuthenticated
                        ? priceFormat(+total)
                        : priceFormat(+totalPrice)}
                    </p>
                  </div>
                  {/* <div className="Arsia--cart-order-summury--content1">
                    <p>Discount</p>
                    <p>Rs 500</p>
                  </div> */}
                  <div className="Arsia--cart-order-summury--content1">
                    <p>Shipping</p>
                    <p>Free</p>
                  </div>
                  {/* <div className="Arsia--cart-order-summury--content1">
                    <p>Coupon Applied</p>
                    <p>Rs 0.00</p>
                  </div> */}
                </div>
                <div className="Arsia--cart--border--line"></div>
                <div className="Arsia--cart-order-summury--content1-1">
                  <p className="Arsia--cart-order-summury--content1--p">
                    TOTAL
                  </p>
                  <p className="Arsia--cart-order-summury--content1--p2">
                    {isAuthenticated
                      ? priceFormat(+total)
                      : priceFormat(+totalPrice)}
                  </p>
                </div>
                <div className="Arsia--cart--coupon--code">
                  {/* <div className="Arsia--cart--coupon--code--1">
                    <p>Coupon Code</p>
                  </div>
                  <div className="Arsia--cart--coupon--code--2">
                    <p>Apply</p>
                  </div> */}
                </div>
                <Link to={"/order-proceed"}>
                  <button
                    // onClick={() =>
                    //   orderPlaceAPi(
                    //     isAuthenticated
                    //       ? { product_id, qty }
                    //       : { product_id_local, qty_local }
                    //   )
                    // }
                    className="Arsia--cart-order-summury--content1-1--button"
                  >
                    Proceed to Checkout
                  </button>
                </Link>
                {/* <button
                  disabled={isOder}
                  onClick={orderPlaceHandler}
                  className="Arsia--cart-order-summury--content1-1--button"
                >
                  {isOder ? "Loading..." : "Proceed to Checkout"}
                </button> */}
              </div>
            </div>
          </div>
        </div>
        <FooterMobile />
      </div>
    </div>
  );
}
