import './Arsia.css';
import React, { useState } from 'react';
import logo3 from './image/logo3.png';
import heart from './image/heart.png';
import { Link } from "react-router-dom";
import pic1 from './image/pic1.png';
import pic5 from './image/pic5.png';
import pic6 from './image/pic6.png';
import img9 from './image/img9.png';
import facebook from './image/favebook.svg';
import insta from './image/insta.svg';
import linked from './image/linked.png';
import pint from './image/pint.svg';
import heart2 from './image/heart2.svg';
import face from './image/face.svg';
import pintaa from './image/pintaa.svg';
import twitta from './image/twitta.svg';
import instaa from './image/instaa.svg';
import ArsiaVerticalCarousal from './Arsia-vetical-caraousal/Arsia--vertical--carousal';
import ArsiaHeader from './ArsiaHeader';
import Frame from './image/Frame.png';
import videoimg from './image/videoimg.png';
import play from './image/play.png';
import insta6 from './image/insta6.svg';
import face7 from './image/face7.svg';
import navbar from './image/navbar.svg';
import search1 from './image/search1.png';
import liked9 from './image/liked9.svg';
import footername from './image/footername.png';
import back from './image/back.png';
import cross from './image/cross.svg';
import avtar from './image/avtar.png';
import home from './image/home.svg';
import collection from './image/collection.svg';
import favorite from './image/favorite.svg';
import business from './image/business.svg';
import headset from './image/headset.svg';
import language from './image/language.svg';
import { Carousel } from "antd";
import verticar3 from './image/verticar3.png'; 

import pic3 from './image/pic3.png';



export default function ArsiaProduct() {
    const [isContentVisible, setIsContentVisible] = useState(false);

    const handleDescriptionClick = () => {
        setIsContentVisible(!isContentVisible);
    };
    const [isContentVisible1, setIsContentVisible1] = useState(false);

    const handleDescriptionClick1 = () => {
        setIsContentVisible1(!isContentVisible1);
    };
    const [isContentVisible2, setIsContentVisible2] = useState(false);

    const handleDescriptionClick2 = () => {
        setIsContentVisible2(!isContentVisible2);
    };

    const [isSearchCardOpen, setIsSearchCardOpen] = useState(false);
 const handleSearchIconClick = () => {
    setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
  };
 const handleArrowLeftClick = () => {
    setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
  };
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const showSidebar = () => {
    setSidebarVisible(true);
  };

  const hideSidebar = () => {
    setSidebarVisible(false);
  };
    return (
        <div>
        <div className='Arsia--website--design'>

            <ArsiaHeader />
            <div className='Arsia--carausal--and--cart'>
                <div className='Arsia--caraousal'>
                    <ArsiaVerticalCarousal />

                    <div className='Arsia--caraousal--video'>
                        <img src={videoimg}></img>
                        <div className='Arsia--caraousal--video--1'>
                            <img src={play}></img>
                        </div>
                    </div>

                </div>
                <div className='Arsia--cart'>
                    <div className='Arsia--cart--1--card'>
                        <div className='Arsia--cart--1'>
                            <img src={heart2}></img>
                            <p>Add to wishlist</p>
                        </div>
                        <div className='Arsia--cart--1'>
                            <img src={instaa}></img>
                            <img src={face}></img>
                            <img src={twitta}></img>
                            <img src={pintaa}></img>

                        </div>
                    </div>
                    <div className='Arsia--cart--caraousal--card'>
                        <p>Red Slit Marvel</p>
                    </div>
                    <div className='Arsia--cart--1--card'>
                        <div className='Arsia--cart--1--2'>
                            <p>Rs 14,000</p>
                        </div>

                    </div>
                    <div className='Arsia--cart--1--card'>
                        <div className='Arsia--cart--1--2--text'>
                            <p>The gently curved lines accentuated by sewn details are kind to your body and pleasant to look at. Also, there’s a tilt and height-adjusting mechanism that’s built to outlast years of ups and downs.</p>
                        </div>

                    </div>
                    <div className='Arsia--cart--size--usa'>
                        <div className='Arsia--cart--size--usa--card'>
                            <p className='Arsia--cart--size--usa--card--p'>Size</p>
                            <div className='Arsia--cart--size--usa--card--1'>
                                <p>US Size</p>
                            </div>
                        </div>
                        <div className='Arsia--cart--size--usa--button'>
                            <button>US 5.5</button>
                            <button>US 6</button>
                            <button>US 7</button>
                            <button>US 7.5</button>
                            <button>US 8</button>
                            <button>US 8.5</button>
                            <button>US 9</button>
                            <button>US 10</button>
                        </div>
                        <div className='Arsia--cart--size--usa--card'>
                            <p className='Arsia--cart--size--usa--card--p'>Qty:</p>
                            <div className='Arsia--cart--size--usa--card--1--add'>
                                <p >-</p>
                                <p>0</p>
                                <p >+</p>
                            </div>
                        </div>
                        <div className='Arsia--cart--size--usa--card--price'>
                            <p>Ask For Price</p>
                        </div>
                        <div className='Arsia--cart--size--usa--card--free--ship'>
                            <p>Free 3-5 day shipping </p>
                            <p> •  Tool-free assembly</p>
                            <p> •  30-day trial</p>
                        </div>
                    </div>

                    <div className='Arsia--cart--border--1'></div>
                    <div className='Arsia--cart--dropdown--discription'>
                        <div >
                            <div className='Arsia--cart--dropdown--discription--1' onClick={handleDescriptionClick}>
                                <p>Description</p>
                                <img src={Frame} alt="Description Icon" />
                            </div>
                            {isContentVisible && (
                                <div className='Arsia--cart--dropdown--discription--1--content'>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='Arsia--cart--border--1'></div>

                    <div className='Arsia--cart--dropdown--discription'>
                        <div >
                            <div className='Arsia--cart--dropdown--discription--1' onClick={handleDescriptionClick1}>
                                <p>Size & Fit</p>
                                <img src={Frame} alt="Description Icon" />
                            </div>
                            {isContentVisible1 && (
                                <div className='Arsia--cart--dropdown--discription--1--size--fit'>
                                    <div className='Arsia--cart--size--usa'>
                                        <div className='Arsia--cart--size--usa--card'>
                                            <p className='Arsia--cart--size--usa--card--p'>Size</p>
                                            <div className='Arsia--cart--size--usa--card--1'>
                                                <p>US Size</p>
                                            </div>
                                        </div>
                                        <div className='Arsia--cart--size--usa--button'>
                                            <button>US 5.5</button>
                                            <button>US 6</button>
                                            <button>US 7</button>
                                            <button>US 7.5</button>
                                            <button>US 8</button>
                                            <button>US 8.5</button>
                                            <button>US 9</button>
                                            <button>US 10</button>
                                        </div>

                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='Arsia--cart--border--1'></div>
                    <div className='Arsia--cart--dropdown--discription'>
                        <div >
                            <div className='Arsia--cart--dropdown--discription--1' onClick={handleDescriptionClick2}>
                                <p>Delivery & Returns</p>
                                <img src={Frame} alt="Description Icon" />
                            </div>
                            {isContentVisible2 && (
                                <div className='Arsia--cart--size--usa'>


                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='Arsia--product--most--review'>
                <div className='Arsia--below--content--5-1'>
                    <p className='Arsia--below--content--1--3'>
                        You may also like
                    </p>
                    <p className='Arsia--below--content--2--3'>
                        Every Angle exposed
                    </p>
                </div>
            </div>
            <div className='Arsia--collection--below--content--part'>
                <div className='Arsia--below--content--card--carousal'>
                    <div className='Arsia--below--content--card--carousal--1--collection'>
                        <img src={pic5}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Frecesca LE´ | Red Slit Marvel</p>
                            <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                        </div>
                    </div>
                    <div className='Arsia--below--content--card--carousal--1--collection'>
                        <img src={img9}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Frecesca LE´ | Red Slit Marvel</p>
                            <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                        </div>
                    </div>
                    <div className='Arsia--below--content--card--carousal--1--collection'>
                        <img src={pic1}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Frecesca LE´ | Red Slit Marvel</p>
                            <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                        </div>
                    </div>
                    <div className='Arsia--below--content--card--carousal--1--collection'>
                        <img src={pic6}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Frecesca LE´ | Red Slit Marvel</p>
                            <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Arsia--footer--design'>
                <div className='Arsia--footer--design--1'>
                    <div className='Arsia--footer--design--1--card1'>
                        <div className='Arsia--footer--design--1--card1--1'>
                            <p className='Arsia--footer--heading'>Quick Links</p>
                            <p className='Arsia--footer--heading--1'>Track My Order</p>
                            <p className='Arsia--footer--heading--1'>My Account</p>
                            <p className='Arsia--footer--heading--1'>Returns</p>
                            <p className='Arsia--footer--heading--1'>Customer Care</p>
                            <p className='Arsia--footer--heading--1'>About Us & More</p>
                        </div>
                        <div className='Arsia--footer--design--1--card1--2'>
                            <p className='Arsia--footer--heading--1'>Help/Get In Touch With Us</p>
                            <p className='Arsia--footer--heading--1'>Returns</p>
                            <p className='Arsia--footer--heading--1'>Delivery Info</p>
                            <p className='Arsia--footer--heading--1'>Terms & Condition</p>
                            <p className='Arsia--footer--heading--1'>Get Offers & Updates</p>
                        </div>
                    </div>
                    <div className='Arsia--footer--design--1--card2'>
                        <div className='Arsia--footer--design--1--card1--1'>
                            <p className='Arsia--footer--heading'>Contacts</p>
                            <p className='Arsia--footer--heading--1'>arshiyasehgal@gmail.in</p>
                            <div className='Arsia--footer--design--1--card1--1--3'>
                                <p className='Arsia--footer--heading--1'>+358887129366</p>
                                <p className='Arsia--footer--heading--1'>+359885730505</p>
                            </div>
                            <p className='Arsia--footer--heading--1'>str. YourStreet 123, Sofia, Bulgaria</p>
                            <div className='Arsia--image--all'>
                                <img src={facebook}></img>
                                <img src={insta}></img>
                                <img src={linked}></img>
                                <img src={pint}></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='Arsia--footer--border--line'></div>
                <div className='Arsia--footer--copywrite--design'>
                    <p>© 2023 Arshiya Sehgal, All Rights Reserved. Made with by We Reach</p>
                </div>
            </div>
        </div>

          {/* Arsia--Mobile--view */}
      <div className="Arsia--website--design--mobile--view">
      <div className='Arsia--header'>
          <p>Orders at Arshiya Seghal are packed with love. Get this love at flat 10% off on your first order.</p>
        </div>
        {isSearchCardOpen ? (
          <div className='Arsia--Navbar--search--1'>
          
           <img className='Arsia--Navbar--search--1--back' src={back} alt='Arrow Left' onClick={handleArrowLeftClick}></img>
        

            <div className='Arsia--Navbar--search--1--2'>
              <input placeholder='Search...'></input>
              <img src={search1} alt='Search'></img>
            </div>
          </div>
        ) : (
          <div className='Arsia--Navbar'>
            <div className='Arsia--Navbar-1'>
              <div className='Arsia--Navbar-1--8'>
                <div>
                  <img
                    className='Arsia--Navbar-1--img1'
                    onClick={showSidebar}
                    src={navbar}
                    alt='Navbar'
                  />
                  <nav>
                    <ul className={`sidebar ${sidebarVisible ? 'visible' : ''}`}>
                      <li>
                        <div className='Arsia--ham--profile'>
                          <div className='Arsia--ham--profile--p'>
                            <img src={avtar}></img>
                            <p>Sign in | Register</p>
                          </div>
                          <img className='Arsia--ham--profile--img--cross' onClick={hideSidebar} src={cross} alt='Close' />
                        </div>
                      </li>
                      <li>
                        <div className='Arsia--ham--content'>
                          <div className='Arsia--ham--content1'>
                            <div className='Arsia--ham--content--1'>
                              <img src={home}></img>
                              <p>Home</p>
                            </div>
                            <div className='Arsia--ham--content--1'>
                              <Link to={"/Arsia--collection"} style={{ textDecoration: 'none' }}>
                              <img src={collection}></img>
                              <p>Collection</p>
                              </Link>
                            </div>
                            <div className='Arsia--ham--content--1'>
                              <img src={favorite}></img>
                              <p>Favorite</p>
                            </div>
                          </div>
                          <div className='Arsia--ham--content--border--line'></div>
                          <div className='Arsia--ham--content1'>
                            <div className='Arsia--ham--content--1'>
                              <img src={language}></img>
                              <p>English | USD</p>
                            </div>
                            <div className='Arsia--ham--content--1'>
                              <img src={headset}></img>
                              <p>Contact us</p>
                            </div>
                            <div className='Arsia--ham--content--1'>
                              <img src={business}></img>
                              <p>About</p>
                            </div>
                          </div>
                          <div className='Arsia--ham--content--border--line'></div>
                          <div className='Arsia--ham--content1--pp'>
                            <p >Privacy Policy</p>
                          </div>
                          <div className='Arsia--ham--content1--signout'>
                            <p >Sign Out</p>   
                          </div>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
                <img className='Arsia--Navbar-1--img2' src={logo3} alt='Logo'></img>
              </div>
            </div>
            <div className='Arsia--icon1'>
              <img src={heart} alt='Heart'></img>
              <img src={search1} alt='Search' onClick={handleSearchIconClick}></img>
              {/* <img src={icon3} alt='Icon'></img> */}
            </div>
          </div>
        )}
       <div className='Arsia--product--mobile--view'>
       <Carousel autoplay>
          
            <div className="Arsia--product--mobile--view--img">
              <img src={verticar3}></img>
            </div>
            <div className="Arsia--product--mobile--view--img">
              <img src={verticar3}></img>
            </div>
            <div className="Arsia--product--mobile--view--img">
              <img src={verticar3}></img>
            </div>
            <div className="Arsia--product--mobile--view--img">
              <img src={verticar3}></img>
            </div>
         
        </Carousel>
       </div>
       <div className='Arsia--carausal--and--cart'>
                <div className='Arsia--caraousal'>
                    <ArsiaVerticalCarousal />
                    <div className='Arsia--caraousal--video'>
                        <img src={videoimg}></img>
                        <div className='Arsia--caraousal--video--1'>
                            <img src={play}></img>
                        </div>
                    </div>

                </div>
                <div className='Arsia--cart'>
                    <div className='Arsia--cart--1--card'>
                    <div className='Arsia--cart--caraousal--card'>
                        <p>Red Slit Marvel</p>
                    </div>
                        <div className='Arsia--cart--1'>
                            <img src={instaa}></img>
                            <img src={face}></img>
                            <img src={twitta}></img>
                            <img src={pintaa}></img>
                        </div>
                    </div>
                    <div className='Arsia--cart--1--card'>
                        <div className='Arsia--cart--1--2'>
                            <p>Rs 14,000</p>
                        </div>
                    </div>
                    <div className='Arsia--cart--1--card'>
                        <div className='Arsia--cart--1--2--text'>
                            <p>The gently curved lines accentuated by sewn details are kind to your body and pleasant to look at. Also, there’s a tilt and height-adjusting mechanism that’s built to outlast years of ups and downs.</p>
                        </div>

                    </div>
                    <div className='Arsia--cart--size--usa'>
                        <div className='Arsia--cart--size--usa--card'>
                            <p className='Arsia--cart--size--usa--card--p'>Size</p>
                            <div className='Arsia--cart--size--usa--card--1'>
                                <p>US Size</p>
                            </div>
                        </div>
                        <div className='Arsia--cart--size--usa--button'>
                            <button>US 5.5</button>
                            <button>US 6</button>
                            <button>US 7</button>
                            <button>US 7.5</button>
                            <button>US 8</button>
                            <button>US 8.5</button>
                            <button>US 9</button>
                            <button>US 10</button>
                        </div>

                        <div className='Arsia--cart--1--card'>
                        <div className='Arsia--cart--size--usa--card'>
                            <p className='Arsia--cart--size--usa--card--p'>Qty:</p>
                            <div className='Arsia--cart--size--usa--card--1--add'>
                                <p >-</p>
                                <p>0</p>
                                <p >+</p>
                            </div>
                        </div>
                        <div className='Arsia--cart--1'>
                            <img src={heart2}></img>
                            <p>Add to wishlist</p>
                        </div>
                      
                    </div>

                       
                        <div className='Arsia--cart--size--usa--card--price'>
                            <p>Ask For Price</p>
                        </div>
                        <div className='Arsia--cart--size--usa--card--free--ship'>
                            <p>Free 3-5 day shipping </p>
                            <p> •  Tool-free assembly</p>
                            <p> •  30-day trial</p>
                        </div>
                    </div>

                    <div className='Arsia--cart--border--1'></div>
                    <div className='Arsia--cart--dropdown--discription'>
                        <div >
                            <div className='Arsia--cart--dropdown--discription--1' onClick={handleDescriptionClick}>
                                <p>Description</p>
                                <img src={Frame} alt="Description Icon" />
                            </div>
                            {isContentVisible && (
                                <div className='Arsia--cart--dropdown--discription--1--content'>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='Arsia--cart--border--1'></div>

                    <div className='Arsia--cart--dropdown--discription'>
                        <div >
                            <div className='Arsia--cart--dropdown--discription--1' onClick={handleDescriptionClick1}>
                                <p>Size & Fit</p>
                                <img src={Frame} alt="Description Icon" />
                            </div>
                            {isContentVisible1 && (
                                <div className='Arsia--cart--dropdown--discription--1--size--fit'>
                                    <div className='Arsia--cart--size--usa'>
                                        <div className='Arsia--cart--size--usa--card'>
                                            <p className='Arsia--cart--size--usa--card--p'>Size</p>
                                            <div className='Arsia--cart--size--usa--card--1'>
                                                <p>US Size</p>
                                            </div>
                                        </div>
                                        <div className='Arsia--cart--size--usa--button'>
                                            <button>US 5.5</button>
                                            <button>US 6</button>
                                            <button>US 7</button>
                                            <button>US 7.5</button>
                                            <button>US 8</button>
                                            <button>US 8.5</button>
                                            <button>US 9</button>
                                            <button>US 10</button>
                                        </div>

                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='Arsia--cart--border--1'></div>
                    <div className='Arsia--cart--dropdown--discription'>
                        <div >
                            <div className='Arsia--cart--dropdown--discription--1' onClick={handleDescriptionClick2}>
                                <p>Delivery & Returns</p>
                                <img src={Frame} alt="Description Icon" />
                            </div>
                            {isContentVisible2 && (
                                <div className='Arsia--cart--size--usa'>


                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='Arsia--mobile--content--1'>
          <div className='Arsia--below--content--5'>
            <p className='Arsia--below--content--1'>
              Most Bought Pieces
            </p>
            <p className='Arsia--below--content--2'>
              The love of every woman
            </p>
          </div>
          <div className='Arsia--below--content--card--carousal'>
            <div className='Arsia--below--content--card--carousal--1'>
              <img src={img9}></img>
              <div className='Arsia--below--content--card--carousal--1--price'>
                <p className='Arsia--below--content--card--carousal--1--price--p1'>Frecesca LE´ </p>
                <p className='Arsia--below--content--card--carousal--1--price--p2'>Xs  S  M  L  XL  XXL   </p>
                <p className='Arsia--below--content--card--carousal--1--price--p3'>₹15,699</p>
              </div>
            </div>
            <div className='Arsia--below--content--card--carousal--1'>
              <img src={pic6}></img>
              <div className='Arsia--below--content--card--carousal--1--price'>
                <p className='Arsia--below--content--card--carousal--1--price--p1'>Frecesca LE´</p>
                <p className='Arsia--below--content--card--carousal--1--price--p2'>Xs  S  M  L  XL  XXL   </p>
                <p className='Arsia--below--content--card--carousal--1--price--p3'>₹15,699</p>
              </div>
            </div>
            <div className='Arsia--below--content--card--carousal--1'>
              <img src={pic3}></img>
              <div className='Arsia--below--content--card--carousal--1--price'>
                <p className='Arsia--below--content--card--carousal--1--price--p1'>Frecesca LE´ </p>
                <p className='Arsia--below--content--card--carousal--1--price--p2'>Xs  S  M  L  XL  XXL   </p>
                <p className='Arsia--below--content--card--carousal--1--price--p3'>₹15,699</p>
              </div>
            </div>
            <div className='Arsia--below--content--card--carousal--1'>
              <img src={pic3}></img>
              <div className='Arsia--below--content--card--carousal--1--price'>
                <p className='Arsia--below--content--card--carousal--1--price--p1'>Frecesca LE´ </p>
                <p className='Arsia--below--content--card--carousal--1--price--p2'>Xs  S  M  L  XL  XXL   </p>
                <p className='Arsia--below--content--card--carousal--1--price--p3'>₹15,699</p>
              </div>
            </div>
          </div>
        </div>
            </div>
            <div className='Arsia--mobile--view--footer'>
          <img src={footername}></img>
          <div className='Arsia--mobile--view--footer--insta'>
            <img src={face7}></img>
            <img src={insta6}></img>
            <img src={liked9}></img>
          </div>
          <p className='Arsia--mobile--view--footer--p1'>+91 9810889012</p>
          <p className='Arsia--mobile--view--footer--p1'>54, block C, Sector 40, Noida, Uttar Pradesh 201303</p>
          <p className='Arsia--mobile--view--footer--p1'>arshiyasehgal40@gmail.com</p>
          {/* <p className='Arsia--mobile--view--footer--p1'>order@arshiyasehgal.com</p> */}
          {/* <p className='Arsia--mobile--view--footer--p1'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer</p>
        <a href='#' className='anchor--arshiya'>  <p className='Arsia--mobile--view--footer--p1--2-1'>Track Order</p></a>
        <a href='#' className='anchor--arshiya'><p className='Arsia--mobile--view--footer--p1--2'>My Account</p></a>
        <a href='#' className='anchor--arshiya'><p className='Arsia--mobile--view--footer--p1--2'>Customer Care</p></a>
        <a href='#' className='anchor--arshiya'><p className='Arsia--mobile--view--footer--p1--2'>About Us </p></a>
        <a href='#' className='anchor--arshiya'> <p className='Arsia--mobile--view--footer--p1--2'>Terms & Conditions</p></a>
        <a href='#' className='anchor--arshiya'> <p className='Arsia--mobile--view--footer--p1--2'>Returns</p></a>
        <a href='#' className='anchor--arshiya'> <p className='Arsia--mobile--view--footer--p1--2'>Get Offers & Updates</p></a>
          <div className='Arsia--mobile--view--footer--insta'>
            <img src={vector7}></img>
            <img src={vector9}></img>
            <img src={visa}></img>
            <img src={upi8}></img>
          </div> */}
          <p className='Arsia--mobile--view--footer--p1'>© 2023 Arshiya Sehgal, All Rights Reserved.</p>
          <p className='Arsia--mobile--view--footer--p1'>Made with by ❤️ We Reach</p>
        </div>
      </div>
      {/* Arsia--Mobile--view--end */}
        </div>
    );
}