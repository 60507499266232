import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { logout as apiLogout } from "../../services/apiUser";
import { userAuthenticate } from "./userSlice";

export const useLogout = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate: logout, isPending: isLogout } = useMutation({
    mutationFn: apiLogout,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
      localStorage.clear("authToken");
      dispatch(userAuthenticate(false));
      toast.success(res.message);
    },
    onError: (err) => {
      console.log(err);
      toast.error(err.message);
    },
  });

  return { logout, isLogout };
};
