import { objectToFormData } from "../utils/helper";

export const login = async (user) => {
  const myHeaders = new Headers();
  const formdata = objectToFormData(user);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };
  try {
    const res = await fetch(
      "https://admin.arshiyasehgal.com/API/AuthUser/login",
      requestOptions
    );
    const data = await res.json();

    if (data.status !== true) {
      throw new Error(data.message);
    }
    console.log(data);
    localStorage.setItem("authToken", data.token);
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const signUp = async (user) => {
  console.log(user);
  try {
    const res = await fetch(
      "https://arshiyasehgal-backend.vercel.app/api/v1/users/signUp",
      {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await res.json();
    console.log(data);

    if (data.status !== "success") {
      throw new Error(data.message);
    }
    localStorage.setItem("authToken", data.token);
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const otpVerify = async (otp) => {
  const token = localStorage.getItem("authToken");
  const myHeaders = new Headers();
  const formdata = objectToFormData({ ...otp, Authorization: token });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      "https://admin.arshiyasehgal.com/API/AuthUser/verifyOtp",
      requestOptions
    );
    const data = await res.json();
    localStorage.setItem("user", JSON.stringify(data.data[0]));
    console.log(data);

    if (data.status !== true) {
      throw new Error(data.message);
    }

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const logout = async () => {
  const token = localStorage.getItem("authToken");

  try {
    const res = await fetch(
      "https://arshiyasehgal-backend.vercel.app/api/v1/users/logout",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await res.json();

    if (data.status !== "success") {
      throw new Error(data.message);
    }

    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};
