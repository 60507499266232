import "./Arsia.css";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import heart2 from "./image/heart2.svg";
import face from "./image/face.svg";
import pintaa from "./image/pintaa.svg";
import twitta from "./image/twitta.svg";
import instaa from "./image/instaa.svg";
import ArsiaVerticalCarousal from "./Arsia-vetical-caraousal/Arsia--vertical--carousal";
import ArsiaHeader from "./ArsiaHeader";
import Frame from "./image/Frame.png";
import play from "./image/play.png";
import { Carousel } from "antd";
import sitareproduct2 from "./image/sitareproduct2.png";
import ArsiaFooter from "./ArsiaFooter";
import ArsiaHeaderMobile from "./Arsia-header-mobile";
import FooterMobile from "./Arsia--footer--mobile";
import ArsiaSizePopup from "./Arsia-size-popup";
import { useGetProduct } from "../features/product/useGetProduct";
import { IMAGE_PATH } from "../utils/constant";
import { priceFormat } from "../utils/helper";
import Loader from "../ui/Loader";
import { useAddCart } from "../features/cart/useAddCart";
import { addingCart } from "../features/cart/cartSlice";
import { useGetCart } from "../features/cart/useGetCart";
import { useHomePage } from "../features/siteManage/useHomepage";

export default function ArsiaSitare() {
  const { productId } = useParams();
  const { isLoading, error, product } = useGetProduct(productId);
  const { isAdding, addCart } = useAddCart();
  const { isAuthenticated } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { cart } = useGetCart();
  const { homepage } = useHomePage();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);



  const [isContentVisible, setIsContentVisible] = useState(false);

  const handleDescriptionClick = () => {
    setIsContentVisible(!isContentVisible);
  };
  const [isContentVisible1, setIsContentVisible1] = useState(false);

  const handleDescriptionClick1 = () => {
    setIsContentVisible1(!isContentVisible1);
  };
  const [isContentVisible2, setIsContentVisible2] = useState(false);

  const handleDescriptionClick2 = () => {
    setIsContentVisible2(!isContentVisible2);
  };
  const [isContentVisible3, setIsContentVisible3] = useState(false);

  const handleDescriptionClick3 = () => {
    setIsContentVisible3(!isContentVisible3);
  };
  const [isSearchCardOpen, setIsSearchCardOpen] = useState(false);
  const handleSearchIconClick = () => {
    setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
  };
  const handleArrowLeftClick = () => {
    setIsSearchCardOpen(!isSearchCardOpen);
  };
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const showSidebar = () => {
    setSidebarVisible(true);
  };

  const hideSidebar = () => {
    setSidebarVisible(false);
  };
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  const [isAddToCartClicked, setIsAddToCartClicked] = useState(false);

  const handleAddToCartClick = (product) => {

    if (selectedSize !== "") {
      if (isAuthenticated) {
        addCart({ product_id: product.id, qty: 1 });
        setIsAddToCartClicked(!isAddToCartClicked);
      }

      if (!isAuthenticated) {
        const cartData = {
          name: product.name,
          size: selectedSize,
          product_id: product.id,
          qty: 1,
          price: product.price,
          image: product.images[0].img,
          mrp: product.mrp,
          discount: product.discount
        };
        setIsAddToCartClicked(!isAddToCartClicked);

        dispatch(addingCart(cartData));
      }
    } else {
      alert("Please select a size before adding to cart.");
    }
  };

  const priceStyle = {
    textDecoration: "line-through",
    color: "#888",
    marginLeft: "3px",
  };

  const discountPriceStyle = {
    color: "black",
    // fontWeight: "bold",
  };

  const [selectedSize, setSelectedSize] = useState("");
  const handleSizeSelect = (size) => {
    setSelectedSize(size);
    let updateSize = {
      name: product.name,
      size: size,
      product_id: product.id,
      qty: 0
    }

    addCart(updateSize);
  };
  const [selectedColor, setSelectedColor] = useState("");

  const handleColorSelection = (colorId) => {
    setSelectedColor(colorId);
    let updateColorID = {
      colour: colorId,
      product_id: product.id,
      qty: 0
    }
    addCart(updateColorID);
  };


  if (isLoading) return <Loader />;
  if (error) return <div>{error.message}</div>;


  return (
    <div>
      <div className="Arsia--website--design">
        <ArsiaHeader />
        <div className="Arsia--carausal--and--cart">
          <div className="Arsia--caraousal">
            <div className="Arsia--carousal--1">
              <div className="Arsia--vertical--carousal--left">
                <div className="Arsia--below--content--card--carousal--left">
                  {/* <div className="Arsia--vertical--carousal--left--card">
                    <img
                      src={sitarethin}
                      onClick={() => handleImageClick(sitareproduct)}
                      alt="img"
                    />
                  </div> */}
                  {product?.images.map((image, i) => {
                    return (
                      <div className="Arsia--vertical--carousal--left--card">
                        <img
                          src={`${IMAGE_PATH}${image.img}`}
                          onClick={() =>
                            handleImageClick(`${IMAGE_PATH}${image.img}`)
                          }
                          alt="img"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="Arsia--vertical--carousal--right">
                {selectedImage ? (
                  <img src={selectedImage} alt="Selected" />
                ) : (
                  <img
                    src={`${IMAGE_PATH}${product?.images[0]?.img}`}
                    alt="Default"
                  />
                )}
              </div>
            </div>
          </div>
          <div className="Arsia--cart">
            <div className="Arsia--cart--1--card">
              <div className="Arsia--cart--1">
                <img src={heart2} alt="" />
                <p>Add to wishlist</p>
              </div>
              <div className="Arsia--cart--1">
                <img src={instaa} alt="" />
                <img src={face} alt="" />
                <img src={twitta} alt="" />
                <img src={pintaa} alt="" />
              </div>
            </div>
            <div className="Arsia--cart--caraousal--card">
              <p>{product?.name}</p>
            </div>
            <div className="Arsia--cart--1--card">
              <div className="Arsia--cart--1--2">
                {
                  product.discount === "0" ?
                    <p className="Arsia--below--content--card--carousal--1--price--p1--2">
                      {" "}
                      <span style={discountPriceStyle}>
                        {priceFormat(+product.price)}
                      </span>
                    </p>
                    :
                    <p className="Arsia--below--content--card--carousal--1--price--p1--2">
                      {" "}
                      <span style={discountPriceStyle}>
                        {priceFormat(+product.price)}
                      </span>
                      {"  "}
                      <span style={priceStyle}>
                        {priceFormat(+product.mrp)}
                      </span>{" "}
                      <span style={{ color: "#E74C3C", marginLeft: "3px" }}>
                        {product.discount}% OFF
                      </span>
                    </p>
                }

              </div>
            </div>
            <div className="Arsia--cart--1--card">
              <div className="Arsia--cart--1--2--text">
                <p>{product?.des}</p>
              </div>
            </div>
            <div className="Arsia--cart--size--usa">
              <div className="Arsia--cart--size--usa">
                {product?.colours.length > 0 ? (
                  <div className="Arsia--cart--size--usa--button6">
                    <div className="Arsia--cart--size--usa--card">
                      <p className="Arsia--cart--size--usa--card--p">Color</p>
                    </div>
                    <div>
                      {product.colours.map((color) => (
                        <div
                          key={color.id}
                          className="Arsia--cart--size--usa--button-1"
                          onClick={() => handleColorSelection(color.id)}
                        >
                          <img
                            className={`Arsia--cart--size--usa--button-1-img ${selectedColor === color.id ? "selected3" : ""
                              }`}
                            src={`${IMAGE_PATH}${color.img}`}
                            alt="Default"
                          />
                          <p>{color.name}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="Arsia--cart--size--usa--card">
                <p className="Arsia--cart--size--usa--card--p">Size</p>
              </div>
              <div className="Arsia--cart--size--usa--button">
                {product?.size &&
                  product.size.map((size, index) => (
                    <p
                      key={index}
                      className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === size.trim().toLowerCase()
                        ? "selected"
                        : ""
                        }`}
                      onClick={() =>
                        handleSizeSelect(size.trim().toLowerCase())
                      }
                    >
                      {size}
                    </p>
                  ))}
              </div>

              <div className="Arsia--add--to--cart--and--buy--now">
                <div>
                  {isAddToCartClicked ? (
                    <Link to={"/Cart"} className="arsia--product--link">
                      <div className="Arsia--cart--size--usa--card--price">
                        <p>Go to cart</p>
                      </div>
                    </Link>
                  ) : (
                    <div
                      disabled={isAdding}
                      className="Arsia--cart--size--usa--card--price"
                      onClick={() => handleAddToCartClick(product)}
                    >
                      <p>{isAdding ? "Loading..." : "Add to cart"}</p>
                    </div>
                  )}
                </div>
                {/* <Link
                  to={"/paymentGatewayPage"}
                  className="arsia--product--link"
                >
                  <div className="Arsia--cart--size--usa--card--price">
                    <p>Buy Now</p>
                  </div>
                </Link> */}
              </div>
              {/* <div className='Arsia--cart--size--usa--card--free--ship'>
                                <p>Free 3-5 day shipping </p>
                                <p> •  Tool-free assembly</p>
                                <p> •  30-day trial</p>
                            </div> */}
            </div>
            <ArsiaSizePopup />
            <div className="Arsia--cart--border--1"></div>
            <div className="Arsia--cart--dropdown--discription">
              <div>
                <div
                  className="Arsia--cart--dropdown--discription--1"
                  onClick={handleDescriptionClick}
                >
                  <p>Description</p>
                  <img src={Frame} alt="Description Icon" />
                </div>
                {isContentVisible && (
                  <div className="Arsia--cart--1--2--text">
                    <p>{product?.long_des}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="Arsia--cart--border--1"></div>

            <div className="Arsia--cart--dropdown--discription">
              <div>
                <div
                  className="Arsia--cart--dropdown--discription--1"
                  onClick={handleDescriptionClick1}
                >
                  <p>Fabric</p>
                  <img src={Frame} alt="Description Icon" />
                </div>
                {isContentVisible1 && (
                  <div className="Arsia--cart--1--2--text">
                    <p>{product?.fabric}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="Arsia--cart--border--1"></div>
            <div className="Arsia--cart--dropdown--discription">
              <div>
                <div
                  className="Arsia--cart--dropdown--discription--1"
                  onClick={handleDescriptionClick2}
                >
                  <p>Product Care</p>
                  <img src={Frame} alt="Description Icon" />
                </div>
                {isContentVisible2 && (
                  <div className="Arsia--cart--1--2--text">
                    <p>{product?.product_care}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="Arsia--cart--border--1"></div>
            <div className="Arsia--cart--dropdown--discription">
              <div>
                <div
                  className="Arsia--cart--dropdown--discription--1"
                  onClick={handleDescriptionClick3}
                >
                  <p>Disclaimer</p>
                  <img src={Frame} alt="Description Icon" />
                </div>
                {isContentVisible3 && (
                  <div className="Arsia--cart--1--2--text">
                    <p>{product?.disclaimer}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="Arsia--below--content--5">
          <p className="Arsia--below--content--1--3">Most Bought Pieces</p>
          <p className="Arsia--below--content--2--3">the love of every woman</p>
        </div>
        <div className="Arsia--collection--below--content--part">
          <div className="Arsia--below--content--card--carousal">
            {homepage[0].most_bought_pieces?.map((product) => (
              <Link to={product.url} className="arsia--product--link">
                <div className="Arsia--below--content--card--carousal--1--collection">
                  <img
                    src={`${IMAGE_PATH}${product.img}`}
                    alt=""
                    height={"270px"}
                    width={"100%"}
                  />
                  <div className="Arsia--below--content--card--carousal--1--price">
                    <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                      {product.title}
                    </p>
                    {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                  </div>
                </div>
              </Link>
            ))}
            {/* <Link to={"/Sitare-Hazel"} className="arsia--product--link">
              <div className="Arsia--below--content--card--carousal--1--collection">
                <img src={sitaremost1}></img>
                <div className="Arsia--below--content--card--carousal--1--price">
                  <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                    Sitare{" "}
                  </p>
                  <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                </div>
              </div>
            </Link>
            <Link to={"/laila-Hazel"} className="arsia--product--link">
              <div className="Arsia--below--content--card--carousal--1--collection">
                <img src={lailamostbought}></img>
                <div className="Arsia--below--content--card--carousal--1--price">
                  <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                    Laila
                  </p>
                  <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                </div>
              </div>
            </Link>
            <Link to={"/Lady-In_red-Hazel"} className="arsia--product--link">
              <div className="Arsia--below--content--card--carousal--1--collection">
                <img src={redmost1}></img>
                <div className="Arsia--below--content--card--carousal--1--price">
                  <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                    Lady in Red{" "}
                  </p>
                  <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                </div>
              </div>
            </Link>
            <Link to={"/Moonlight-Hazel"} className="arsia--product--link">
              <div className="Arsia--below--content--card--carousal--1--collection">
                <img src={moonlightmost2}></img>
                <div className="Arsia--below--content--card--carousal--1--price">
                  <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                    Moonlight{" "}
                  </p>
                  <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                </div>
              </div>
            </Link> */}
          </div>
        </div>
        <ArsiaFooter />
      </div>

      {/* Arsia--Mobile--view */}
      <div className="Arsia--website--design--mobile--view">
        <ArsiaHeaderMobile />
        <div className="Arsia--product--mobile--view">
          <Carousel autoplay dots={false}>
            {product?.images.map((image, i) => {
              return (
                <div key={i} className="Arsia--product--mobile--view--img">
                  <img src={`${IMAGE_PATH}${image?.img}`} alt="imgs"></img>
                </div>
              );
            })}
          </Carousel>
        </div>
        <div className="Arsia--carausal--and--cart">
          <div className="Arsia--caraousal">
            <ArsiaVerticalCarousal />
            <div className="Arsia--caraousal--video">
              <img src={sitareproduct2}></img>
              <div className="Arsia--caraousal--video--1">
                <img src={play}></img>
              </div>
            </div>
          </div>
          <div className="Arsia--cart">
            <div className="Arsia--cart--1--card">
              <div className="Arsia--cart--caraousal--card">
                <p>{product?.name}</p>
              </div>
              <div className="Arsia--cart--1">
                <img src={instaa}></img>
                <img src={face}></img>
                <img src={twitta}></img>
                <img src={pintaa}></img>
              </div>
            </div>
            <div className="Arsia--cart--1--card">
              <div className="Arsia--cart--1--2">
                {
                  product.discount === "0" ?
                    <p className="Arsia--below--content--card--carousal--1--price--p1--2">
                      {" "}
                      <span style={discountPriceStyle}>
                        {priceFormat(+product.price)}
                      </span>
                    </p>
                    :
                    <p className="Arsia--below--content--card--carousal--1--price--p1--2">
                      {" "}
                      <span style={discountPriceStyle}>
                        {priceFormat(+product.price)}
                      </span>
                      {"  "}
                      <span style={priceStyle}>
                        {priceFormat(+product.mrp)}
                      </span>{" "}
                      <span style={{ color: "#E74C3C", marginLeft: "3px" }}>
                        {product.discount}% OFF
                      </span>
                    </p>
                }

              </div>
            </div>
            <div className="Arsia--cart--1--card">
              <div className="Arsia--cart--1--2--text">
                <p>{product?.des}</p>
              </div>
            </div>

            <div className="Arsia--cart--size--usa">
              {product?.colours.length > 0 ? (
                <div className="Arsia--cart--size--usa--button6">
                  <div className="Arsia--cart--size--usa--card">
                    <p className="Arsia--cart--size--usa--card--p">Color</p>
                  </div>
                  <div>
                    {product.colours.map((color) => (
                      <div
                        key={color.id}
                        className="Arsia--cart--size--usa--button-1"
                        onClick={() => handleColorSelection(color.id)}
                      >
                        <img
                          className={`Arsia--cart--size--usa--button-1-img ${selectedColor === color.id ? "selected3" : ""
                            }`}
                          src={`${IMAGE_PATH}${color.img}`}
                          alt="Default"
                        />
                        <p>{color.name}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="Arsia--cart--size--usa--card">
              <p className="Arsia--cart--size--usa--card--p">Size</p>
            </div>
            <div className="Arsia--cart--size--usa--button">
              {product?.size &&
                product.size.map((size, index) => (
                  <p
                    key={index}
                    className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === size.trim().toLowerCase()
                      ? "selected"
                      : ""
                      }`}
                    onClick={() => handleSizeSelect(size.trim().toLowerCase())}
                  >
                    {size}
                  </p>
                ))}
            </div>
            <ArsiaSizePopup />

            <div>
              {isAddToCartClicked ? (
                <Link to={"/Cart"} className="arsia--product--link">
                  <div className="Arsia--cart--size--usa--card--price-mobile">
                    <p>Go to cart</p>
                  </div>
                </Link>
              ) : (
                <div
                  disabled={isAdding}
                  className="Arsia--cart--size--usa--card--price-mobile"
                  onClick={() => handleAddToCartClick(product)}
                >
                  <p>{isAdding ? "Loading..." : "Add to cart"}</p>
                </div>
              )}
            </div>

            <div className="Arsia--cart--border--1"></div>
            <div className="Arsia--cart--dropdown--discription">
              <div>
                <div
                  className="Arsia--cart--dropdown--discription--1"
                  onClick={handleDescriptionClick}
                >
                  <p>Description</p>
                  <img src={Frame} alt="Description Icon" />
                </div>
                {isContentVisible && (
                  <div className="Arsia--cart--1--2--text">
                    <p>{product?.long_des}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="Arsia--cart--border--1"></div>

            <div className="Arsia--cart--dropdown--discription">
              <div>
                <div
                  className="Arsia--cart--dropdown--discription--1"
                  onClick={handleDescriptionClick1}
                >
                  <p>Fabric</p>
                  <img src={Frame} alt="Description Icon" />
                </div>
                {isContentVisible1 && (
                  <div className="Arsia--cart--1--2--text">
                    <p>{product?.fabric}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="Arsia--cart--border--1"></div>
            <div className="Arsia--cart--dropdown--discription">
              <div>
                <div
                  className="Arsia--cart--dropdown--discription--1"
                  onClick={handleDescriptionClick2}
                >
                  <p>Product Care</p>
                  <img src={Frame} alt="Description Icon" />
                </div>
                {isContentVisible2 && (
                  <div className="Arsia--cart--1--2--text">
                    <p>{product?.product_care}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="Arsia--cart--border--1"></div>
            <div className="Arsia--cart--dropdown--discription">
              <div>
                <div
                  className="Arsia--cart--dropdown--discription--1"
                  onClick={handleDescriptionClick3}
                >
                  <p>Disclaimer</p>
                  <img src={Frame} alt="Description Icon" />
                </div>
                {isContentVisible3 && (
                  <div className="Arsia--cart--1--2--text">
                    <p>{product?.disclaimer}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="Arsia--mobile--content--1">
            <div className="Arsia--below--content--5">
              <p className="Arsia--below--content--1">Most Bought Pieces</p>
              <p className="Arsia--below--content--2">
                The love of every woman
              </p>
            </div>
            <div className="Arsia--below--content--1--content">
              <div className="Arsia--below--content--card--carousal">
                {homepage[0].most_bought_pieces?.map((product) => (
                  <Link to={product.url} className="arsia--product--link">
                    <div className="Arsia--below--content--card--carousal--1--collection">
                      <img
                        src={`${IMAGE_PATH}${product.img}`}
                        alt=""
                        height={"370px"}
                        width={"100%"}
                      />
                      <div className="Arsia--below--content--card--carousal--1--price">
                        <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                          {product.title}
                        </p>
                        {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                      </div>
                    </div>
                  </Link>
                ))}
                {/* <Link to={"/Sitare-Hazel"} className="arsia--product--link">
                  <div className="Arsia--below--content--card--carousal--1--collection">
                    <img src={sitaremost1}></img>
                    <div className="Arsia--below--content--card--carousal--1--price">
                      <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                        Sitare{" "}
                      </p>
                      <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                    </div>
                  </div>
                </Link>
                <Link to={"/laila-Hazel"} className="arsia--product--link">
                  <div className="Arsia--below--content--card--carousal--1--collection">
                    <img src={lailamostbought}></img>
                    <div className="Arsia--below--content--card--carousal--1--price">
                      <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                        Laila
                      </p>
                      <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                    </div>
                  </div>
                </Link>
                <Link
                  to={"/Lady-In_red-Hazel"}
                  className="arsia--product--link"
                >
                  <div className="Arsia--below--content--card--carousal--1--collection">
                    <img src={redmost1}></img>
                    <div className="Arsia--below--content--card--carousal--1--price">
                      <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                        Lady in Red{" "}
                      </p>
                      <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                    </div>
                  </div>
                </Link>
                <Link to={"/Moonlight-Hazel"} className="arsia--product--link">
                  <div className="Arsia--below--content--card--carousal--1--collection">
                    <img src={moonlightmost2}></img>
                    <div className="Arsia--below--content--card--carousal--1--price">
                      <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                        Moonlight{" "}
                      </p>
                      <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p>
                    </div>
                  </div>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <FooterMobile />
      </div>
      {/* Arsia--Mobile--view--end */}
    </div>
  );
}
