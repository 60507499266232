import React, { useState, useRef, useEffect } from 'react';
import './Arsia.css';
import ArsiaHeader from './ArsiaHeader';
import ascoll from './image/ascoll.png';
import { Link } from "react-router-dom";
import ArsiaFooter from './ArsiaFooter';
import collectionbanner from './image/collectionbanner.jpg';
import ArsiaHeaderMobile from './Arsia-header-mobile';
import FooterMobile from './Arsia--footer--mobile';
import ArsiaSortFilter from './Arsia-sort-filter';
import ArsiaSearchFilter from './Arsia--search--filter';

export default function ArsiaSearchPage() {

    const [isSearchCardOpen, setIsSearchCardOpen] = useState(false);

    const handleSearchIconClick = () => {
        setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
    };

    const handleArrowLeftClick = () => {
        setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
    };
    const [sidebarVisible, setSidebarVisible] = useState(false);

    const showSidebar = () => {
        setSidebarVisible(true);
    };

    const hideSidebar = () => {
        setSidebarVisible(false);
    };
   useEffect(() => {
     const inputElement = document.querySelector(".Arsia--search--input");
     if (inputElement) {
    //    inputElement.value = searchQuery || "";
       inputElement.focus();
     }
     window.scrollTo(0, 0);
   }, []);



    return (
        <div>
            <div className='Arsia--website--design'>

                <ArsiaHeader />
                <div className='search_page_css'>
                    <ArsiaSearchFilter />
                </div>
                <ArsiaFooter />
            </div>

            {/* Arsia--Mobile--view */}
            <div className="Arsia--website--design--mobile--view">
                <ArsiaHeaderMobile />
                <div className='search_page_css_mobile'>
                    <ArsiaSearchFilter />
                </div>
                <FooterMobile />
            </div>
            {/* Arsia--Mobile--view--end */}

        </div>

    );

}