import React, { useEffect } from "react";
import { 
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation, 
} from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import App from "./App";
import Arsia from "./Arsia--website/Arsia";
import ArsiaCollection from "./Arsia--website/Arsia--collection";
import ArsiaProduct from "./Arsia--website/Arsia--product";
import ArsiaLaila from "./Arsia--website/Arsia--laila";

import ArsiaAbout from "./Arsia--website/Arsia-About";
import ArsiaPrivacy from "./Arsia--website/privacy-policy";
import ArsiaReturn from "./Arsia--website/Retrun";
import ArsiaMoonlight from "./Arsia--website/Arsia-moonlight";

import ArsiaLadyared from "./Arsia--website/Arsia-Ladyred"; 
import ArsiaSitare from "./Arsia--website/Arsia-Sitare";

import ArsiaNewArrival from "./Arsia--website/Arsia-New-Arrival";
import ArsiaLimited from "./Arsia--website/Arsia-limited-edition";
import ArsiaHandPicks from "./Arsia--website/As-Handpick";
import ArsiaContactPage from "./Arsia--website/Arsia-contact";
import ArsiaCartPage from "./Arsia--website/Arsia--cart--page";
import ArsiaAddressLogin from "./Arsia--website/Arsia-Address--login";
import PaymentGatewayPage from "./Arsia--website/Payment-gateway--page";
import ArshiyaOrderSummuryPage from "./Arsia--website/Arshiya--Order--summury--page";
import ArsiaTermsandCondition from "./Arsia--website/Arsia--terms--and--condition";
import ArsiaShippingAddress from "./Arsia--website/Arsia--shipping--address";

import ArsiaProductDetail from "./Arsia--website/Arsia--product--detail";
import store from "./store";
import OrderSummary from "./Arsia--website/Arshiya--Order--summury--page";
import ArsiaCollections from "./Arsia--website/Arsia--Collections";
import CollectionProducts from "./Arsia--website/CollectionProducts";
import Arshiya_Cod_Order_Success from "./Arsia--website/Arsia--Cod--Order--Success";
import Arshiya_Prepaid_Order_Success from "./Arsia--website/Arsia--Prepaid--Order--Success";
import Arshiya_Order_Failed from "./Arsia--website/Arsia--Order--Failed";
import ArsiaSearchPage from "./Arsia--website/Arsia--search--page";

function Routerpage() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 0,
      },
    },
  });
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <Router> 
          <div className="router-page">
            <Routes>
              <Route path="/" element={<Arsia />}></Route>
              <Route path="/allproducts" element={<ArsiaCollection />}></Route>
              <Route path="/Collections" element={<ArsiaCollections />}></Route>
              <Route
                path="/Collections/:name"
                element={<CollectionProducts />}
              ></Route>

              <Route
                path="/:productName/:productId"
                element={<ArsiaProductDetail />}
              ></Route>

              <Route path="/Arsia--product" element={<ArsiaProduct />}></Route>
              <Route path="/laila-Hazel" element={<ArsiaLaila />}></Route>

              <Route
                path="/order-proceed"
                element={<ArsiaAddressLogin />}
              ></Route>
              <Route path="/order-summary" element={<OrderSummary />}></Route>

              <Route path="/About" element={<ArsiaAbout />}></Route>
              <Route path="/privacy-policy" element={<ArsiaPrivacy />}></Route>
              <Route
                path="/return-and-exchange"
                element={<ArsiaReturn />}
              ></Route>
              <Route
                path="/Moonlight-Hazel"
                element={<ArsiaMoonlight />}
              ></Route>

              <Route
                path="/Lady-In_red-Hazel"
                element={<ArsiaLadyared />}
              ></Route>
              <Route path="/Sitare-Hazel" element={<ArsiaSitare />}></Route>

              <Route path="/new-arrivals" element={<ArsiaNewArrival />}></Route>
              <Route
                path="/A.S-limited-editions"
                element={<ArsiaLimited />}
              ></Route>
              <Route
                path="/A.S-hand-picks"
                element={<ArsiaHandPicks />}
              ></Route>
              <Route path="/Contact" element={<ArsiaContactPage />}></Route>
              <Route path="/Cart" element={<ArsiaCartPage />}></Route>
              <Route
                path="/paymentGatewayPage"
                element={<PaymentGatewayPage />}
              ></Route>
              <Route
                path="/OrderSummury"
                element={<ArshiyaOrderSummuryPage />}
              ></Route>

              <Route path="/T&C" element={<ArsiaTermsandCondition />}></Route>
              <Route
                path="/ShippingAddress"
                element={<ArsiaShippingAddress />}
              ></Route>
              <Route
                path="/cod-success/:order_id"
                element={<Arshiya_Cod_Order_Success />}
              ></Route>
              <Route
                path="/prepaid-success/:order_id"
                element={<Arshiya_Prepaid_Order_Success />}
              ></Route>
              <Route
                path="/order-failed/:order_id"
                element={<Arshiya_Order_Failed />}
              ></Route>
              <Route
                path="/search-results"
                element={<ArsiaSearchPage />}
              ></Route>
            </Routes>
          </div>
        </Router>
        <Toaster
          position="top-center"
          gutter={12}
          containerStyle={{ margin: "28px" }}
          toastOptions={{
            success: {
              duration: 2000,
            },
            error: {
              duration: 3000,
            },
            style: {
              fontSize: "16px",
              maxWidth: "500px", 
              padding: "16px 24px",
              zIndex: 999,
            },
          }}
        />
      </Provider>
    </QueryClientProvider>
  );
}
export default Routerpage;
