export function priceFormat(price) {
  const [whole, decimal] = price.toFixed(0).toString().split(".");
  const formattedWhole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `₹${formattedWhole}${decimal ? `.${decimal}` : ""}`;
}

export function phoneNumberFormat(phoneNumber) {
  let cleaned = ("" + phoneNumber).replace(/\D/g, "");
  let formatted = cleaned.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");

  return formatted;
}

export function objectToFormData(obj) {
  const formData = new FormData();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      formData.append(key, obj[key]);
    }
  }

  return formData;
}
