import React, { useState, useRef, useEffect } from 'react';
import './Arsia.css';
import ArsiaHeader from './ArsiaHeader';
import ArsiaFooter from './ArsiaFooter';

import ArsiaHeaderMobile from './Arsia-header-mobile';
import FooterMobile from './Arsia--footer--mobile';
import newarrivalbanner  from './image/newarrivalbanner.jpg';
import newarrivalmobile from './image/newarrivalmobile.jpg';
import ArsiaSortFilter from './Arsia-sort-filter';

export default function ArsiaNewArrival() {

    const [isSearchCardOpen, setIsSearchCardOpen] = useState(false);

  const handleSearchIconClick = () => {
    setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
  };

  const handleArrowLeftClick = () => {
    setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
  };
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const showSidebar = () => {
    setSidebarVisible(true);
  };

  const hideSidebar = () => {
    setSidebarVisible(false);
  };
  useEffect(() => {
    // Scroll to the top of the page when navigating to the About page
    window.scrollTo(0, 0);
  }, []);
   
    return (
        <div>
        <div className='Arsia--website--design'>
            <div className='Arsia--header'>
                <p>Orders at Arshiya  Seghal are packed with love. </p>
            </div>
            <ArsiaHeader/>
            <div className='Arsia--banner--1'>
                <img src={newarrivalbanner}></img>
            </div>
            <div className='Arsia--collection--banner--below--content'>
                <p className='Arsia--collection--banner--below--content--p1'>Chic Unveilings - It’s New. It’s You.</p>
            </div>
            <div className='Arsia--collection--below--content'></div>
            <ArsiaSortFilter/>
          
         <ArsiaFooter/>
            </div>

           {/* Arsia--Mobile--view */}
      <div className="Arsia--website--design--mobile--view">
       <ArsiaHeaderMobile/>
           <div className='Arsia--banner--1'>
                <img src={newarrivalmobile}></img>
            </div>
            <div className='Arsia--collection--banner--below--content'>
                <p className='Arsia--collection--banner--below--content--p1'>Chic Unveilings - It’s New. It’s You.</p>
            </div>
           
            <ArsiaSortFilter/>
        
           <FooterMobile/>
      </div>
      {/* Arsia--Mobile--view--end */}
           
        </div>
        
    );

}