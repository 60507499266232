import { useQuery } from "@tanstack/react-query";
import { getAddress } from "../../services/apiAddress";

export const useGetAddress = () => {
  const { isLoading, error, data } = useQuery({
    queryKey: ["address"],
    queryFn: () => getAddress(),
  });

  const { data: address } = data || {};

  return { isLoading, error, address };
};
