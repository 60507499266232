
import FooterMobile from "./Arsia--footer--mobile";
import React, { useEffect, useState } from "react";
import ArsiaHeaderMobile from "./Arsia-header-mobile";
import "./Arsia.css"; 
import ArsiaFooter from "./ArsiaFooter";
import ArsiaHeader from "./ArsiaHeader";
import { Link,useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Arshiya_Prepaid_Order_Success() {
    const params = useParams();
    const Navigate=useNavigate()
    function gotoAllProducts(){
      Navigate('/allproducts')
    }
    return (
        <div>
            <div className="Arsia--contact--us--page--web--view">
                <ArsiaHeader /> 
                <div className="Arsia--contact--us--page--web--view--cart">
                    <div className="Arsia--cart--page--card"> 

                        <div className="Arsia--cart--page--card--add-to-cart oder_status_css">
                            <div className="Arsia--order--id--card" style={{ textAlign: 'center' }}>
                                <p className="Arsia--Address--login--card--p1">Order Placed Successfully</p>

                                <p className="Arsia--Address--login--card--p2">
                                (Your order ID is {params.order_id})
                                </p>
                                <button className="Continue_Shopping_Button_Order_Success_Page" onClick={gotoAllProducts}>Continue Shopping</button>
                                {/* <div className="Arsia--order--id--card--1">
                                    <p className="Arsia--Address--login--card--p2--6">
                                        Your order Is Confirmed. Your transaction [transaction id here] is processed successfully.
                                    </p>
                                    <p className="Arsia--Address--login--card--p2--6">
                                        You Will Get Updates via SMS or Call
                                    </p>
                                </div> */}
                            </div>
                            {/* <div className="Arsia--address--whole--card--2" >
                                <p className="Arsia--billing--and--shipping--address1--p">
                                    Billing & Shipping Address
                                </p>

                                <div className="Arsia--address--whole--card">
                                    <div className="Arsia--address--first--card">
                                        <div className="Arsia--address--first--card--1">
                                            <div className="Arsia--address--first--card--2">
                                                <p className="Arsia--address--first--card--1--p1 order_successfull_cus_name">
                                                    Amit Chauhan
                                                </p>
                                                <p className="Arsia--address--first--card--1--p2">
                                                    D-1/15 New Kondli East Delhi - 110096
                                                </p>
                                                <p className="Arsia--address--first--card--1--p3">
                                                    Contact - <span>8377818204</span>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="Arsia--cart--border--line"></div>

                                <div className="Arsia--payment--gateway" >
                                    <p className="Arsia--billing--and--shipping--address1--p">
                                        Payment Method
                                    </p>
                                    <p className="">
                                        UPI
                                    </p>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="Arsia--cart--page--card--order--summury">
                            <div className="Arsia--cart--page--card--order--summury--card">
                                <p className="Arsia--cart--page--card--order--summury--card--p1">
                                    Price Details
                                </p>
                                <div className="Arsia--cart-order-summury--content">
                                    <div className="Arsia--cart-order-summury--content1">
                                        <p>Subtotal</p>
                                        <p>
                                            &#8377; 5000
                                        </p>
                                    </div>
                                    <div className="Arsia--cart-order-summury--content1">
                    <p>Discount</p>
                    <p>Rs 00</p>
                  </div>
                                    <div className="Arsia--cart-order-summury--content1">
                                        <p>Coupon Discount</p>
                                        <p>&#8377; 0.00</p>
                                    </div>
                                    <div className="Arsia--cart-order-summury--content1">
                                        <p>Additional Discount</p>
                                        <p>&#8377; 0.00</p>
                                    </div>
                                    <div className="Arsia--cart-order-summury--content1">
                                        <p>Tax</p>
                                        <p>&#8377; 0.00</p>
                                    </div>
                                    <div className="Arsia--cart-order-summury--content1">
                                        <p>Shipping Charges</p>
                                        <p>&#8377; 0.00</p>
                                    </div>
                                </div>
                                <div className="Arsia--cart--border--line"></div>
                                <div className="Arsia--cart-order-summury--content1-1">
                                    <p className="Arsia--cart-order-summury--content1--p">
                                        TOTAL
                                    </p>
                                    <p className="Arsia--cart-order-summury--content1--p2">
                                        <p>
                                            &#8377; 5000
                                        </p>
                                    </p>
                                </div>
                                <Link to={"/allproducts"}>
                                    <button className="Arsia--cart-order-summury--content1-1--button">
                                        Continue Shopping
                                    </button>
                                </Link>
                                <button
                  disabled={isOder}
                  onClick={orderPlaceHandler}
                  className="Arsia--cart-order-summury--content1-1--button"
                >
                  {isOder ? "Loading..." : "Proceed to Checkout"}
                </button>
                            </div>
                        </div> */}
                    </div>
                </div>
                <ArsiaFooter />
            </div>

            <div className="Arsia--contact--us--page--mobile--view">
                <ArsiaHeaderMobile />
                <div className="Arsia--contact--us--page--web--view--cart">
                    <div className="Arsia--cart--page--card">
                        <div className="Arsia--cart--page--card--add-to-cart oder_status_css">
                            <div className="Arsia--order--id--card" style={{ textAlign: 'center' }}>
                                <p className="Arsia--Address--login--card--p1">Order Placed Successfully</p>

                                <p className="Arsia--Address--login--card--p2">
                                (Your order ID is {params.order_id})
                                </p>
                                <button className="Continue_Shopping_Button_Order_Success_Page" onClick={gotoAllProducts}>Continue Shopping</button>
                                {/* <div className="Arsia--order--id--card--1">
                                    <p className="Arsia--Address--login--card--p2--6">
                                        Your order Is Confirmed. Your transaction [transaction id here] is processed successfully.
                                    </p>
                                    <p className="Arsia--Address--login--card--p2--6">
                                        You Will Get Updates via SMS or Call
                                    </p>
                                </div> */}
                            </div>
                            {/* <div className="Arsia--address--whole--card--2" >
                                <p className="Arsia--billing--and--shipping--address1--p">
                                    Billing & Shipping Address
                                </p>

                                <div className="Arsia--address--whole--card">
                                    <div className="Arsia--address--first--card">
                                        <div className="Arsia--address--first--card--1">
                                            <div className="Arsia--address--first--card--2">
                                                <p className="Arsia--address--first--card--1--p1 order_successfull_cus_name">
                                                    Amit Chauhan
                                                </p>
                                                <p className="Arsia--address--first--card--1--p2">
                                                    D-1/15 Nedw Kondli East Delhi 110096
                                                </p>
                                                <p className="Arsia--address--first--card--1--p3">
                                                    Contact - <span>8377818204</span>
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="Arsia--cart--border--line"></div>

                                <div className="Arsia--payment--gateway" >
                                    <p className="Arsia--billing--and--shipping--address1--p">
                                        Payment Method
                                    </p>


                                    <p className="Arsia--address--first--card--1--p1">
                                        UPI
                                    </p>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="Arsia--cart--page--card--order--summury">
                            <div className="Arsia--cart--page--card--order--summury--card">
                                <p className="Arsia--cart--page--card--order--summury--card--p1">
                                    Price Details
                                </p>
                                <div className="Arsia--cart-order-summury--content">
                                    <div className="Arsia--cart-order-summury--content1">
                                        <p>Subtotal</p>
                                        <p>
                                            &#8377; 5000
                                        </p>
                                    </div>
                                    <div className="Arsia--cart-order-summury--content1">
                    <p>Discount</p>
                    <p>Rs 00</p>
                  </div>
                                    <div className="Arsia--cart-order-summury--content1">
                                        <p>Coupon Discount</p>
                                        <p>&#8377; 0.00</p>
                                    </div>
                                    <div className="Arsia--cart-order-summury--content1">
                                        <p>Additional Discount</p>
                                        <p>&#8377; 0.00</p>
                                    </div>
                                    <div className="Arsia--cart-order-summury--content1">
                                        <p>Tax</p>
                                        <p>&#8377; 0.00</p>
                                    </div>
                                    <div className="Arsia--cart-order-summury--content1">
                                        <p>Shipping Charges</p>
                                        <p>&#8377; 0.00</p>
                                    </div>
                                </div>
                                <div className="Arsia--cart--border--line"></div>
                                <div className="Arsia--cart-order-summury--content1-1">
                                    <p className="Arsia--cart-order-summury--content1--p">
                                        TOTAL
                                    </p>
                                    <p className="Arsia--cart-order-summury--content1--p2">
                                        <p>
                                            &#8377; 5000
                                        </p>
                                    </p>
                                </div>

                                <Link to={"/allproducts"}>
                                    <button className="Arsia--cart-order-summury--content1-1--button">
                                        Continue Shopping
                                    </button>
                                </Link>
                                <button
                  disabled={isOder}
                  onClick={orderPlaceHandler}
                  className="Arsia--cart-order-summury--content1-1--button"
                >
                  {isOder ? "Loading..." : "Proceed to Checkout"}
                </button>
                            </div>
                        </div> */}
                    </div>
                </div>
                <FooterMobile />
            </div>
        </div>
    );
}
