import FooterMobile from './Arsia--footer--mobile';
import React, {  useEffect, useState } from 'react';
import ArsiaHeaderMobile from './Arsia-header-mobile';
import './Arsia.css';
import ArsiaFooter from './ArsiaFooter';
import ArsiaHeader from './ArsiaHeader';
import axios from 'axios';


export default function ArsiaContactPage()
{

    const [contactData,setContactData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get('https://admin.arshiyasehgal.com/API/Web/getContact');
            setContactData(response.data.data[0]);
            console.log("hkjhwhuyrtuthqwt",response.data.data);
          } catch (error) {
            console.log(error);
          }
        };
    
        fetchData();
      }, []); 
        useEffect(() => {
        // Scroll to the top of the page when navigating to the About page
        window.scrollTo(0, 0);
      }, []);
    return(
        <div>
            <div className='Arsia--contact--us--page--web--view'>
                <ArsiaHeader/>
                <div className='Arsia--contact--us--page--web--view-1'>
                <p className='Arsia--contact--us--page--web--view--h1'>WE’D LOVE TO HEAR FROM YOU</p>
                <div className='Arsia--contact--us--page--web--view--p'>
                 <p className='Arsia--contact--us--page--web--view--p1'>Write us an email on</p>
                 <p className='Arsia--contact--us--page--web--view--p2'>{contactData.email}</p>
                </div>
                <div className='Arsia--contact--us--page--web--view--p'>
                 <p className='Arsia--contact--us--page--web--view--p1'>Call Us</p>
                 <p className='Arsia--contact--us--page--web--view--p2'>+91 {contactData.mobile}</p>
                </div>
                <div className='Arsia--contact--us--page--web--view--p'>
                 <p className='Arsia--contact--us--page--web--view--p1'>You can find us at</p>
                 <p className='Arsia--contact--us--page--web--view--p2'>{contactData.address}</p>
                </div>
                </div>
                <ArsiaFooter/>
            </div>

            <div className='Arsia--contact--us--page--mobile--view'>
            <ArsiaHeaderMobile/>
                <div className='Arsia--contact--us--page--mobile--view-1'>
                <p className='Arsia--contact--us--page--web--view--h1'>WE’D LOVE TO HEAR FROM YOU</p>
                <div className='Arsia--contact--us--page--web--view--p'>
                 <p className='Arsia--contact--us--page--web--view--p1'>Write us an email on</p>
                 <p className='Arsia--contact--us--page--web--view--p2'>{contactData.email}</p>
                </div>
                <div className='Arsia--contact--us--page--web--view--p'>
                 <p className='Arsia--contact--us--page--web--view--p1'>Call Us</p>
                 <p className='Arsia--contact--us--page--web--view--p2'>+91 {contactData.mobile}</p>
                </div>
                <div className='Arsia--contact--us--page--web--view--p'>
                 <p className='Arsia--contact--us--page--web--view--p1'>You can find us at</p>
                 <p className='Arsia--contact--us--page--web--view--p2'>{contactData.address}</p>
                </div>
                </div>
                <FooterMobile/>
            </div>
        </div>
    );
}