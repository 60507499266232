import "./Arsia.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import heart2 from "./image/heart2.svg";
import face from "./image/face.svg";
import pintaa from "./image/pintaa.svg";
import twitta from "./image/twitta.svg";
import instaa from "./image/instaa.svg";
import ArsiaVerticalCarousal from "./Arsia-vetical-caraousal/Arsia--vertical--carousal";
import ArsiaHeader from "./ArsiaHeader";
import Frame from "./image/Frame.png";
import videoimg from "./image/videoimg.png";
import play from "./image/play.png";
import { Carousel } from "antd";
import laila1 from "./image/laila1.png";
import laila2 from "./image/laila2.png";
import laila3 from "./image/laila3.png";
import ArsiaFooter from "./ArsiaFooter";
import ArsiaHeaderMobile from "./Arsia-header-mobile";
import FooterMobile from "./Arsia--footer--mobile";
import lailathin from "./image/lailathin.png";
import lailathin2 from "./image/lailathin2.png";
import lailathin3 from "./image/lailathin3.png";
import lailawidth from "./image/lailawidth.png";
import lailamobile from "./image/lailamobile.png";
import lailamobile1 from "./image/lailamobile1.png";
import lailamobile2 from "./image/lailamobile2.png";
import lailamobile3 from "./image/lailamobile3.png";
import lailamobile4 from "./image/lailamobile4.png";
import ArsiaSizePopup from "./Arsia-size-popup";
import lailamostbought from "./image/lailamostbought.png";
import moonlightmost2 from "./image/moonlightmost2.png";
import sitaremost1 from "./image/sitaremost1.png";
import redmost1 from "./image/redmost1.png";

export default function ArsiaLaila() {
  const [isContentVisible, setIsContentVisible] = useState(false);

  const handleDescriptionClick = () => {
    setIsContentVisible(!isContentVisible);
  };
  const [isContentVisible1, setIsContentVisible1] = useState(false);

  const handleDescriptionClick1 = () => {
    setIsContentVisible1(!isContentVisible1);
  };
  const [isContentVisible2, setIsContentVisible2] = useState(false);

  const handleDescriptionClick2 = () => {
    setIsContentVisible2(!isContentVisible2);
  };
  const [isContentVisible3, setIsContentVisible3] = useState(false);

  const handleDescriptionClick3 = () => {
    setIsContentVisible3(!isContentVisible3);
  };

  const [isSearchCardOpen, setIsSearchCardOpen] = useState(false);
  const handleSearchIconClick = () => {
    setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
  };
  const handleArrowLeftClick = () => {
    setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
  };
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const showSidebar = () => {
    setSidebarVisible(true);
  };

  const hideSidebar = () => {
    setSidebarVisible(false);
  };
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  useEffect(() => {
    // Scroll to the top of the page when navigating to the About page
    window.scrollTo(0, 0);
  }, []);

  const [isAddToCartClicked, setIsAddToCartClicked] = useState(false);
  const handleAddToCartClick = () => {
    if (selectedSize !== '') {
      setIsAddToCartClicked(!isAddToCartClicked); // Toggle the state
    } else {
      alert('Please select a size before adding to cart.');
    }
  };

const [selectedSize, setSelectedSize] = useState('');


const handleSizeSelect = (size) => {
    setSelectedSize(size);
  };

  return (
    <div>
      <div className="Arsia--website--design">
        <ArsiaHeader />
        <div className="Arsia--carausal--and--cart">
          <div className="Arsia--caraousal">
            <div className="Arsia--carousal--1">
              <div className="Arsia--vertical--carousal--left">
                <div className="Arsia--below--content--card--carousal--left">
                  <div className="Arsia--vertical--carousal--left--card">
                    <img
                      src={lailathin}
                      onClick={() => handleImageClick(laila3)}
                    />
                  </div>
                  <div className="Arsia--vertical--carousal--left--card1">
                    <img
                      src={lailawidth}
                      onClick={() => handleImageClick(laila1)}
                    />
                  </div>
                  <div className="Arsia--vertical--carousal--left--card">
                    <img
                      src={lailathin2}
                      onClick={() => handleImageClick(laila2)}
                    />
                  </div>
                  <div className="Arsia--vertical--carousal--left--card">
                    <img
                      src={lailathin3}
                      onClick={() => handleImageClick(laila1)}
                    />
                  </div>
                  <div className="Arsia--vertical--carousal--left--card">
                    <img
                      src={lailathin}
                      onClick={() => handleImageClick(laila3)}
                    />
                  </div>
                </div>
              </div>
              <div className="Arsia--vertical--carousal--right">
                {selectedImage ? (
                  <img src={selectedImage} alt="Selected" />
                ) : (
                  <img src={laila1} alt="Default" />
                )}
              </div>
            </div>

            <div className="Arsia--caraousal--video">
              <img src={laila1}></img>
              <div className="Arsia--caraousal--video--1">
                <img src={play}></img>
              </div>
            </div>
          </div>
          <div className="Arsia--cart">
            <div className="Arsia--cart--1--card">
              <div className="Arsia--cart--1">
                <img src={heart2}></img>
                <p>Add to wishlist</p>
              </div>
              <div className="Arsia--cart--1">
                <img src={instaa}></img>
                <img src={face}></img>
                <img src={twitta}></img>
                <img src={pintaa}></img>
              </div>
            </div>
            <div className="Arsia--cart--caraousal--card">
              <p>Laila</p>
            </div>
            <div className="Arsia--cart--1--card">
              <div className="Arsia--cart--1--2">
                <p>Rs 15,200</p>
              </div>
            </div>
            <div className="Arsia--cart--1--card">
              <div className="Arsia--cart--1--2--text">
                <p>
                  {" "}
                  Enjoy your everlasting moment in Laila. A timeless piece for
                  our pink lovers, and our shared love for sarees.
                </p>
              </div>
            </div>
            <div className="Arsia--cart--size--usa">
               <div className='Arsia--cart--size--usa--card'>
                                <p className='Arsia--cart--size--usa--card--p'>Size</p>
                              
                            </div>
                            <div className='Arsia--cart--size--usa--button'>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'Xs' ? 'selected' : ''}`} onClick={() => handleSizeSelect('Xs')}>Xs</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'S' ? 'selected' : ''}`} onClick={() => handleSizeSelect('S')}>S</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'M' ? 'selected' : ''}`} onClick={() => handleSizeSelect('M')}>M</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'L' ? 'selected' : ''}`} onClick={() => handleSizeSelect('L')}>L</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'XL' ? 'selected' : ''}`} onClick={() => handleSizeSelect('XL')}>XL</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'XXL' ? 'selected' : ''}`} onClick={() => handleSizeSelect('XXL')}>XXL</p>
                            </div>
                            <ArsiaSizePopup />
              {/* <div className='Arsia--cart--size--usa--card'>
                                <p className='Arsia--cart--size--usa--card--p'>Qty:</p>
                                <div className='Arsia--cart--size--usa--card--1--add'>
                                    <p >-</p>
                                    <p>0</p>
                                    <p >+</p>
                                </div>
                            </div> */}

              <div className="Arsia--add--to--cart--and--buy--now">
              <div>
      {isAddToCartClicked ? (
        <Link
        to={"/Cart"}
        className="arsia--product--link"
      >
        <div className="Arsia--cart--size--usa--card--price">
            
          <p>Go to cart</p>
        </div>
        </Link>
      ) : (
        <div className="Arsia--cart--size--usa--card--price" onClick={handleAddToCartClick}>
          <p>Add to cart</p>
        </div>
      )}
              </div>
                {/* <Link
                  to={"/paymentGatewayPage"}
                  className="arsia--product--link"
                >
                  <div className="Arsia--cart--size--usa--card--price">
                    <p>Buy Now</p>
                  </div>
                </Link> */}
              </div>
              {/* <Link to={"https://api.whatsapp.com/send?phone=919810889012&text=Hi!%20I%20just%20saw%20this%20gorgeous%20dress%20at%20your%20website.%20How%20can%20I%20add%20it%20to%20my%20wardrobe?"} className='arsia--product--link'>
                            <div className='Arsia--cart--size--usa--card--price'>
                                <p>Order on Demand</p>
                            </div>
                            </Link> */}
              {/* <div className="Arsia--cart--size--usa--card--free--ship">
                <p>Free 3-5 day shipping </p>
                <p> • Tool-free assembly</p>
                <p> • 30-day trial</p>
              </div> */}
            </div>
           

            <div className="Arsia--cart--border--1"></div>
            <div className="Arsia--cart--dropdown--discription">
              <div>
                <div
                  className="Arsia--cart--dropdown--discription--1"
                  onClick={handleDescriptionClick}
                >
                  <p>Description</p>
                  <img src={Frame} alt="Description Icon" />
                </div>
                {isContentVisible && (
                  <div className="Arsia--cart--1--2--text">
                    <p>
                      <br></br> Comes with a diamond shaped silhouette.{" "}
                      <br></br>
                      <br></br> The silhouette has been embroidered with pink
                      fringing, and a catchy neckline. <br></br>
                      <br></br> Comes with a nude lining, for comfort and
                      support. <br></br>
                      <br></br> The saree is non stitched and can be worn
                      accordingly. <br></br>
                      <br></br> This silhouette is padded.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="Arsia--cart--border--1"></div>

            <div className="Arsia--cart--dropdown--discription">
              <div>
                <div
                  className="Arsia--cart--dropdown--discription--1"
                  onClick={handleDescriptionClick1}
                >
                  <p>Fabric</p>
                  <img src={Frame} alt="Description Icon" />
                </div>
                {isContentVisible1 && (
                  <div className="Arsia--cart--1--2--text">
                    <p>Organza</p>
                  </div>
                )}
              </div>
            </div>
            <div className="Arsia--cart--border--1"></div>
            <div className="Arsia--cart--dropdown--discription">
              <div>
                <div
                  className="Arsia--cart--dropdown--discription--1"
                  onClick={handleDescriptionClick2}
                >
                  <p>Product Care</p>
                  <img src={Frame} alt="Description Icon" />
                </div>
                {isContentVisible2 && (
                  <div className="Arsia--cart--1--2--text">
                    <p>
                      We suggest dry clean, as the product is very delicate. The
                      model is wearing the size ‘S’.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="Arsia--cart--border--1"></div>
            <div className="Arsia--cart--dropdown--discription">
              <div>
                <div
                  className="Arsia--cart--dropdown--discription--1"
                  onClick={handleDescriptionClick3}
                >
                  <p>Disclaimer</p>
                  <img src={Frame} alt="Description Icon" />
                </div>
                {isContentVisible3 && (
                  <div className="Arsia--cart--1--2--text">
                    <p>
                      The product colour can slightly vary due to lighting
                      effects during photoshoots{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="Arsia--below--content--5">
          <p className="Arsia--below--content--1--3">Most Bought Pieces</p>
          <p className="Arsia--below--content--2--3">the love of every woman</p>
        </div>
        <div className="Arsia--collection--below--content--part">
          <div className="Arsia--below--content--card--carousal">
            <Link to={"/Sitare-Hazel"} className="arsia--product--link">
              <div className="Arsia--below--content--card--carousal--1--collection">
                <img src={sitaremost1}></img>
                <div className="Arsia--below--content--card--carousal--1--price">
                  <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                    Sitare{" "}
                  </p>
                  {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                </div>
              </div>
            </Link>
            <Link to={"/laila-Hazel"} className="arsia--product--link">
              <div className="Arsia--below--content--card--carousal--1--collection">
                <img src={lailamostbought}></img>
                <div className="Arsia--below--content--card--carousal--1--price">
                  <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                    Laila
                  </p>
                  {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                </div>
              </div>
            </Link>
            <Link to={"/Lady-In_red-Hazel"} className="arsia--product--link">
              <div className="Arsia--below--content--card--carousal--1--collection">
                <img src={redmost1}></img>
                <div className="Arsia--below--content--card--carousal--1--price">
                  <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                    Lady in Red{" "}
                  </p>
                  {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                </div>
              </div>
            </Link>
            <Link to={"/Moonlight-Hazel"} className="arsia--product--link">
              <div className="Arsia--below--content--card--carousal--1--collection">
                <img src={moonlightmost2}></img>
                <div className="Arsia--below--content--card--carousal--1--price">
                  <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                    Moonlight{" "}
                  </p>
                  {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                </div>
              </div>
            </Link>
          </div>
        </div>
        <ArsiaFooter />
      </div>

      {/* Arsia--Mobile--view */}
      <div className="Arsia--website--design--mobile--view">
        <ArsiaHeaderMobile />
        <div className="Arsia--product--mobile--view">
          <Carousel autoplay dots={false}>
            <div className="Arsia--product--mobile--view--img">
              <img src={lailamobile}></img>
            </div>
            <div className="Arsia--product--mobile--view--img">
              <img src={lailamobile1}></img>
            </div>
            <div className="Arsia--product--mobile--view--img">
              <img src={lailamobile2}></img>
            </div>
            <div className="Arsia--product--mobile--view--img">
              <img src={lailamobile3}></img>
            </div>
            <div className="Arsia--product--mobile--view--img">
              <img src={lailamobile4}></img>
            </div>
          </Carousel>
        </div>
        <div className="Arsia--carausal--and--cart">
          <div className="Arsia--caraousal">
            <ArsiaVerticalCarousal />
            <div className="Arsia--caraousal--video">
              <img src={videoimg}></img>
              <div className="Arsia--caraousal--video--1">
                <img src={play}></img>
              </div>
            </div>
          </div>
          <div className="Arsia--cart">
            <div className="Arsia--cart--1--card">
              <div className="Arsia--cart--caraousal--card">
                <p>Laila</p>
              </div>
              <div className="Arsia--cart--1">
                <img src={instaa}></img>
                <img src={face}></img>
                <img src={twitta}></img>
                <img src={pintaa}></img>
              </div>
            </div>
            <div className="Arsia--cart--1--card">
              <div className="Arsia--cart--1--2">
                <p>Rs 14,000</p>
              </div>
            </div>
            <div className="Arsia--cart--1--card">
              <div className="Arsia--cart--1--2--text">
                <p>
                  {" "}
                  Enjoy your everlasting moment in Laila. A timeless piece for
                  our pink lovers, and our shared love for sarees.{" "}
                </p>
              </div>
            </div>
            <div className="Arsia--cart--size--usa">
              <div className='Arsia--cart--size--usa--card'>
                                <p className='Arsia--cart--size--usa--card--p'>Size</p>
              </div>
                           
              <div className='Arsia--cart--size--usa--button'>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'Xs' ? 'selected' : ''}`} onClick={() => handleSizeSelect('Xs')}>Xs</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'S' ? 'selected' : ''}`} onClick={() => handleSizeSelect('S')}>S</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'M' ? 'selected' : ''}`} onClick={() => handleSizeSelect('M')}>M</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'L' ? 'selected' : ''}`} onClick={() => handleSizeSelect('L')}>L</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'XL' ? 'selected' : ''}`} onClick={() => handleSizeSelect('XL')}>XL</p>
        <p className={`Arsia--below--content--card--carousal--1--price--p2--2 ${selectedSize === 'XXL' ? 'selected' : ''}`} onClick={() => handleSizeSelect('XXL')}>XXL</p>
                            </div>
                            <ArsiaSizePopup />
              {/* <div className='Arsia--cart--1--card'>
                                <div className='Arsia--cart--size--usa--card'>
                                    <p className='Arsia--cart--size--usa--card--p'>Qty:</p>
                                    <div className='Arsia--cart--size--usa--card--1--add'>
                                        <p >-</p>
                                        <p>0</p>
                                        <p >+</p>
                                    </div>
                                </div>
                                <div className='Arsia--cart--1'>
                                    <img src={heart2}></img>
                                    <p>Add to wishlist</p>
                                </div>

                            </div> */}

              {/* 
                            <Link to={"https://api.whatsapp.com/send?phone=919810889012&text=Hi!%20I%20just%20saw%20this%20gorgeous%20dress%20at%20your%20website.%20How%20can%20I%20add%20it%20to%20my%20wardrobe?"} className='arsia--product--link'>
                            <div className='Arsia--cart--size--usa--card--price'>
                                <p>Order on Demand</p>
                            </div>
                            </Link> */}
               <div className="Arsia--add--to--cart--and--buy--now">
              <div>
      {isAddToCartClicked ? (
        <Link
        to={"/Cart"}
        className="arsia--product--link"
      >
        <div className="Arsia--cart--size--usa--card--price">
            
          <p>Go to cart</p>
        </div>
        </Link>
      ) : (
        <div className="Arsia--cart--size--usa--card--price" onClick={handleAddToCartClick}>
          <p>Add to cart</p>
        </div>
      )}
    </div>
                {/* <Link
                  to={"/paymentGatewayPage"}
                  className="arsia--product--link"
                >
                  <div className="Arsia--cart--size--usa--card--price">
                    <p>Buy Now</p>
                  </div>
                </Link> */}
              </div>
              {/* <div className="Arsia--cart--size--usa--card--free--ship">
                <p>Free 3-5 day shipping </p>
                <p> • Tool-free assembly</p>
                <p> • 30-day trial</p>
              </div> */}
            </div>
         

            <div className="Arsia--cart--border--1"></div>
            <div className="Arsia--cart--dropdown--discription">
              <div>
                <div
                  className="Arsia--cart--dropdown--discription--1"
                  onClick={handleDescriptionClick}
                >
                  <p>Description</p>
                  <img src={Frame} alt="Description Icon" />
                </div>
                {isContentVisible && (
                  <div className="Arsia--cart--1--2--text">
                    <p>
                      <p>
                        <br></br> Comes with a diamond shaped silhouette.{" "}
                        <br></br>
                        <br></br> The silhouette has been embroidered with pink
                        fringing, and a catchy neckline. <br></br>
                        <br></br> Comes with a nude lining, for comfort and
                        support. <br></br>
                        <br></br> The saree is non stitched and can be worn
                        accordingly. <br></br>
                        <br></br> This silhouette is padded.
                      </p>
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="Arsia--cart--border--1"></div>

            <div className="Arsia--cart--dropdown--discription">
              <div>
                <div
                  className="Arsia--cart--dropdown--discription--1"
                  onClick={handleDescriptionClick1}
                >
                  <p>Fabric</p>
                  <img src={Frame} alt="Description Icon" />
                </div>
                {isContentVisible1 && (
                  <div className="Arsia--cart--1--2--text">
                    <p>Organza</p>
                  </div>
                )}
              </div>
            </div>
            <div className="Arsia--cart--border--1"></div>
            <div className="Arsia--cart--dropdown--discription">
              <div>
                <div
                  className="Arsia--cart--dropdown--discription--1"
                  onClick={handleDescriptionClick2}
                >
                  <p>Product Care</p>
                  <img src={Frame} alt="Description Icon" />
                </div>
                {isContentVisible2 && (
                  <div className="Arsia--cart--1--2--text">
                    <p>
                      We suggest dry clean, as the product is very delicate. The
                      model is wearing the size ‘S’.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="Arsia--cart--border--1"></div>
            <div className="Arsia--cart--dropdown--discription">
              <div>
                <div
                  className="Arsia--cart--dropdown--discription--1"
                  onClick={handleDescriptionClick3}
                >
                  <p>Disclaimer</p>
                  <img src={Frame} alt="Description Icon" />
                </div>
                {isContentVisible3 && (
                  <div className="Arsia--cart--1--2--text">
                    <p>
                      The product colour can slightly vary due to lighting
                      effects during photoshoots{" "}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="Arsia--mobile--content--1">
            <div className="Arsia--below--content--5">
              <p className="Arsia--below--content--1">Most Bought Pieces</p>
              <p className="Arsia--below--content--2">
                The love of every woman
              </p>
            </div>
            <div className="Arsia--below--content--1--content">
              <div className="Arsia--below--content--card--carousal">
                <Link to={"/Sitare-Hazel"} className="arsia--product--link">
                  <div className="Arsia--below--content--card--carousal--1--collection">
                    <img src={sitaremost1}></img>
                    <div className="Arsia--below--content--card--carousal--1--price">
                      <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                        Sitare{" "}
                      </p>
                      {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                    </div>
                  </div>
                </Link>
                <Link to={"/laila-Hazel"} className="arsia--product--link">
                  <div className="Arsia--below--content--card--carousal--1--collection">
                    <img src={lailamostbought}></img>
                    <div className="Arsia--below--content--card--carousal--1--price">
                      <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                        Laila
                      </p>
                      {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                    </div>
                  </div>
                </Link>
                <Link
                  to={"/Lady-In_red-Hazel"}
                  className="arsia--product--link"
                >
                  <div className="Arsia--below--content--card--carousal--1--collection">
                    <img src={redmost1}></img>
                    <div className="Arsia--below--content--card--carousal--1--price">
                      <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                        Lady in Red{" "}
                      </p>
                      {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                    </div>
                  </div>
                </Link>
                <Link to={"/Moonlight-Hazel"} className="arsia--product--link">
                  <div className="Arsia--below--content--card--carousal--1--collection">
                    <img src={moonlightmost2}></img>
                    <div className="Arsia--below--content--card--carousal--1--price">
                      <p className="Arsia--below--content--card--carousal--1--price--p1--2--1">
                        Moonlight{" "}
                      </p>
                      {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p>
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,699</p> */}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <FooterMobile />
      </div>
      {/* Arsia--Mobile--view--end */}
    </div>
  );
}
