// import { useQuery } from "@tanstack/react-query";
// import { getAllProduct } from "../../services/apiProduct";

// export function useGetProducts() {
//   const { isLoading, error, data } = useQuery({
//     queryKey: ["products"],
//     queryFn: () => getAllProduct(),
//   });
//   const { data: products } = data || {};
//   return { isLoading, error, products };
// }

import { useQuery } from "@tanstack/react-query";
import { getAllProduct } from "../../services/apiProduct";
import { useState, useEffect } from "react";

export function useGetProducts(searchQuery) {
  const { isLoading, error, data } = useQuery({
    queryKey: ["products"],
    queryFn: () => getAllProduct(),
  });

  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    if (data && data.data) {
      if (searchQuery) {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filtered = data.data.filter((product) =>
          product.name.toLowerCase().includes(lowerCaseQuery)
        );
        setFilteredProducts(data.data);
      } else {
        setFilteredProducts(data.data);
      }
    }
  }, [data, searchQuery]);

  console.log("Filtered Products:", filteredProducts);

  return { isLoading, error, products: filteredProducts };
}
