import "./Arsia.css";
import React, { useEffect, useState } from "react";
import ArsiaFooter from "./ArsiaFooter";
import ArsiaHeader from "./ArsiaHeader";
import { Link, useLocation } from "react-router-dom";
import FooterMobile from "./Arsia--footer--mobile";
import ArsiaHeaderMobile from "./Arsia-header-mobile";
import axios from "axios";
import { IMAGE_PATH } from "../utils/constant";

export default function ArsiaOrderSummuryPage() {
  const { search } = useLocation();
  const order_Id = search.split("=")[1];

  const InitialData = {
    order_ID: "",
    price: 0,
    discount: 0,
    total: 0,
    cust_Name: "",
    address: "",
    contact: "",
    payment_mode: "",
  };

  const userData = localStorage.getItem("user");
  const userDataWithId = JSON.parse(userData);
  console.log(JSON.parse(userData));
  const [order_details, setOrderDetails] = useState(InitialData);

  // const FetchDetails = async () => {
  //   try {
  //     const formdata = new FormData()
  //     formdata.append('order_id', order_Id)
  //     const res = await axios.post(`https://admin.arshiyasehgal.com/API/User/getOrderDetail`, formdata)
  //     console.log(res,'dddd')

  //   } catch (e) {
  //     console.log(e)
  //   }

  // }

  //desctructre Data
  const {
    order_ID,
    price,
    discount,
    total,
    cust_Name,
    contact,
    address,
    payment_mode,
  } = order_details;

  const [orderData, setOrderData] = useState([]);

  const fetchOrderDetails = async () => {
    const formData = new FormData();
    formData.append("user_id", userDataWithId?.id);
    try {
      const response = await axios.post(
        "https://admin.arshiyasehgal.com/API/User/myOrders",

        formData
      );
      setOrderData(response.data.data);

      console.log(response);
    } catch (error) {
      console.log("Error in getOrder", error);
    }
  };

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  console.log(orderData);
  return (
    <>
      <div>
        <div className="Arsia--contact--us--page--web--view--1">
          <div className="Arsia--header6--web">
            <ArsiaHeader />
          </div>
          <div className="Arsia--header6--mobile">
            <ArsiaHeaderMobile />
          </div>
          <div className="Arsia--contact--us--page--web--view--cart">
            <div className="Arsia--cart--page--card">
              <div className="Arsia--cart--page--card--add-to-cart order_summary_items_div">
                <div className="Arsia--cart--item">
                  <p className="Arsia--cart--item--p1 order_summary_items_heading_main">
                    My Orders
                  </p>
                  <p className="Arsia--cart--item--p2 order_summary_items_heading">
                    {" "}
                    ITEMS
                  </p>
                </div>
                <div className="Arsia--cart--page--card--box ">
                  {orderData &&
                    orderData.map((order) => (
                      <div className="Arsia--cart--page--card--box--1 order_summary_items">
                        <div className="Arsia--cart--page--card--box--1--1">
                          <img
                            className="Arsia--cart-page-product-img"
                            src={`${IMAGE_PATH}${order.image}`}
                            alt="img"
                          ></img>
                          <div className="Arsia--cart--page--card--box--1--1--content">
                            <p className="Arsia--dress--name--cart order_summary_p_name">
                              Product Name : <span>{order.name}</span>
                            </p>
                            <p className="Arsia--dress--name--cart order_summary_p_size">
                              Size: <span>{order.size}</span>
                            </p>
                            <p className="Arsia--dress--name--cart order_summary_p_ord_id">
                              Order Id : <span>{order.order_id}</span>
                            </p>
                            <p className="Arsia--dress--name--cart order_summary_p_ord_date">
                              Ordered on : <span>{order.order_at}</span>
                            </p>
                            <p className="Arsia--dress--name--cart order_summary_p_ord_qty">
                              QTY :<span> 1</span>
                            </p>
                            <p className="Arsia--dress--name--cart order_summary_p_ord_value">
                              Order Value :
                              <span> &#8377;{order.order_value}</span>
                            </p>
                          </div>
                        </div>
                        <div className="Arsia--cart--page--card--box--1--2 div_my_orders_need_help_button">
                          <a
                            className="Arsia--cart--page--card--box--1--2--price order_summary_p_ord_amount"
                            href={`mailto:orders@arshiyasehgal.com?subject=Need help with an order&body=Hi, I ordered some outfit(s) from your store and I need help with that order. I am sharing my order id below. [PLEASE ADD YOUR ORDER ID BELOW]`}
                            target="blank"
                          >
                            <button>Need Help</button>
                          </a>{" "}
                          <span></span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className="Arsia--header6--mobile">
            <FooterMobile />
          </div>
          <div className="Arsia--header6--web">
            <ArsiaFooter />
          </div>
        </div>
      </div>
      {/* <div>
      <div className="Arsia--contact--us--page--web--view--1">
        <div className="Arsia--header6--web">
          <ArsiaHeader />
        </div>
        <div className="Arsia--header6--mobile">
          <ArsiaHeaderMobile />
        </div>
        <div className="Arsia--contact--us--page--web--view--cart">
          <div className="Arsia--cart--page--card">
            <div className="Arsia--cart--page--card--add-to-cart">
              <div className="Arsia--order--id--card" style={{textAlign:'center'}}>
                <p className="Arsia--Address--login--card--p1">Order Placed Successfully</p>

                <p className="Arsia--Address--login--card--p2">
                  (Your order ID is {order_Id})
                </p>
                <div className="Arsia--order--id--card--1">
                  <p className="Arsia--Address--login--card--p2--6">
                    Your order Is In Awaiting Confirmation
                  </p>
                  <p className="Arsia--Address--login--card--p2--6">
                    You Will Get Updates via SMS or WhatsApp
                  </p>
                </div>
              </div>
              <div className="Arsia--address--whole--card--2" style={{display:'none'}}>
                <p className="Arsia--billing--and--shipping--address1--p">
                  Billing & Shipping Address
                </p>

                <div className="Arsia--address--whole--card">
                  <div className="Arsia--address--first--card">
                    <div className="Arsia--address--first--card--1">
                      <div className="Arsia--address--first--card--2">
                        <p className="Arsia--address--first--card--1--p1">
                          {cust_Name}
                        </p>
                        <p className="Arsia--address--first--card--1--p2">
                          {address}
                        </p>
                        <p className="Arsia--address--first--card--1--p3">
                          Contact - <span>{contact}</span>
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
                <div className="Arsia--cart--border--line"></div>

                <div className="Arsia--payment--gateway" style={{display:'none'}}>
                  <p className="Arsia--billing--and--shipping--address1--p">
                    Payment Methods
                  </p>

                  <div className="Arsia--address--first--card">
                    <div className="Arsia--address--first--card--1">
                      <div className="Arsia--address--first--card--2">
                        <p className="Arsia--address--first--card--1--p1">
                         {payment_mode}
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div className="Arsia--cart--page--card--order--summury" style={{display:'none'}}>
              <div className="Arsia--cart--page--card--order--summury--card">
                <p className="Arsia--cart--page--card--order--summury--card--p1">
                  Order Summury
                </p>
                <div className="Arsia--cart-order-summury--content">
                  <div className="Arsia--cart-order-summury--content1">
                    <p>Price</p>
                    <p>Rs {price}</p>
                  </div>
                  <div className="Arsia--cart-order-summury--content1">
                    <p>Discount</p>
                    <p>Rs {discount}</p>
                  </div>
                  <div className="Arsia--cart-order-summury--content1">
                    <p>Shipping</p>
                    <p>Free</p>
                  </div>
                  <div className="Arsia--cart-order-summury--content1">
                    <p>Coupon Applied</p>
                    <p>Rs 0.00</p>
                  </div>
                </div>
                <div className="Arsia--cart--border--line"></div>
                <div className="Arsia--cart-order-summury--content1-1">
                  <p className="Arsia--cart-order-summury--content1--p">
                    TOTAL
                  </p>
                  <p className="Arsia--cart-order-summury--content1--p2">
                    Rs {total}
                  </p>
                </div>

                <Link to={"/"}>
                  <button className="Arsia--cart-order-summury--content1-1--button">
                    Continue Shopping
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Arsia--header6--mobile">
        <FooterMobile />
      </div>
      <div className="Arsia--header6--web">
        <ArsiaFooter />
      </div>
    </div> */}
    </>
  );
}
