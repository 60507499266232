import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { orderPlace as apiOrderPlace } from "../../services/apiOrder";

export const usePlaceOrder = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: orderPlace, isPending: isOder } = useMutation({
    mutationFn: (order) => apiOrderPlace(order),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["order"],
      });
      localStorage.setItem("orderId", res.data.order_id);
      // navigate("/order-proceed", { replace: true });
    },
    onError: (err) => {
      console.log(err);
      // toast.error(err.message);
    },
  });

  return { orderPlace, isOder };
};
