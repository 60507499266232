import './Arsia.css';
import { Link } from "react-router-dom";
import insta6 from './image/insta6.svg';
import face7 from './image/face7.svg';
import linkedin9 from './image/linkedin9.png';
import footerlogo from './image/footerlogo.png';


export default function FooterMobile() {
  return (
    <>
      <div className='Arsia--mobile--view--footer'>
        <img src={footerlogo}></img>
        <div className='Arsia--mobile--view--footer--insta'>
          <Link to={"https://www.facebook.com/profile.php?id=61555497766692"}>
            <img src={face7}></img></Link>
          <Link to={"https://www.instagram.com/arshiyasehgalofficial/"}>
            <img src={insta6}></img></Link>
          <Link to={"https://www.linkedin.com/company/arshiya-sehgal/"}>
            <img src={linkedin9}></img></Link>
        </div>
        <p className='Arsia--mobile--view--footer--p1-8'>+91 9810889012</p>
        <p className='Arsia--mobile--view--footer--p1'>54, block C, Sector 40, Noida, Uttar Pradesh 201303</p>
        <p className='Arsia--mobile--view--footer--p1-8'>arshiyasehgal40@gmail.com</p>
        {/* <p className='Arsia--mobile--view--footer--p1'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer</p>
          <a href='#' className='anchor--arshiya'>  <p className='Arsia--mobile--view--footer--p1--2-1'>Track Order</p></a>
          <a href='#' className='anchor--arshiya'><p className='Arsia--mobile--view--footer--p1--2'>My Account</p></a>
          <a href='#' className='anchor--arshiya'><p className='Arsia--mobile--view--footer--p1--2'>Customer Care</p></a> */}
        <Link to={"/About"} className='anchor--arshiya'><p className='Arsia--mobile--view--footer--p1--2'>About Us </p></Link>
        <Link to={"/privacy-policy"} className='anchor--arshiya'> <p className='Arsia--mobile--view--footer--p1--2'>Privacy Policy</p></Link>
        <Link to={"/return-and-exchange"} className='anchor--arshiya'> <p className='Arsia--mobile--view--footer--p1--2'>Returns & Exchange</p></Link>
        <Link to={"/Contact"} className='anchor--arshiya'> <p className='Arsia--mobile--view--footer--p1--2'>Contact Us</p></Link>
        <Link to={"/T&C"} className='anchor--arshiya'> <p className='Arsia--mobile--view--footer--p1--2'>Terms & Conditions</p></Link>
        <Link to={"/ShippingAddress"} className='anchor--arshiya'> <p className='Arsia--mobile--view--footer--p1--2'>Shipping Policy</p></Link>
        {/* <Link to={"/Cart"} className='anchor--arshiya'> <p className='Arsia--mobile--view--footer--p1--2'>cart</p></Link> */}
        {/* <a href='#' className='anchor--arshiya'> <p className='Arsia--mobile--view--footer--p1--2'>Get Offers & Updates</p></a>
          <div className='Arsia--mobile--view--footer--insta'>
            <img src={vector7}></img>
            <img src={vector9}></img>
            <img src={visa}></img>
            <img src={upi8}></img>
          </div> */}
        <p className='Arsia--mobile--view--footer--p1'>© 2023 Arshiya Sehgal, All Rights Reserved.</p>
        {/* <p className='Arsia--mobile--view--footer--p1'>Made with ❤️ by  We Reach</p> */}
      </div>
    </>
  );
}