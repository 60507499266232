import "./Arsia.css";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ArsiaFooter from "./ArsiaFooter";
import ArsiaHeader from "./ArsiaHeader";
import { Link, useNavigate } from "react-router-dom";
import ArsiaHeaderMobile from "./Arsia-header-mobile";
import FooterMobile from "./Arsia--footer--mobile";
import { useForm } from "react-hook-form";
import { useAddAddress } from "../features/address/useAddAddress";
import { useGetAddress } from "../features/address/useGetAddress";
import {
  objectToFormData,
  phoneNumberFormat,
  priceFormat,
} from "../utils/helper";
import Loader from "../ui/Loader";
import { userBillingAddress } from "../features/user/userSlice";
import { useDeleteAddress } from "../features/address/userDeleteAddress";
import { useOrderCheckout } from "../features/order/useOrderCheckout";
import { useLogin } from "../features/user/useLogin";
import { useOtpVerify } from "../features/user/useOtpVerify";
import { useAddCart } from "../features/cart/useAddCart";
import { usePlaceOrder } from "../features/order/usePlaceOrder";
import { orderCheckout } from "../services/apiOrder";
import { useGetCart } from "../features/cart/useGetCart";
import axios from "axios";
import toast from "react-hot-toast";

export default function ArsiaAddressLogin() {
  console.log("Arun mkjahgjahg");
  const { address: activeAddress, isAuthenticated } = useSelector(
    (state) => state.user
  );
  const [isSubmitted, setSubmitted] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [isChangeClicked, setChangeClicked] = useState(false);
  const { addAddress, isAdding } = useAddAddress();
  const { address, isLoading, error } = useGetAddress();
  const dispatch = useDispatch();
  const { orderPlace, isOder } = usePlaceOrder();

  const { deleteAddress, isdeleting } = useDeleteAddress();
  const { isCheckout } = useOrderCheckout();
  const { id: address_id } = useSelector((state) => state.user.address);
  const { total } = useSelector((state) => state.user.cart);
  const { login, isLogin } = useLogin();
  const { verifyOtp, isVerify } = useOtpVerify();
  const { cart: localCart } = useSelector((state) => state.cart);
  const [updateCart, setUpdateCart] = useState(false);
  // const { cart } = useSelector((state) => state.user.cart);
  const {
    isLoading: cartLoad,
    error: cartError,
    cart,
    total: cartTotal,
  } = useGetCart();
  const { addCart, isAdding: isCartAdd } = useAddCart();

  const globalCart = isAuthenticated ? cart : localCart;

  const product_id = cart?.map((item) => item.product_id)?.toString();
  const qty = cart?.map((item) => item.qty)?.toString();

  const product_idGlobal =
    // globalCart ||
    localCart?.map((item) => item.product_id)?.toString();

  const qtyGlobal =
    // globalCart ||
    localCart?.map((item) => item.qty)?.toString();

  // console.log(product_idGlobal, qtyGlobal, "testing animesh");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const navigate = useNavigate();

  const [couponCode, setCouponCode] = useState("");
  const [discountedTotal, setDiscountedTotal] = useState(total);
  const [couponCodeError, setCouponCodeError] = useState(false);
  const [couponCodeShow, setCouponCodeShow] = useState(0);
  const [discountType, setDiscountType] = useState("");
  const [couponName, setCouponName] = useState("");

  // const { orderId } = useSelector((state) => state.user);
  const orderId = localStorage.getItem("orderId");

  useEffect(() => {
    // Convert total to a number if it's not already
    // dispatch(userOrderId(orderId))
    console.log(orderId, "jsfahgfahgfsaj");
    const totalAmount = Number(total);
    setDiscountedTotal(totalAmount);
  }, [total]);

  const orderPlaceAPi1 = async (order) => {
    const token = localStorage.getItem("authToken");
    const myHeaders = new Headers();

    const formdata = objectToFormData({ ...order, Authorization: token });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      // mode: "no-cors",
      redirect: "follow",
    };

    try {
      const res = await fetch(
        "https://admin.arshiyasehgal.com/API/User/placeOrder",
        requestOptions
      );
      const data = await res.json();

      // Ensure the response has the expected structure
      const newOrderId = data?.data?.order_id;
      if (!newOrderId) {
        throw new Error("Invalid order response");
      }

      console.log("orderId", newOrderId);
      // dispatch(userOrderId(newOrderId));
      localStorage.setItem("orderId", newOrderId);

      navigate("/order-proceed");

      if (data.status !== true && data.message !== "Data not found")
        throw new Error(data.message);
      return data;
    } catch (err) {
      throw new Error(err.message);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      orderPlaceAPi1({ product_id, qty });
    }
  }, [isAuthenticated]);

  const applyCouponHandler = () => {
    // Convert total to a number if it's not already
    const totalAmount = Number(total);
    if (!isNaN(totalAmount) && couponCode.toLowerCase() === "first20") {
      const discount = totalAmount * 0.2;
      const discountValue = Math.round(discount);
      setCouponCodeShow(discountValue);
      setDiscountedTotal(totalAmount - discount);
    } else {
      setDiscountedTotal(totalAmount);
      setCouponCodeError(true);
      setTimeout(() => {
        setCouponCodeError(false);
      }, 5000);
    }
  };

  const copounApiHandler = async () => {
    try {
      const formdata = objectToFormData({
        order_id: orderId,
        coupon_code: couponCode,
      });

      const res = await axios.post(
        "https://admin.arshiyasehgal.com/API/User/applyCoupon",
        formdata
      );

      console.log(res, "jsfahgfahgfsaj");
      setDiscountedTotal(res?.data?.data?.total_after_discount);
      setDiscountType(res?.data?.data?.discount_type);
      setCouponCodeShow(res?.data?.data?.discount_amount);
      setCouponName(res?.data?.data?.coupon_code);

      if (res.data.status === true) {
        toast.success("Coupon Apply successfully");
      } else {
        toast.error("Invailed coupon");
        const totalAmount = Number(total);
        setDiscountedTotal(totalAmount);
        setCouponCodeShow(0);
        setCouponName("");
      }
    } catch (error) {
      console.log("Error IN APPLY COUPON: " + error);
      toast.error("Invailed coupon");
      const totalAmount = Number(total);
      setDiscountedTotal(totalAmount);
      setCouponCodeShow(0);
    }
  };

  const submitHandler = function (data) {
    addAddress(data);
    reset();
  };

  // const orderCheckoutHandler = (mode) => {
  //   const order_id = localStorage.getItem("orderId");
  //   orderPlace(
  //     { product_id, qty },
  //     {
  //       onSuccess: async (res) => {
  //         // orderCheckout({ order_id: res.data.order_id, address_id,payment_mode:mode });
  //         const { url } = await orderCheckout({
  //           order_id: res.data.order_id,
  //           address_id,
  //           payment_mode: mode,
  //         });

  //         if (mode === "online") window.location.href = url;
  //         else;
  //       },
  //     }
  //   );
  //   if (!order_id) return;
  //   // orderCheckout({ order_id, address_id });
  // };

  const orderPlaceAPi = async (order) => {
    const token = localStorage.getItem("authToken");
    const myHeaders = new Headers();

    const formdata = objectToFormData({ ...order, Authorization: token });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      // mode: "no-cors",
      redirect: "follow",
    };

    try {
      const res = await fetch(
        "https://admin.arshiyasehgal.com/API/User/placeOrder",
        requestOptions
      );
      const data = await res.json();

      if (data.status !== true && data.message !== "Data not found")
        throw new Error(data.message);
      return data;
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const orderCheckout1 = async (order) => {
    const token = localStorage.getItem("authToken");

    const myHeaders = new Headers();

    const formdata = objectToFormData({ ...order, Authorization: token });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    try {
      const res = await fetch(
        "https://admin.arshiyasehgal.com/API/User/checkoutOrder",
        requestOptions
      );
      const data = await res.json();

      console.log("Order Place API Response:", data);

      if (data.status !== true && data.message !== "Data not found")
        throw new Error(data.message);
      return data;
    } catch (err) {
      throw new Error(err.message);
    }
  };

  const orderCheckoutHandler = async (mode) => {
    // Remove the localStorage order_id check since it's unnecessary in your logic
    // const order_id = localStorage.getItem("orderId");
    // console.log("hjafhaf", order_id);
    // if (!order_id) return;

    const handlePaymentResult = (success) => {
      window.location.href = success ? "/cod-success" : "/order-failed";
    };

    try {
      // // Place the order and get the response
      // const orderResponse = await orderPlaceAPi({ product_id, qty });

      // // Log the response to understand its structure
      // console.log("Order Response:", orderResponse);

      // // Check if orderResponse is defined and has data
      // if (!orderResponse || !orderResponse.data || !orderResponse.data.order_id) {
      //   throw new Error("Invalid order response");
      // }
      // // Ensure the response has the expected structure
      // const newOrderId = orderResponse?.data?.order_id;
      if (!orderId) {
        throw new Error("Invalid order response");
      }

      console.log("orderId", orderId, mode);

      // Checkout with the new order ID
      const { url } = await orderCheckout1({
        order_id: orderId,
        address_id,
        payment_mode: mode,
        coupon_code: couponCode,
      });

      console.log("Checkout URL:", url);

      if (mode === "online") {
        window.location.href = url;
      } else {
        window.location.href = url; // For cash on delivery, show success page directly
      }
    } catch (error) {
      console.error("Operation failed", error);
      // handlePaymentResult(false); // Handle failure
    }
  };

  const orderCheckoutHandlerCOD = async (mode) => {
    // Remove the localStorage order_id check since it's unnecessary in your logic
    // const order_id = localStorage.getItem("orderId");
    // console.log("hjafhaf", order_id);
    // if (!order_id) return;

    const handlePaymentResult = (success, orderId) => {
      window.location.href = success
        ? `/cod-success/${orderId}`
        : `/order-failed/${orderId}`;
    };

    try {
      // // Place the order and get the response
      // const orderResponse = await orderPlaceAPi({ product_id, qty });

      // // Log the response to understand its structure
      // console.log("Order Response:", orderResponse);

      // // Check if orderResponse is defined and has data
      // if (!orderResponse || !orderResponse.data || !orderResponse.data.order_id) {
      //   throw new Error("Invalid order response");
      // }
      // // Ensure the response has the expected structure
      // const newOrderId = orderResponse?.data?.order_id;
      if (!orderId) {
        throw new Error("Invalid order response");
      }

      console.log("orderId", orderId, mode);

      // Checkout with the new order ID
      const res = await orderCheckout1({
        order_id: orderId,
        address_id,
        payment_mode: mode,
        coupon_code: couponCode,
      });

      console.log("Checkout URL:", res);

      if (res.status === true) {
        handlePaymentResult(true, orderId);
        localStorage.removeItem("orderId");
      } else {
        handlePaymentResult(false, orderId);
      }
    } catch (error) {
      console.error("Operation failed", error);
      handlePaymentResult(false); // Handle failure
    }
  };

  // const orderCheckoutHandler = async (mode) => {
  //   const order_id = localStorage.getItem("orderId");
  //   if (!order_id) return;

  //   const handlePaymentResult = (success) => {
  //     window.location.href = success ? "/cod-success" : "/order-failed";
  //   };

  //   try {
  //     // Call orderPlace with onSuccess callback
  //     orderPlace(
  //       { product_id, qty },
  //       {
  //         onSuccess: async (res) => {
  //           try {
  //             const { url } = await orderCheckout({
  //               order_id: res.data.order_id,
  //               address_id,
  //               payment_mode: mode,
  //             });

  //             if (mode === "online") {
  //               window.location.href = url;
  //             } else {
  //               handlePaymentResult(true); // For cash on delivery, show success page directly
  //             }
  //           } catch (error) {
  //             console.error("Payment failed", error);
  //             // handlePaymentResult(false);
  //           }
  //         },
  //         onFailure: (error) => {
  //           console.error("Order placement failed", error);
  //           // handlePaymentResult(false);
  //         },
  //       }
  //     );
  //   } catch (error) {
  //     console.error("Order place failed", error);
  //     // handlePaymentResult(false);
  //   }
  // };

  const deleteAddressHandler = (id) => {
    deleteAddress(id);
  };

  const handleButtonClick = () => {
    setSubmitted(true);
  };

  const handleChangeClick = () => {
    setChangeClicked(true);
    setSubmitted(false);
  };
  const [isAddNewaddress, setAddNewaddress] = useState(false);
  const [isDeliverHere, setDeliverHere] = useState(false);

  const handleAddAdressClick = (val = {}) => {
    dispatch(userBillingAddress(val));
    setDeliverHere(true);
    setAddNewaddress(true);
  };

  const handleDeliverHereClick = () => {
    setDeliverHere(!isDeliverHere);
    setAddNewaddress(false);
  };

  const [isOnlineSelected, setOnlineSelected] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");

  const handleRadioChange = (event) => {
    setOnlineSelected(event.target.value === "online");
    setPaymentMethod(event.target.value);
  };

  const handleDeliverHereClick1 = () => {
    setPaymentMethod(!paymentMethod);
  };

  const [showAddressForm, setShowAddressForm] = useState(false);
  const handleAddAdressClick2 = () => {
    setShowAddressForm(!showAddressForm);
  };

  const guestSubmitHandler = (data) => {
    login(data, {
      onSuccess: () => {
        setIsOtp(true);
        reset();
      },
    });
  };

  const guestOtpHandler = (data) => {
    verifyOtp(data, {
      onSuccess: () => {
        setSubmitted(true);
        setUpdateCart(true);
        reset();
        // navigate("/");
      },
    });

    setUpdateCart(true);
  };

  useEffect(() => {
    if (isAuthenticated !== true) return;
    console.log(globalCart, "out side effect");
    if (updateCart) {
      console.log("inside effect", product_idGlobal, qtyGlobal);
      addCart(
        {
          product_id: product_idGlobal,
          qty: qtyGlobal,
        },
        {
          onSuccess: setUpdateCart(false),
        }
      );
      sessionStorage.removeItem("cart");
      console.log("items remove successfully.");
    }
  }, [isAuthenticated, addCart, product_idGlobal, qtyGlobal, updateCart]);

  const totalPrice = globalCart?.reduce((accumulator, currentValue) => {
    return accumulator + +currentValue.price * +currentValue.qty;
  }, 0);

  if (isAuthenticated && isLoading) return <Loader />;
  // if (isAuthenticated && error) return <div>{error.message}</div>;
  if (isCartAdd || isOder) return <Loader />;

  // if (!cart && isAuthenticated) {
  //   navigate("/");
  // }

  return (
    <div>
      <div className="Arsia--contact--us--page--web--view--1">
        <div className="Arsia--header6--web">
          <ArsiaHeader />
        </div>
        <div className="Arsia--header6--mobile">
          <ArsiaHeaderMobile />
        </div>
        <div className="Arsia--contact--us--page--web--view--cart">
          <div className="Arsia--cart--page--card">
            <div className="Arsia--cart--page--card--add-to-cart">
              {isAuthenticated ? (
                <div className="Arsia--Adress--and--payement--card">
                  {/* <div className="Arsia--Address--login--card1">
                    <p className="Arsia--Address--login--card--p1">
                      Guest Checkout
                    </p>
                    <div className="Arsia--Address--login--card--1--card">
                      <p className="Arsia--Address--login--card--p2">
                        srishti.11.pal@gmail.com
                      </p>
                      <p className="Arsia--Address--login--card--p2">
                        <span onClick={handleChangeClick}>Change</span>
                      </p>
                    </div>
                  </div> */}
                  {/* <div className="Arsia--cart--border--line"></div> */}
                  <div className="Arsia--Address-payment--breadcrumb">
                    {isDeliverHere ? (
                      <div className="Arsia--address--whole--card--2">
                        <p className="Arsia--billing--and--shipping--address1--p">
                          Billing & Shipping Address
                        </p>
                        <div className="Arsia--address--whole--card">
                          <div className="Arsia--address--first--card">
                            <div className="Arsia--address--first--card--1">
                              <div className="Arsia--address--first--card--2">
                                <p className="Arsia--address--first--card--1--p1">
                                  {activeAddress.name}
                                </p>
                                <p className="Arsia--address--first--card--1--p2">
                                  {`${activeAddress.address}, ${activeAddress.city}, ${activeAddress.state} - ${activeAddress.pincode}`}
                                </p>
                                <p className="Arsia--address--first--card--1--p3">
                                  Contact -{" "}
                                  <span>
                                    {phoneNumberFormat(activeAddress.mobile)}
                                  </span>
                                </p>
                              </div>
                            </div>
                            <div className="Arsia--address--first--card--1--edit">
                              <p
                                className="Arsia--Address--login--card--p2"
                                onClick={handleDeliverHereClick}
                              >
                                <span>Change</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="Arsia--cart--border--line"></div>

                        <div className="Arsia--payment--gateway">
                          <p className="Arsia--billing--and--shipping--address1--p">
                            Payment Methods
                          </p>

                          {paymentMethod ? (
                            <div className="Arsia--address--first--card">
                              <div className="Arsia--address--first--card--1">
                                <div className="Arsia--address--first--card--2">
                                  <p className="Arsia--address--first--card--1--p1">
                                    {paymentMethod === "online"
                                      ? "Online"
                                      : "Cash on delivery"}
                                  </p>
                                </div>
                              </div>
                              <div className="Arsia--address--first--card--1--edit">
                                <p
                                  className="Arsia--Address--login--card--p2"
                                  onClick={handleDeliverHereClick1}
                                >
                                  <span>Change</span>
                                </p>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="Arsia--payment--gateway"
                              style={{ flexDirection: "column !important" }}
                            >
                              {/* <div className="Arsia--address--first--card--1">
                                <div>
                                  <input
                                    type="radio"
                                    name="paymentMethod"
                                    value="cod"
                                    checked={paymentMethod === "cod"}
                                    onChange={handleRadioChange}
                                  ></input>
                                </div>
                                <div className="Arsia--address--first--card--2">
                                  <p className="Arsia--address--first--card--1--p1">
                                    Cash on delivery
                                  </p>
                                </div>
                              </div> */}
                              <div className="Arsia--address--first--card--1">
                                <div>
                                  <input
                                    type="radio"
                                    name="paymentMethod"
                                    value="online"
                                    checked={paymentMethod === "online"}
                                    onChange={handleRadioChange}
                                  ></input>
                                </div>
                                <div className="Arsia--address--first--card--2">
                                  <p className="Arsia--address--first--card--1--p1">
                                    Online
                                  </p>
                                  <p className="Arsia--address--first--card--1--p2">
                                    Credit | Debit | UPI | Wallets | Internet
                                    Banking
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="Arsia--billing--and--shipping--address1">
                        <p className="Arsia--billing--and--shipping--address1--p">
                          Billing & Shipping Address
                        </p>

                        <div className="Arsia--address--whole--card--3">
                          {address?.map((item, i) => {
                            return (
                              <div
                                key={i}
                                className="Arsia--address--whole--card1"
                              >
                                <div className="Arsia--address--first--card">
                                  <div className="Arsia--address--first--card--1">
                                    <div>
                                      <input
                                        type="radio"
                                        onClick={() =>
                                          handleAddAdressClick(item)
                                        }
                                      ></input>
                                    </div>
                                    <div className="Arsia--address--first--card--2">
                                      <p className="Arsia--address--first--card--1--p1">
                                        {item.name}
                                      </p>
                                      <p className="Arsia--address--first--card--1--p2">
                                        {`${item.address}, ${item.city}, ${item.state} - ${item.pincode}`}
                                      </p>
                                      <p className="Arsia--address--first--card--1--p3">
                                        Contact -{" "}
                                        <span>
                                          {phoneNumberFormat(item.mobile)}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <p
                                    className="Arsia-address-delete"
                                    onClick={() =>
                                      deleteAddressHandler(item.id)
                                    }
                                  >
                                    {isdeleting ? "Loading..." : "Delete"}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                          <div className="Arsia--cart--border--line"></div>

                          <div
                            className="Arsia--add--address--value"
                            onClick={handleAddAdressClick2}
                          >
                            <p className="Arsia--add--address--value--p1">+ </p>
                            <p className="Arsia--add--address--value--p2">
                              Add Address
                            </p>
                          </div>
                        </div>
                        {showAddressForm && (
                          <form onSubmit={handleSubmit(submitHandler)}>
                            <div className="Arsia--billing--and--shipping--address">
                              <div>
                                <input
                                  className="Arsia--billing--and--shipping--address--input"
                                  type="text"
                                  placeholder="Full Name"
                                  {...register("name", {
                                    required: "Name must be required.",
                                  })}
                                ></input>
                                {errors?.["name"]?.message && (
                                  <span>{errors?.["name"]?.message}</span>
                                )}
                              </div>

                              <input
                                className="Arsia--billing--and--shipping--address--input"
                                type="email"
                                placeholder="Email"
                                {...register("email", {
                                  required: "Email must be required.",
                                })}
                              ></input>
                              {errors?.["email"]?.message && (
                                <span>{errors?.["email"]?.message}</span>
                              )}

                              <input
                                className="Arsia--billing--and--shipping--address--input"
                                type="text"
                                placeholder="Mobile"
                                {...register("mobile", {
                                  required: "Mobile must be required.",
                                })}
                              ></input>
                              {errors?.["mobile"]?.message && (
                                <span>{errors?.["mobile"]?.message}</span>
                              )}

                              <input
                                className="Arsia--billing--and--shipping--address--input"
                                type="text"
                                placeholder="Pincode"
                                {...register("pincode", {
                                  required: "Pincode must be required.",
                                })}
                              ></input>
                              {errors?.["pincode"]?.message && (
                                <span>{errors?.["pincode"]?.message}</span>
                              )}

                              <div className="Arsia--billing--and--shipping--address--input--select">
                                <input
                                  type="text"
                                  placeholder="City"
                                  {...register("city", {
                                    required: "City must be required.",
                                  })}
                                ></input>
                                {errors?.["city"]?.message && (
                                  <span>{errors?.["city"]?.message}</span>
                                )}
                                <select
                                  {...register("state", {
                                    required: "State must be required.",
                                  })}
                                >
                                  <option value="">State</option>
                                  <option value="Andhra Pradesh">
                                    Andhra Pradesh
                                  </option>
                                  <option value="Arunachal Pradesh">
                                    Arunachal Pradesh
                                  </option>
                                  <option value="Assam">Assam</option>
                                  <option value="Andaman and Nicobar Islands">
                                    Andaman and Nicobar Islands
                                  </option>
                                  <option value="Bihar">Bihar</option>
                                  <option value="Chandigarh">Chandigarh</option>
                                  <option value="Chhattisgarh">
                                    Chhattisgarh
                                  </option>
                                  <option value="Dadra and Nagar Haveli">
                                    Dadra and Nagar Haveli
                                  </option>
                                  <option value="Daman and Diu">
                                    Daman and Diu
                                  </option>
                                  <option value="Delhi">Delhi</option>
                                  <option value="Gujarat">Gujarat</option>
                                  <option value="Haryana">Haryana</option>
                                  <option value="Himachal Pradesh">
                                    Himachal Pradesh
                                  </option>
                                  <option value="Jammu and Kashmir">
                                    Jammu and Kashmir
                                  </option>
                                  <option value="Goa">Goa</option>
                                  <option value="Jharkhand">Jharkhand</option>
                                  <option value="Karnataka">Karnataka</option>
                                  <option value="Kerala">Kerala</option>
                                  <option value="Lakshadweep">
                                    Lakshadweep
                                  </option>
                                  <option value="Madhya Pradesh">
                                    Madhya Pradesh
                                  </option>
                                  <option value="Maharashtra">
                                    Maharashtra
                                  </option>
                                  <option value="Manipur">Manipur</option>
                                  <option value="Meghalaya">Meghalaya</option>
                                  <option value="Mizoram">Mizoram</option>
                                  <option value="Nagaland">Nagaland</option>
                                  <option value="Odisha">Odisha</option>
                                  <option value="Puducherry">Puducherry</option>
                                  <option value="Punjab">Punjab</option>
                                  <option value="Rajasthan">Rajasthan</option>
                                  <option value="Sikkim">Sikkim</option>
                                  <option value="Tamil Nadu">Tamil Nadu</option>
                                  <option value="Telangana">Telangana</option>
                                  <option value="Tripura">Tripura</option>
                                  <option value="Uttarakhand">
                                    Uttarakhand
                                  </option>
                                  <option value="Uttar Pradesh">
                                    Uttar Pradesh
                                  </option>
                                  <option value="West Bengal">
                                    West Bengal
                                  </option>
                                </select>
                                {errors?.["state"]?.message && (
                                  <span>{errors?.["state"]?.message}</span>
                                )}

                                <select
                                  {...register("country", {
                                    required: "Country must be required.",
                                  })}
                                >
                                  <option value="">Country</option>
                                  <option value="India">India</option>
                                </select>
                                {errors?.["country"]?.message && (
                                  <span>{errors?.["country"]?.message}</span>
                                )}
                              </div>
                              <input
                                className="Arsia--billing--and--shipping--address--input1"
                                type="text"
                                placeholder="Address "
                                {...register("address", {
                                  required: "Address must be required.",
                                })}
                              ></input>
                              {errors?.["address"]?.message && (
                                <span>{errors?.["address"]?.message}</span>
                              )}

                              <input
                                className="Arsia--billing--and--shipping--address--input1"
                                type="hidden"
                                placeholder="Address Type"
                                value="home"
                                {...register("address_type", {
                                  required: "Address type must be required.",
                                })}
                              ></input>
                              {errors?.["address_type"]?.message && (
                                <span>{errors?.["address_type"]?.message}</span>
                              )}
                              {/* <input
                              className="Arsia--billing--and--shipping--address--input1"
                              type="text"
                              placeholder="Address 2"
                            ></input> */}
                              {/* <button onClick={handleAddAdressClick}>
                                Deliver Here
                              </button> */}
                              <button disabled={isAdding}>
                                {isAdding ? "Loading..." : "Deliver Here"}
                              </button>
                            </div>
                          </form>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ) : !isOtp ? (
                <form onSubmit={handleSubmit(guestSubmitHandler)}>
                  <div className="Arsia--Address--login--card">
                    <p className="Arsia--Address--login--card--p1">
                      Guest Checkout?
                    </p>
                    <p className="Arsia--Address--login--card--p2">
                      Enter your mobile number and become a part of Arshiya
                      Sehgal Community.
                    </p>
                    <div className="Arsia--Address--login--card--1">
                      <input
                        {...register("mobile", {
                          required: "Mobile must be required.",
                        })}
                        type="text"
                        placeholder="Mobile Number"
                      />
                      {/* <button onClick={handleButtonClick}>Submit</button> */}
                      <button>Submit</button>
                    </div>
                  </div>
                </form>
              ) : (
                <form onSubmit={handleSubmit(guestOtpHandler)}>
                  <div className="Arsia--Address--login--card">
                    <p className="Arsia--Address--login--card--p1">
                      Guest Checkout?
                    </p>
                    <p className="Arsia--Address--login--card--p2">
                      Enter OTP to verify mobile number.
                    </p>
                    <div className="Arsia--Address--login--card--1">
                      <input
                        {...register("otp", {
                          required: "OTP must be required.",
                        })}
                        type="text"
                        placeholder="OTP"
                      />
                      {/* <button onClick={handleButtonClick}>Submit</button> */}
                      <button>Submit</button>
                    </div>
                  </div>
                </form>
              )}
            </div>
            <div className="Arsia--cart--page--card--order--summury">
              {paymentMethod === "cod" && (
                <div className="Arsia--cart--page--card--order--summury--card">
                  <p className="Arsia--cart--page--card--order--summury--card--p1">
                    Order Summary
                  </p>
                  <div className="Arsia--cart-order-summury--content">
                    <div className="Arsia--cart-order-summury--content1">
                      <p>Price</p>
                      <p>
                        {isAuthenticated
                          ? priceFormat(+total)
                          : priceFormat(+totalPrice)}
                      </p>
                    </div>
                    {/* <div className="Arsia--cart-order-summury--content1">
                      <p>Discount</p>
                      <p>Rs 500</p>
                    </div> */}
                    <div className="Arsia--cart-order-summury--content1">
                      <p>Shipping</p>
                      <p>Free</p>
                    </div>
                    <div className="Arsia--cart-order-summury--content1">
                      <p>Coupon Discount</p>
                      <p>Rs {couponCodeShow}</p>
                    </div>
                    <div className="Arsia--cart-order-summury--content1">
                      <p>Coupon Name</p>
                      <p> {couponName || "--"}</p>
                    </div>
                  </div>
                  <div className="Arsia--cart--border--line"></div>
                  <div className="Arsia--cart-order-summury--content1-1">
                    <p className="Arsia--cart-order-summury--content1--p">
                      TOTAL
                    </p>
                    <p className="Arsia--cart-order-summury--content1--p2">
                      {isAuthenticated
                        ? priceFormat(+discountedTotal)
                        : priceFormat(+totalPrice)}
                    </p>
                  </div>
                  <div className="Arsia--cart--coupon--code">
                    <input
                      type="text"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      className="coupon_input"
                      placeholder="Coupon Code"
                    />
                    <button
                      className="coupon_button"
                      onClick={copounApiHandler}
                    >
                      Apply
                    </button>
                    {/* <div className="Arsia--cart--coupon--code--1">
                      <p>Coupon Code</p>
                    </div>
                    <div className="Arsia--cart--coupon--code--2">
                      <p>Apply</p>
                    </div> */}
                  </div>
                  {couponCodeError && (
                    <p style={{ color: "red" }}>Invalid coupon code</p>
                  )}
                  {/* <Link to={"/OrderSummury"}> */}
                  <button
                    onClick={() => orderCheckoutHandlerCOD("COD")}
                    className="Arsia--cart-order-summury--content1-1--button"
                  >
                    {isCheckout ? "Loading..." : "Proceed to Checkout"}
                  </button>
                  {/* </Link> */}
                </div>
              )}
              {paymentMethod === "online" && (
                <div className="Arsia--cart--page--card--order--summury--card">
                  <p className="Arsia--cart--page--card--order--summury--card--p1">
                    Order Summary
                  </p>
                  <div className="Arsia--cart-order-summury--content">
                    <div className="Arsia--cart-order-summury--content1">
                      <p>Price</p>
                      <p>
                        {isAuthenticated
                          ? priceFormat(+total)
                          : priceFormat(+totalPrice)}
                      </p>
                    </div>
                    {/* <div className="Arsia--cart-order-summury--content1">
                      <p>Discount</p>
                      <p>Rs 500</p>
                    </div> */}
                    <div className="Arsia--cart-order-summury--content1">
                      <p>Shipping</p>
                      <p>Free</p>
                    </div>
                    <div className="Arsia--cart-order-summury--content1">
                      <p>Coupon Discount</p>
                      <p>Rs {couponCodeShow}</p>
                    </div>
                    <div className="Arsia--cart-order-summury--content1">
                      <p>Coupon Name</p>
                      <p> {couponName || "--"}</p>
                    </div>
                  </div>
                  <div className="Arsia--cart--border--line"></div>
                  <div className="Arsia--cart-order-summury--content1-1">
                    <p className="Arsia--cart-order-summury--content1--p">
                      TOTAL
                    </p>
                    <p className="Arsia--cart-order-summury--content1--p2">
                      {isAuthenticated
                        ? priceFormat(+discountedTotal)
                        : priceFormat(+totalPrice)}
                    </p>
                  </div>
                  <div className="Arsia--cart--coupon--code">
                    <input
                      type="text"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                      className="coupon_input"
                      placeholder="Coupon Code"
                    />
                    <button
                      className="coupon_button"
                      onClick={copounApiHandler}
                    >
                      Apply
                    </button>
                    {/* <div className="Arsia--cart--coupon--code--1">
                      <p>Coupon Code</p>
                    </div>
                    <div className="Arsia--cart--coupon--code--2">
                      <p>Apply</p>
                    </div> */}
                  </div>
                  {couponCodeError && (
                    <p style={{ color: "red" }}>Invalid coupon code</p>
                  )}
                  {/* <Link to={"/paymentGatewayPage"}>
                    <button className="Arsia--cart-order-summury--content1-1--button">
                      Proceed to Checkout
                    </button>
                  </Link> */}
                  <button
                    onClick={() => orderCheckoutHandler("online")}
                    className="Arsia--cart-order-summury--content1-1--button"
                  >
                    {isCheckout ? "Loading..." : "Proceed to Checkout"}
                  </button>
                </div>
              )}
              {!paymentMethod && (
                <div className="Arsia--cart--page--card--order--summury--card">
                  <p className="Arsia--cart--page--card--order--summury--card--p1">
                    Order Summary
                  </p>
                  <div className="Arsia--cart-order-summury--content">
                    <div className="Arsia--cart-order-summury--content1">
                      <p>Price</p>
                      <p>
                        {isAuthenticated
                          ? priceFormat(+total)
                          : priceFormat(+totalPrice)}
                      </p>
                    </div>
                    {/* <div className="Arsia--cart-order-summury--content1">
                      <p>Discount</p>
                      <p>Rs 500</p>
                    </div> */}
                    <div className="Arsia--cart-order-summury--content1">
                      <p>Shipping</p>
                      <p>Free</p>
                    </div>
                    <div className="Arsia--cart-order-summury--content1">
                      <p>Coupon Discount</p>
                      <p>Rs {couponCodeShow}</p>
                    </div>
                    <div className="Arsia--cart-order-summury--content1">
                      <p>Coupon Name</p>
                      <p>{couponName || "--"}</p>
                    </div>
                  </div>
                  <div className="Arsia--cart--border--line"></div>
                  <div className="Arsia--cart-order-summury--content1-1">
                    <p className="Arsia--cart-order-summury--content1--p">
                      TOTAL
                    </p>
                    <p className="Arsia--cart-order-summury--content1--p2">
                      {isAuthenticated
                        ? priceFormat(+total)
                        : priceFormat(+totalPrice)}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="Arsia--header6--mobile">
          <FooterMobile />
        </div>
        <div className="Arsia--header6--web">
          <ArsiaFooter />
        </div>
      </div>
    </div>
  );
}
