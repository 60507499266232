import './Arsia.css';
import React, { useState, useRef, useEffect } from 'react';
import ArsiaFooter from './ArsiaFooter';
import ArsiaHeader from './ArsiaHeader';
import ArsiaHeaderMobile from './Arsia-header-mobile';
import FooterMobile from './Arsia--footer--mobile';
import axios from 'axios';

export default function ArsiaShippingAddress() {
    const [isSearchCardOpen, setIsSearchCardOpen] = useState(false);
    const [shippingData,setShippingData] = useState({});
    const handleSearchIconClick = () => {
        setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
    };
    const handleArrowLeftClick = () => {
        setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
    };
    const [sidebarVisible, setSidebarVisible] = useState(false);

    const showSidebar = () => {
        setSidebarVisible(true);
    };

    const hideSidebar = () => {
        setSidebarVisible(false);
    };
    useEffect(() => {
        // Scroll to the top of the page when navigating to the About page
        window.scrollTo(0, 0);
      }, []);

      
      useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get('https://admin.arshiyasehgal.com/API/Web/getShippingPolicy');
            setShippingData(response.data.data[0]);
            console.log("jgskghsghsghs",response.data.data[0].shipping_policy)
            // setLoading(false);
          } catch (error) {
            console.log(error)
          }
        };
    
        fetchData();
      }, []); 
    

    return (
        <>
            <div className="Arsia--website--design">
                <ArsiaHeader />
                <div className='Arsia--about--us--page'>
                    {/* <h1>Shipping worldwide</h1>
                    <p>For all other countries, we have tied up with the best logistic partners like FedEx, DHL, TNT, UPS and Aramex to provide you with the most reliable and efficient service.<br></br>

                    <br></br>Shipping charges will be added/ mentioned while checking out on your order. For any difficulty, please reach out to you us over social media or arshiyasehgal40@gmail.com<br></br>

                    <br></br>We accept all international credit and debit cards. Simply choose a credit card at the time of check out. This is the simplest way to complete your transaction.
<br></br>

<br></br>An alternate payment option is PayPal. You can select this at the time of check out as well. Once we get an order confirmation email, we will send you an invoice (on the email id provided) for the same.<br></br>
<br></br>The payment gateway for international payments, Paytm is not accepted.<br></br>
<br></br>Orders shipped outside of India may be subject to import taxes, customs duties and fees levied by the destination country. The recipient of an international shipment may be subject to such import taxes, customs duties and fees, which are levied once a shipment reaches the recipient's country.
<br></br>
<br></br>No refunds will be provided.<br></br>

                   </p>
                   
                   
                    <h1>Shipping Information - India</h1>

                    <p>We at ArshiyaSehgal (A4 Productions) know how important it is to receive your purchased products in the finest condition and on time. So we use a large network of couriers to deliver your products to you as early as possible. We are as excited as you to receive your pair of ArshiyaSehgal (A4 Productions)!<br></br>

                    <br></br>Depending upon the location, the product is delivered to you in 3-5 working days after it has been dispatched from our warehouse.
Free shipping is available on all products, subject to a minimum order value.<br></br>
<br></br>The whole order amount including any delivery charges will be refunded if the order is cancelled or lost in transit by us.<br></br>
<br></br>COD Orders: All COD orders are contacted by our customer service team for security purposes. 3 attempts will be made to confirm the order failing which, orders will be cancelled. (We suggest to confirm your COD order on call for a smooth delivery experience).<br></br>
<br></br>Our courier partners will make contact with the shipping number provided at the time of delivery. Failing to receive or attend to the calls will result in your order being returned back to our warehouse.<br></br>
</p> */}
                   
                   <div dangerouslySetInnerHTML={{ __html: shippingData && shippingData.shipping_policy }} />
                   
                </div>
                <ArsiaFooter />
            </div>

            {/* mobile--view */}

            <div className="Arsia--website--design--mobile--view">

                {/* 
   #Arsiya Header
*/}
                <ArsiaHeaderMobile />


                <div className='Arsia--about--us--page'>
                           {/* <h1>Shipping worldwide</h1>
                    <p>For all other countries, we have tied up with the best logistic partners like FedEx, DHL, TNT, UPS and Aramex to provide you with the most reliable and efficient service.<br></br>

                    <br></br>Shipping charges will be added/ mentioned while checking out on your order. For any difficulty, please reach out to you us over social media or arshiyasehgal40@gmail.com<br></br>

                    <br></br>We accept all international credit and debit cards. Simply choose a credit card at the time of check out. This is the simplest way to complete your transaction.
<br></br>

<br></br>An alternate payment option is PayPal. You can select this at the time of check out as well. Once we get an order confirmation email, we will send you an invoice (on the email id provided) for the same.<br></br>
<br></br>The payment gateway for international payments, Paytm is not accepted.<br></br>
<br></br>Orders shipped outside of India may be subject to import taxes, customs duties and fees levied by the destination country. The recipient of an international shipment may be subject to such import taxes, customs duties and fees, which are levied once a shipment reaches the recipient's country.
<br></br>
<br></br>No refunds will be provided.<br></br>

                   </p>
                   
                   
                    <h1>Shipping Information - India</h1>

                    <p>We at ArshiyaSehgal (A4 Productions) know how important it is to receive your purchased products in the finest condition and on time. So we use a large network of couriers to deliver your products to you as early as possible. We are as excited as you to receive your pair of ArshiyaSehgal (A4 Productions)!<br></br>

                    <br></br>Depending upon the location, the product is delivered to you in 3-5 working days after it has been dispatched from our warehouse.
Free shipping is available on all products, subject to a minimum order value.<br></br>
<br></br>The whole order amount including any delivery charges will be refunded if the order is cancelled or lost in transit by us.<br></br>
<br></br>COD Orders: All COD orders are contacted by our customer service team for security purposes. 3 attempts will be made to confirm the order failing which, orders will be cancelled. (We suggest to confirm your COD order on call for a smooth delivery experience).<br></br>
<br></br>Our courier partners will make contact with the shipping number provided at the time of delivery. Failing to receive or attend to the calls will result in your order being returned back to our warehouse.<br></br>
</p> */}
                   
                   <div dangerouslySetInnerHTML={{ __html: shippingData && shippingData.shipping_policy }} />
                   
                </div>
                {/* 
   #Arshiya footer mobile view
 */}

                <FooterMobile />
            </div>
        </>
    );
}