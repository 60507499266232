import React from 'react'
import ArsiaHeader from './ArsiaHeader'
import ArsiaFooter from './ArsiaFooter'
import ArsiaHeaderMobile from './Arsia-header-mobile'
import FooterMobile from './Arsia--footer--mobile'
import phonepayimg from './image/phonepayimg.png';

export default function PaymentGatewayPage()
{
    return(
        <div>
           <div className="Arsia--header6--web">
        <ArsiaHeader />
        </div>

        <div className="Arsia--header6--mobile">
        <ArsiaHeaderMobile />
        </div>
            <div className='Arsia--Payment--gateway--Page'>
             <img src={phonepayimg}></img>
            </div>
            <div className="Arsia--header6--mobile">
        <FooterMobile />
        </div>
        <div className="Arsia--header6--web">
        <ArsiaFooter />
        </div>
        </div>
    )
}