import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { orderCheckout as apiOrderCheckout } from "../../services/apiOrder";

export const useOrderCheckout = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: orderCheckout, isPending: isCheckout } = useMutation({
    mutationFn: (order) => apiOrderCheckout(order),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["order"],
      });
      console.log(res,'resss')
      // window.location.href = res.URL;
    },
    onError: (err) => {
      toast.error(err.message);
    },
  });

  return { orderCheckout, isCheckout };
};
