// const initialState = {
//   cart: getCartFromLocalStorage(),
// };

// function getCartFromLocalStorage() {
//   const storedCart = localStorage.getItem("cart");
//   return storedCart ? JSON.parse(storedCart) : [];
// }

// const cartReducer = function (state = initialState, action) {
//   switch (action.type) {
//     case "cart/cartAdding": {
//       const existingItemIndex = state.cart.findIndex(
//         (item) => item.product_id === action.payload.product_id
//       );

//       if (existingItemIndex !== -1) {
//         const updatedCart = state.cart.map((item, index) => {
//           if (index === existingItemIndex) {
//             return {
//               ...item,
//               qty: item.qty + action.payload.qty,
//             };
//           }
//           return item;
//         });

//         return { ...state, cart: updatedCart };
//       } else {
//         return { ...state, cart: [...state.cart, action.payload] };
//       }
//     }

//     case "cart/cartDeleting":
//       return {
//         ...state,
//         cart: state.cart.filter((item) => item.product_id !== action.payload),
//       };

//     case "cart/cartQtyHandeling": {
//       return {
//         ...state,
//         cart: state.cart.map((item) => {
//           if (item.product_id === action.payload.id) {
//             return {
//               ...item,
//               qty: action.payload.qty,
//             };
//           } else {
//             return item;
//           }
//         }),
//       };
//     }

//     default:
//       return state;
//   }
// };

// export default cartReducer;

// export const addingCart = (cart) => {
//   return { type: "cart/cartAdding", payload: cart };
// };

// export const deletingCart = (id) => {
//   return { type: "cart/cartDeleting", payload: id };
// };

// export const qtyHandeling = (id, qty) => {
//   return { type: "cart/cartQtyHandeling", payload: { id, qty } };
// };

const initialState = {
  cart: getCartFromLocalStorage(),
};

function getCartFromLocalStorage() {
  const storedCart = sessionStorage.getItem("cart");
  return storedCart ? JSON.parse(storedCart) : [];
}

function saveCartToLocalStorage(cart) {
  sessionStorage.setItem("cart", JSON.stringify(cart));
}

const cartReducer = function (state = initialState, action) {
  switch (action.type) {
    case "cart/cartAdding": {

      console.log("fgbsangbab", state);
      const existingItemIndex = state.cart.findIndex(
        (item) =>
          item.product_id === action.payload.product_id
      );

      let updatedCart;
      if (existingItemIndex !== -1) {
        updatedCart = state.cart.map((item, index) => {
          console.log("fgbsangbabc", item);
          if (index === existingItemIndex) {
            return {
              ...item,
              qty: item.qty + action.payload.qty,
              size: item.size,
              name:item.name,
            };
          }
          return item;
        });
      } else {
        updatedCart = [...state.cart, action.payload];
      }

      console.table(["fgbsangba",state]);
      saveCartToLocalStorage(updatedCart);
      return { ...state, cart: updatedCart };
    }

    case "cart/cartDeleting": {
      const updatedCart = state.cart.filter(
        (item) => item.product_id !== action.payload
      );
      saveCartToLocalStorage(updatedCart);
      return {
        ...state,
        cart: updatedCart,
      };
    }

    case "cart/cartQtyHandeling": {
      const updatedCart = state.cart.map((item) => {
        if (
          item.product_id === action.payload.id &&
          item.size === action.payload.size
        ) {
          return {
            ...item,
            qty: action.payload.qty,
          };
        } else {
          return item;
        }
      });
      saveCartToLocalStorage(updatedCart);
      return {
        ...state,
        cart: updatedCart,
      };
    }

    default:
      return state;
  }
};

export default cartReducer;

export const addingCart = (cart) => {
  return { type: "cart/cartAdding", payload: cart };
};

export const deletingCart = (id) => {
  return { type: "cart/cartDeleting", payload: id };
};

export const qtyHandeling = (id, size, qty) => {
  return { type: "cart/cartQtyHandeling", payload: { id, size, qty } };
};
