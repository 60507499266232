const initialState = {
  isAuthenticated: false,
  user: {},
  address: {},
  cart: [],
  search :""
};

const userReducer = function (state = initialState, action) {
  switch (action.type) {
    case "user/userAuthenticate":
      return { ...state, isAuthenticated: action.payload };
    case "user/userVerify":
      return { ...state, user: action.payload };
    case "user/userBillingAddress":
      return { ...state, address: action.payload };
    case "user/userAddCart":
      return { ...state, cart: action.payload };
    case "user/searchQuery":
      return { ...state, search: action.payload };

    default:
      return state;
  }
};

export default userReducer;

export const userAuthenticate = (value) => {
  return { type: "user/userAuthenticate", payload: value };
};

export const userVerify = (value) => {
  return { type: "user/userVerify", payload: value };
};

export const userBillingAddress = (value) => {
  return { type: "user/userBillingAddress", payload: value };
};

export const userAddCart = (value) => {
  return { type: "user/userAddCart", payload: value };
};

export const userSearchQuery = (value) => {
  return { type: "user/searchQuery", payload: value };
};
