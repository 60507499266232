import "./Arsia.css";
import facebook from "./image/favebook.svg";
import insta from "./image/insta.svg";
import linked7 from "./image/linked7.png";
import pint from "./image/pint.svg";
import { Link } from "react-router-dom";
import insta6 from "./image/insta6.svg";
import face7 from "./image/face7.svg";
import linkedin9 from "./image/linkedin9.png";
import vector7 from "./image/vector7.svg";
import vector9 from "./image/vector9.svg";
import visa from "./image/visa.svg";
import upi8 from "./image/upi8.svg";
import { useEffect, useState } from "react";
import axios from "axios";

export default function ArsiaFooter() {
  const [contactData, setContactData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://admin.arshiyasehgal.com/API/Web/getContact"
        );
        setContactData(response.data.data[0]);
        console.log("hkjhwhuyrtuthqwt", response.data.data[0]);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  console.log(contactData);

  const handleImageClick = (socialMediaUrl) => {
    window.location.href = socialMediaUrl;
  };
  return (
    <div>
      <div className="Arsia--footer--design">
        <div className="Arsia--footer--design--1">
          <div className="Arsia--footer--design--1--card1">
            <div className="Arsia--footer--design--1--card1--1">
              <p className="Arsia--footer--heading">Essentials</p>

              <Link to={"/"} className="anchor--arshiya">
                <p className="Arsia--footer--heading--1">Home</p>
              </Link>
              <Link to={"/collection"} className="anchor--arshiya">
                <p className="Arsia--footer--heading--1">Collection</p>
              </Link>

              <Link to={"/About"} className="anchor--arshiya">
                <p className="Arsia--footer--heading--1">About Us</p>
              </Link>
              <Link to={"/Contact"} className="anchor--arshiya">
                <p className="Arsia--footer--heading--1">Contact Us</p>
              </Link>
            </div>
            <div className="Arsia--footer--design--1--card1--1">
              <p className="Arsia--footer--heading">Quick Links</p>

              <Link to={"/privacy-policy"} className="anchor--arshiya">
                <p className="Arsia--footer--heading--1">Privacy Policy</p>
              </Link>
              <Link to={"/return-and-exchange"} className="anchor--arshiya">
                <p className="Arsia--footer--heading--1">Returns & Exchange</p>
              </Link>

              <Link to={"/T&C"} className="anchor--arshiya">
                <p className="Arsia--footer--heading--1">Terms & Conditions</p>
              </Link>
              <Link to={"/ShippingAddress"} className="anchor--arshiya">
                <p className="Arsia--footer--heading--1">Shipping Policy</p>
              </Link>
            </div>
          </div>
          <div className="Arsia--footer--design--1--card2">
            <div className="Arsia--footer--design--1--card1--1">
              <p className="Arsia--footer--heading">Contact Us</p>
              <p className="Arsia--footer--heading--1">
                arshiyasehgal40@gmail.com
              </p>
              {/* <p className='Arsia--mobile--view--footer--p1'>order@arshiyasehgal.com</p> */}
              <div className="Arsia--footer--design--1--card1--1--3">
                <p className="Arsia--footer--heading--1">+91 9810889012</p>
              </div>
              <p className="Arsia--footer--heading--1">
                54, Block C, Sector 40, Noida, Uttar Pradesh 201303
              </p>
              <div className="Arsia--image--all">
                <a href={contactData.fb}>
                  <img
                    src={face7}
                    alt="socialImage"
                    style={{ cursor: "pointer" }} // Optional: to show pointer cursor on hover
                    // onClick={handleImageClick(contactData.fb)}
                  ></img>
                </a>
                <a href={contactData.linkedin}>
                  <img
                    className="Arsia--footer--linkedin"
                    src={linkedin9}
                    alt="socialImage"
                    style={{ cursor: "pointer" }} // Optional: to show pointer cursor on hover
                    // onClick={handleImageClick(contactData.linkedin)}
                  ></img>
                </a>
                {/* <Link to={"https://www.instagram.com/arshiyasehgalofficial/"}> */}
                <a href={contactData.insta}>
                  <img
                    src={insta6}
                    alt="socialImage"
                    style={{ cursor: "pointer" }} // Optional: to show pointer cursor on hover
                    // onClick={handleImageClick(contactData.insta)}
                  ></img>
                </a>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
        <div className="Arsia--footer--border--line"></div>
        <div className="Arsia--footer--view--9">
          <div className="Arsia--footer--copywrite--design">
            <p>
              © 2023 Arshiya Sehgal, All Rights Reserved. Made with ❤️ by We
              Reach{" "}
            </p>
          </div>
          <div className="Arsia--mobile--view--footer--insta">
            <img className="footer_icons_Payment" src={vector7}></img>
            <img className="footer_icons_Payment" src={vector9}></img>
            <img className="footer_icons_Payment" src={visa}></img>
            <img className="footer_icons_Payment" src={upi8}></img>
          </div>
        </div>
      </div>
    </div>
  );
}
