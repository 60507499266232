import './Arsia.css';
import React, { useState } from 'react';
import Modal from 'react-modal';
import cross from './image/cross.svg';
import sizechart from './image/sizechart.jpeg';
import intersizechart from './image/intersizechart.jpeg';

export default function ArsiaSizePopup()
{
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const [isModalOpen1, setIsModalOpen1] = useState(false);

    const openModal1 = () => {
        setIsModalOpen1(true);
    };
    const closeModal1 = () => {
        setIsModalOpen1(false);
    };

    return(
        <div>
            <div className='Arsia--size--chart--popup'>
            <p className='Arsia--cart--size--usa--card--p--size' onClick={openModal}>Size Chart</p>
            <p className='Arsia--cart--size--usa--card--p--size' onClick={openModal1}>INTL Size Chart</p>
            </div>
              {/* <div className='Arsia--cart--size--usa--card'>
                                <p className='Arsia--cart--size--usa--card--p'>Size</p>
                                <div className='Arsia--cart--size--usa--card--1'>
                                    <p>US Size</p>
                                </div>
                            </div>
                            <div className='Arsia--cart--size--usa--button'>
                                <button>US 5.5</button>
                                <button>US 6</button>
                                <button>US 7</button>
                                <button>US 7.5</button>
                                <button>US 8</button>
                                <button>US 8.5</button>
                                <button>US 9</button>
                                <button>US 10</button>
                            </div> */}

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Video Modal"
                className="custom-modal--size"
                style={{
                    overlay: {
                        width: '100%',
                        height:'100%',
                        background: 'rgba(0,0,0,0.6)',
                        overflow: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        outline: 'none',
                    }
                }}
            >

<div className='Arsia--pop--img'>
                <button className='arsia--video--cross-button--size' onClick={closeModal}><img src={cross}></img></button>
              
                   <img src={sizechart}></img>
                </div>
            </Modal>
            <Modal
                isOpen={isModalOpen1}
                onRequestClose={closeModal1}
                contentLabel="Video Modal"
                className="custom-modal--size"
                style={{
                    overlay: {
                        width: '100%',
                        height:'100%',
                        background: 'rgba(0,0,0,0.6)',
                        overflow: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        outline: 'none',
                    }
                }}
            >

                <div className='Arsia--pop--img'>
                <button className='arsia--video--cross-button--size' onClick={closeModal1}><img src={cross}></img></button>
              
                   <img src={intersizechart}></img>
                </div>
            </Modal>
        </div>
    );

}