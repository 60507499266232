/* eslint-disable jsx-a11y/img-redundant-alt */

import { useParams } from 'react-router-dom';
import React, { useState, useRef, useEffect } from "react";
import "./Arsia.css";
import ArsiaHeader from "./ArsiaHeader";
import { Link } from "react-router-dom";
import ArsiaFooter from "./ArsiaFooter";
import ArsiaHeaderMobile from "./Arsia-header-mobile";
import FooterMobile from "./Arsia--footer--mobile";
import limitedmobile from "./image/limitedmobile.jpg";
import limitedweb1 from "./image/limitedweb1.png";
import { IMAGE_PATH } from "../utils/constant";
import { priceFormat } from '../utils/helper';

const CollectionProducts = () => {
  const { name } = useParams();
  const [collectionData, setCollectionData] = useState(null);
  const [collectionDataImage, setCollectionDataImage] = useState({});

  console.log(collectionData);

  const priceStyle = {
    textDecoration: "line-through",
    color: "#888",
    marginLeft: "3px",
  };

  const discountPriceStyle = {
    color: "black",
    // fontWeight: "bold",
  };

  useEffect(() => {
    const fetchCollectionData = async () => {
      try {
        // Create a FormData object and append the collection name
        const formData = new FormData();
        formData.append("collection_name", decodeURIComponent(name));

        // Make the POST request
        const response = await fetch(
          "https://admin.arshiyasehgal.com/API/Web/collectionWiseProduct",
          {
            method: "POST",
            body: formData,
          }
        );

        // Parse the response as JSON
        const data = await response.json();
        console.log(data)
        setCollectionData(data.data);
        setCollectionDataImage(data.collection);
      } catch (error) {
        console.error("Error fetching collection data:", error);
      }
    };

    fetchCollectionData();
  }, [name]);

  console.log(collectionData);
  return (
    <div>
      <div className="Arsia--website--design">
        <div className="Arsia--header">
          <p>Orders at Arshiya Seghal are packed with love. </p>
        </div>
        <ArsiaHeader />
        <div className="Arsia--banner--1">
          <img
            alt="imGE"
            src={`${IMAGE_PATH}${collectionDataImage.page_desktop_img}`}
          ></img>
        </div>
        <div className="Arsia--collection--banner--below--content">
          <p className="Arsia--collection--banner--below--content--p1">
            Flaunt The Signature Styles.
          </p>
        </div>

        <div className="Arsia--collection--below--content--part">
          <div className="Arsia--collection--all-cards--1">
            {collectionData?.map((product) => (
              <Link
                to={`/${product.name}/${product.id}`}
                className="arsia--product--link"
              >
                <div className="Arsia--below--content--card--carousal--1--collection">
                  <img
                    style={{
                      height: "500px",

                      objectFit: "cover", // Ensures the image covers the given height without stretching
                    }}
                    src={`${IMAGE_PATH}${product.images[0].img}`}
                    alt="CollectionProductsImage"
                  ></img>
                  <div className="Arsia--below--content--card--carousal--1--price">
                    <p className="Arsia--below--content--card--carousal--1--price--p1--2">
                      {product.name}
                    </p>
                    {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                    {
                      product.discount === "0" ?
                        <p className="Arsia--below--content--card--carousal--1--price--p1--2">
                          <span style={discountPriceStyle}>
                            {priceFormat(+product.price)}
                          </span>
                        </p>
                        :
                        <p className="Arsia--below--content--card--carousal--1--price--p1--2">
                          <span style={discountPriceStyle}>
                            {priceFormat(+product.price)}
                          </span>
                          {"  "}
                          <span style={priceStyle}>
                            {priceFormat(+product.mrp)}
                          </span>{" "}
                          <span style={{ color: "#E74C3C", marginLeft: "3px" }}>
                            {product.discount}% OFF
                          </span>
                        </p>
                    }

                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>

        <ArsiaFooter />
      </div>

      {/* Arsia--Mobile--view */}
      <div className="Arsia--website--design--mobile--view">
        <ArsiaHeaderMobile />
        <div className="Arsia--banner--1">
          <img
            alt="image"
            src={`${IMAGE_PATH}${collectionDataImage.page_mobile_img}`}
          />
        </div>
        <div className="Arsia--collection--banner--below--content">
          <p className="Arsia--collection--banner--below--content--p1">
            Flaunt The Signature Styles.
          </p>
        </div>

        <div className="">
          {/* <h3
              style={{ marginTop: "14px" }}
              className="Arsia--below--content--card--carousal--1--price--p1--2"
            >
              Collections
            </h3> */}
          <div className="Arsia--collection--all-cards--1">
            {collectionData?.map((product) => (
              <Link
                to={`/${product.name}/${product.id}`}
                className="arsia--product--link"
              >
                <div className=" Arsia--below--content--card--carousal--1--collection--page--8">
                  <img
                    src={`${IMAGE_PATH}${product.images[0].img}`}
                    alt="CollectionProductsImage"
                  ></img>
                  <div className="Arsia--below--content--card--carousal--1--price">
                    <p className="Arsia--below--content--card--carousal--1--price--p1--2">
                      {product.name}
                    </p>
                    {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                    {
                      product.discount === "0" ?
                        <p className="Arsia--below--content--card--carousal--1--price--p1--2">
                          <span style={discountPriceStyle}>
                            {priceFormat(+product.price)}
                          </span>
                        </p>
                        :
                        <p className="Arsia--below--content--card--carousal--1--price--p1--2">
                          <span style={discountPriceStyle}>
                            {priceFormat(+product.price)}
                          </span>
                          {"  "}
                          <span style={priceStyle}>
                            {priceFormat(+product.mrp)}
                          </span>{" "}
                          <span style={{ color: "#E74C3C", marginLeft: "3px" }}>
                            {product.discount}% OFF
                          </span>
                        </p>
                    }

                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>

        <FooterMobile />
      </div>
      {/* Arsia--Mobile--view--end */}
    </div>
  );
}

export default CollectionProducts
