import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteCart as apiDeleteCart } from "../../services/apiCart";

export const useDeleteCart = () => {
  const queryClient = useQueryClient();

  const { mutate: deleteCart, isPending: isdeleting } = useMutation({
    mutationFn: (id) => apiDeleteCart(id),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["cart"],
      });
    },
    
    onError: (err) => {
      console.log(err);
    },
  });

  return { deleteCart, isdeleting };
};
