import "./Arsia.css";
import axios from "axios";
import React, { useState, useRef, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import icon1 from "./image/icon1.svg";
import icon3 from "./image/icon3.svg";
import { Link, useNavigate } from "react-router-dom";
import heart from "./image/heart.png";
import search1 from "./image/search1.png";
import cross from "./image/cross.svg";
import Aslogo from "./image/Aslogo.png";
import navbar from "./image/navbar.svg";
import back from "./image/back.png";
import footerlogo from "./image/footerlogo.png";
import { useForm } from "react-hook-form";
import { useLogin } from "../features/user/useLogin";
import { useOtpVerify } from "../features/user/useOtpVerify";
import { useGetCart } from "../features/cart/useGetCart";
import {
  userAddCart,
  userAuthenticate,
  userSearchQuery,
} from "../features/user/userSlice";
import { useSignUp } from "../features/user/useSignUp";
import AccountMenu from "../ui/AccountMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

export default function ArsiaHeaderMobile({ search, handleSearch }) {
  const { login, isLogin } = useLogin();
  const { signUp, isSignUp } = useSignUp();
  const { verifyOtp, isVerify } = useOtpVerify();
  const [otpError, setOtpError] = useState("");
  const { cart, total } = useGetCart();
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  // const [searchQuery,setSearchQuery] = useState("");
  const handleSearchChange = (event) => {
    dispatch(userSearchQuery(event.target.value));
  };

  const handleOnFocus = () => {
    navigate("/search-results");
  };

  useEffect(() => {
    const isAuthenticate = localStorage.getItem("isAuthenticate");
    if (isAuthenticate === "true") {
      dispatch(userAuthenticate(true));
    } else {
      dispatch(userAuthenticate(false));
    }

    //add cart globally
    if (!cart) return;
    dispatch(userAddCart({ cart, total }));
  }, [dispatch, cart, total]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();
  const [isSearchCardOpen, setIsSearchCardOpen] = useState(false);
  const handleSearchIconClick = () => {
    setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
  };

  useEffect(() => {
    if (isSearchCardOpen) {
      inputRef.current.focus();
    }
  }, [isSearchCardOpen]);


  const handleArrowLeftClick = () => {
    setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
  };
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [state, setState] = React.useState({
    right: false,
  });
  const [showLogin, setShowLogin] = useState(true);
  const [isOtp, setIsOtp] = useState(false);

  const showSidebar = () => {
    setSidebarVisible(true);
  };

  const hideSidebar = () => {
    setSidebarVisible(false);
    var btn = document.getElementById('div_dropdowns')
    btn.classList.toggle("class_hide")
  };


  const handleOpenSignup = () => {
    setShowLogin(false);
  };

  const signUpHandler = (data) => {
    const newData = { ...data, name: data.firstName + " " + data.lastName };
    signUp(newData, {
      onSuccess: () => {
        reset();
        setShowLogin(true);
        setIsOtp(true);
      },
    });
    // setShowLogin(!showLogin);
    // reset();
  };

  const signInHandler = (data) => {
    login(data, {
      onSuccess: () => {
        reset();
        setIsOtp(true);
      },
    });
  };
  const add_remove_class = () => {
    var btn = document.getElementById('div_dropdowns')
    btn.classList.toggle("class_hide")
  }
  const otpSubmitHandler = (data) => {
    verifyOtp(data, {
      onSuccess: () => {
        reset();
        setOtpError("");
        setState(false);
        setIsOtp(false);
        window.location.href = "/allproducts";
      },
      onError: (error) => {
        setOtpError("Invalid OTP");
      },
    });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const closeHandler = () => {
    setState(false);
    setIsOtp(false);
    setShowLogin(true);
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : "100%",
        height: "100%",
        background: "#000",
        color: "#fff",
      }}
      role="presentation"
    >
      <List>
        <ListItem disablePadding dense>
          <ListItemButton onClick={(e) => e.stopPropagation()}>
            <ListItemText
              primary={
                <li>
                  <img
                    onClick={closeHandler}
                    src="/images/close-square.svg"
                    alt="closeIcon"
                  />
                  <div>
                    {showLogin ? (
                      <div className="Arsia--login--pop--up">
                        <div className="Arsia--login--pop--up--1--p">
                          <p className="Arsia--login--pop--up--1--p--1">
                            CLOTHES ARE YOU!
                          </p>
                          <p className="Arsia--login--pop--up--1--p--2">
                            Login to your AS Label account
                          </p>
                        </div>
                        {!isOtp && (
                          <form onSubmit={handleSubmit(signInHandler)}>
                            <div className="Arsia--login--input--1">
                              <div className="Arsia--login--input">
                                <div className="Arsia--login--input-1">
                                  <span>
                                    <img
                                      src="/images/phoneCall.svg"
                                      alt="img"
                                    ></img>
                                  </span>
                                  <input
                                    type="text"
                                    placeholder="Mobile Number"
                                    {...register("mobile", {
                                      required:
                                        "Mobile number must be required.",
                                    })}
                                  ></input>
                                </div>
                                <div className="Arsia--login--input-1--border--line"></div>
                                <span>
                                  {errors?.mobile?.message && (
                                    <span>{errors?.mobile?.message}</span>
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="Arsia--login--continue">
                              <button disabled={isLogin}>
                                {isLogin ? <p>Loading...</p> : <p>Get OTP</p>}
                              </button>
                            </div>
                            {/* <div className="Arsia--seaprator--box">
                              <img src="/images/seaprator.png" alt="img" />
                            </div>
                            <div className="Arsia--login--option--box">
                              <img src="/images/google.svg" alt="img" />
                              <img src="/images/fb.svg" alt="img" />
                              <img src="/images/apple.svg" alt="img" />
                            </div> */}
                          </form>
                        )}

                        {isOtp && (
                          <form onSubmit={handleSubmit(otpSubmitHandler)}>
                            <div className="Arsia--login--input--1">
                              <div className="Arsia--login--input">
                                <div className="Arsia--login--input-1">
                                  <span></span>
                                  <input
                                    type="text"
                                    placeholder="Enter OTP"
                                    {...register("otp", {
                                      required: "OTP must be required.",
                                    })}
                                  ></input>
                                </div>
                                <div className="Arsia--login--input-1--border--line"></div>
                                <span>
                                  {errors?.otp?.message && (
                                    <span>{errors?.otp?.message}</span>
                                  )}
                                </span>
                                <span>
                                  {!errors?.otp?.message && otpError && (
                                    <span>{otpError}</span>
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="Arsia--login--continue">
                              <button disabled={isVerify}>
                                {isVerify ? <p>Loading...</p> : <p>Submit</p>}
                              </button>
                            </div>
                          </form>
                        )}
                      </div>
                    ) : (
                      <div>
                        <form onSubmit={handleSubmit(signUpHandler)}>
                          <div className="Arsia--login--signup--page">
                            <p>Signup</p>

                            <div className="Arsia--login--signup--page1">
                              <div className="Arsia--login--signup--page--2--input--1--6">
                                <input
                                  type="text"
                                  placeholder="Mobile number..."
                                  {...register("mobileNumber", {
                                    required: "Mobile number must be required.",
                                  })}
                                ></input>
                                <div className="Arsia--cart--border--line--login"></div>
                                <span>
                                  {errors?.mobileNumber?.message && (
                                    <span>{errors?.mobileNumber?.message}</span>
                                  )}
                                </span>
                              </div>
                              <div className="Arsia--login--signup--page--2--input">
                                <div className="Arsia--login--signup--page--2--input--1">
                                  <input
                                    type="text"
                                    placeholder="First Name"
                                    {...register("firstName", {
                                      required: "First name must be required.",
                                    })}
                                  ></input>
                                  <div className="Arsia--cart--border--line--login"></div>
                                  <span>
                                    {errors?.firstName?.message && (
                                      <span>{errors?.firstName?.message}</span>
                                    )}
                                  </span>
                                </div>
                                <div className="Arsia--login--signup--page--2--input--1">
                                  <input
                                    type="text"
                                    placeholder="Last Name"
                                    {...register("lastName")}
                                  ></input>
                                  <div className="Arsia--cart--border--line--login"></div>
                                </div>
                              </div>
                              <div className="Arsia--login--signup--page--2--input--1--6">
                                <input
                                  type="email"
                                  placeholder="Email Address"
                                  {...register("email", {
                                    required: "Email address must be required.",
                                  })}
                                ></input>
                                <div className="Arsia--cart--border--line--login"></div>
                                <span>
                                  {errors?.email?.message && (
                                    <span>{errors?.email?.message}</span>
                                  )}
                                </span>
                              </div>

                              <div className="Arsia--header--get--otp">
                                {/* <p>Get OTP</p> */}

                                {/* <button
                                  type="button"
                                  onClick={handleContinueButtonClick}
                                >
                                  Continue
                                </button> */}
                                <button type="submit">
                                  {isSignUp ? "Loading..." : "Get OTP"}
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </li>
              }
            />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const [data, setData] = useState({});
  useEffect(() => {
    axios
      .get("https://admin.arshiyasehgal.com/API/Homepage/getHomepage")
      .then((response) => {
        // Check if data is present before updating state
        if (response.data.status && response.data.data.length > 0) {
          setData(response.data.data[0]);
        }
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <div>
      <div className="Arsia--header--1">
        <div className="Arsia--header">
          {data && data.top_header_title && <p>{data.top_header_title}</p>}
        </div>
      </div>
      {isSearchCardOpen ? (
        <div className="Arsia--Navbar--search--1">
          <img
            className="Arsia--Navbar--search--1--back"
            src={back}
            alt="Arrow Left"
            onClick={handleArrowLeftClick}
          ></img>

          <div className="Arsia--Navbar--search--1--2">
            <input
              type="text"
              ref={inputRef}
              placeholder="Search..."
              value={search}
              onChange={handleSearchChange}
              onFocus={handleOnFocus}
              className="Arsia--search--input"
            ></input>
            <img src={search1} alt="Search"></img>
          </div>
        </div>
      ) : (
        <div className="Arsia--Navbar">
          <div className="Arsia--Navbar-1">
            <div className="Arsia--Navbar-1--8">
              <div>
                <img
                  className="Arsia--Navbar-1--img1"
                  onClick={showSidebar}
                  src={navbar}
                  alt="Navbar"
                />
                <nav>
                  <ul className={`sidebar ${sidebarVisible ? "visible" : ""}`}>
                    <li>
                      <div className="Arsia--ham--profile">
                        <div className="Arsia--ham--profile--p">
                          <img src={footerlogo}></img>
                        </div>
                        <img
                          className="Arsia--ham--profile--img--cross"
                          onClick={hideSidebar}
                          src={cross}
                          alt="Close"
                        />
                      </div>
                    </li>
                    <li>
                      <div className="Arsia--ham--content">
                        <div className="Arsia--ham--content1">
                          <div className="Arsia--ham--content--1">
                            {/* <img src={home}></img> */}
                            <Link to={"/"} className="arsia--product--link">
                              <p>Home</p>
                            </Link>
                          </div>
                          <div className="Arsia--ham--content--border--line"></div>
                          <div className="Arsia--ham--content--1">
                            {/* <img src={collection}></img> */}
                            <Link
                              to={"/allproducts"}
                              className="arsia--product--link"
                            >
                              <p>All Products</p>
                            </Link>
                            
                          </div>
                          <div className="Arsia--ham--content--border--line"></div>
                            <button className="mobile_menu_select dropdown-toggle" onClick={add_remove_class}>
                              Shop By Category
                            </button>
                          <div id="div_dropdowns" className="mobile_menu_dropdowns class_hide">
                            <ul className="search_by_category_ul">
                              <li className="search_by_category_li"><Link className="option_select" to={"/Collections/co-ord-sets"} onClick={hideSidebar}>Co-ord Sets
                              </Link></li>
                              <li className="search_by_category_li"><Link className="option_select" to={"/Collections/dresses"} onClick={hideSidebar}>Dresses</Link></li>
                            </ul>
                          </div>
                          <div className="Arsia--ham--content--border--line"></div>
                          <Link
                            to={"/Collections"}
                            className="arsia--product--link"
                          >
                            <div className="Arsia--ham--content--1">
                              {/* <img src={favorite}></img> */}
                              <p>Collections</p>
                            </div>
                          </Link>
                          <div className="Arsia--ham--content--border--line"></div>
                          <Link
                            to={"/Collections/new-arrivals"}
                            className="arsia--product--link"
                          >
                            <div className="Arsia--ham--content--1">
                              {/* <img src={favorite}></img> */}
                              <p>New Arrivals</p>
                            </div>
                          </Link>

                        </div>

                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
              <Link to={"/"} className="arsia--product--link">
                <img
                  className="Arsia--Navbar-img"
                  src={Aslogo}
                  alt="Logo"
                ></img>
              </Link>
            </div>
          </div>
          <div className="Arsia--icon1">
            {/* {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <img
                  alt="user"
                  src={icon1}
                  onClick={toggleDrawer(anchor, true)}
                ></img>

                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))} */}
            {!isAuthenticated ? (
              ["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <img
                    alt="img"
                    src={icon1}
                    onClick={toggleDrawer(anchor, true)}
                  ></img>

                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))
            ) : (
              <AccountMenu />
            )}
            {/* <img src={heart} alt="Heart"></img> */}
            <img
              src={search1}
              alt="Search"
              onClick={handleSearchIconClick}
            ></img>
            <Link to={"/Cart"}>
              <img src={icon3} alt="cart"></img>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
