import { objectToFormData } from "../utils/helper";

export const orderPlace = async (order) => {
  const token = localStorage.getItem("authToken");
  const myHeaders = new Headers();

  const formdata = objectToFormData({ ...order, Authorization: token });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      "https://admin.arshiyasehgal.com/API/User/placeOrder",
      requestOptions
    );
    const data = await res.json();
    
    if (data.status !== true && data.message !== "Data not found")
      throw new Error(data.message);
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const orderCheckout = async (order) => {
  const token = localStorage.getItem("authToken");

  const myHeaders = new Headers();

  const formdata = objectToFormData({ ...order, Authorization: token });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      "https://admin.arshiyasehgal.com/API/User/checkoutOrder",
      requestOptions
    );
    const data = await res.json();

     console.log("Order Place API Response:", data); 
    
    if (data.status !== true && data.message !== "Data not found")
      throw new Error(data.message);
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};
