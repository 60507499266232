import "./Arsia.css";
import React, { useState } from "react";
import grid from "./image/grid.svg";
import Menu from "./image/Menu.svg";
import sortclose from "./image/sortclose.svg";
import asup from "./image/asup.png";
import asdown from "./image/asdown.png";
import assearch1 from "./image/assearch1.svg";
import ArsiaCollectionGridView from "./Arsia--collection--grid--view";
import ArsiaCollectionListView from "./Arsia--collection--list--view";
import { useGetProducts } from "../features/product/useGetProducts";

export default function ArsiaSortFilter({search, productFilterData }) {
  const products = useGetProducts(search)
  // console.log(productFilterData,"jhfjafhagjhagha");
  const productData =  products;

  console.log(products,"hfajghaaasgas")

  const [isSortContentVisible, setSortContentVisible] = useState(false);
  const [isFilterContentVisible, setFilterContentVisible] = useState(false);
  const toggleSortContent = () => {
    setSortContentVisible(!isSortContentVisible);
  };

  const handleClosePopup = () => {
    setSortContentVisible(false);
  };
  const toggleSortContent1 = () => {
    setFilterContentVisible(!isFilterContentVisible);
  };

  const handleClosePopup1 = () => {
    setFilterContentVisible(false);
  };
  //    Filter  Categories start
  const [isContentVisibleprice, setContentVisibleprice] = useState(false);

  const toggleContentVisibilityPrice = () => {
    setContentVisibleprice(!isContentVisibleprice);
  };
  const [isContentVisible, setContentVisible] = useState(false);

  const toggleContentVisibility = () => {
    setContentVisible(!isContentVisible);
  };
  const [isContentVisibleSize, setContentVisibleSize] = useState(false);

  const toggleContentVisibilitySize = () => {
    setContentVisibleSize(!isContentVisibleSize);
  };
  const [isContentVisibleColor, setContentVisibleColor] = useState(false);

  const toggleContentVisibilityColor = () => {
    setContentVisibleColor(!isContentVisibleColor);
  };

  // price--filter--code--start
  const [priceValues, setPriceValues] = useState([0, 14000]);
  const [priceGap] = useState(1000);

  const handlePriceInputChange = (e, inputIndex) => {
    const value = parseInt(e.target.value);
    const newPriceValues = [...priceValues];
    newPriceValues[inputIndex] = value;

    if (
      newPriceValues[1] - newPriceValues[0] >= priceGap &&
      newPriceValues[1] <= 20000
    ) {
      setPriceValues(newPriceValues);
    }
  };

  const sliderProgressStyle = {
    left: `${((priceValues[0] / 20000) * 100).toFixed(2)}%`,
    right: `${((1 - priceValues[1] / 20000) * 100).toFixed(2)}%`,
  };

  const rangeInputStyle = { width: "100%" };

  // price--filter--code--end

  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isGridActive, setIsGridActive] = useState(false);

  const handleMenuClick = () => {
    // Set the state to true when the menu is clicked
    setIsMenuActive(true);
    setIsGridActive(false);
  };

  const handleGridClick = () => {
    // Set the state to true when the grid is clicked
    setIsMenuActive(false);
    setIsGridActive(true);
  };
  return (
    <div>
      <div className="Arsia--collection--below--content--filter">
        <div className="Arsia--collection--below--content--filter--1">
          <img src={Menu} onClick={handleMenuClick}></img>
          <img src={grid} onClick={handleGridClick}></img>
          <div className="Arsia--collection--border-line"></div>
        </div>

        {/* <div className="Arsia--collection--below--content--filter--2">
          <div>
            <div
              className="Arsia--collection--below--content--filter--2--sort--by"
              onClick={toggleSortContent}
            >
              <p>Sort By: Featured</p>
            </div>
            {isSortContentVisible && (
              <div className="Arsia--sort--content">
                <div className="Arsia--sort--content--1">
                  <p>Sort By</p>
                  <img
                    src={sortclose}
                    alt="Sort Close"
                    onClick={handleClosePopup}
                  />
                </div>
                <div className="Arsia--border--sort--line"></div>
                <div className="Arsia--sort--price">
                  <p>Price: High To Low</p>
                </div>
                <div className="Arsia--border--sort--line"></div>
                <div className="Arsia--sort--price">
                  <p>Price: Low To High</p>
                </div>
              </div>
            )}
          </div>
          <div>
            <div
              className="Arsia--collection--below--content--filter--2--feature"
              onClick={toggleSortContent1}
            >
              <p>Filter</p>
            </div>
            {isFilterContentVisible && (
              <div className="Arsia--sort--content--2">
                <div className="Arsia--sort--content--1">
                  <p>Filter</p>
                  <img
                    src={sortclose}
                    alt="Sort Close"
                    onClick={handleClosePopup1}
                  />
                </div>
                <div className="Arsia--border--sort--line"></div>
                <div>
                  <div
                    className="Arsia--sort--price1"
                    onClick={toggleContentVisibilityPrice}
                  >
                    <p>Price</p>
                    <div>
                      <img
                        src={isContentVisibleprice ? asup : asdown}
                        alt="Arrow"
                      />
                    </div>
                  </div>
                  {isContentVisibleprice && (
              <div className="Arsia--sort--price1--content">
              <div className="Arsia--sort--price1--content--price">
                <div className="Arsia--price--filter">
                  <p>From</p>
                  <input
                    type="number"
                    value={priceValues[0]}
                    onChange={(e) => handlePriceInputChange(e, 0)}
                  />
                </div>
                <div className="Arsia--price--filter">
                  <p>To</p>
                  <input
                    type="number"
                    value={priceValues[1]}
                    onChange={(e) => handlePriceInputChange(e, 1)}
                  />
                </div>
              </div>
        
              <div className="arsia--price--slider">
                <div className="arsia--price--progress" style={sliderProgressStyle}></div>
              </div>
        
              <div className="arsia--range--input">
                <input
                  type="range"
                  className="arsia--range--min--input"
                  min="0"
                  max="20000"
                  step="100"
                  value={priceValues[0]}
                  style={rangeInputStyle}
                  onChange={(e) => handlePriceInputChange(e, 0)}
                />
                <input
                  type="range"
                  className="arsia--range--max--input"
                  min="0"
                  max="20000"
                  step="100"
                  value={priceValues[1]}
                  style={rangeInputStyle}
                  onChange={(e) => handlePriceInputChange(e, 1)}
                />
              </div>
            </div>
                  )}
                </div>



                <div className="Arsia--border--sort--line"></div>
                <div>
                  <div
                    className="Arsia--sort--price1"
                    onClick={toggleContentVisibility}
                  >
                    <p>Category</p>
                    <div>
                      <img src={isContentVisible ? asup : asdown} alt="Arrow" />
                    </div>
                  </div>
                  {isContentVisible && (
                    <div className="Arsia--sort--price1--content">
                      <div className="Arsia-sort-search--4">
                        <img src={assearch1}></img>
                        <input
                          type="text"
                          placeholder="search"
                          className="Arsia-category-search"
                        ></input>
                      </div>
                      <div className="Arsia--sort--category">
                        <input
                          type="checkbox"
                          className="Arsia-category-checkbox"
                        ></input>
                        <p>Suit</p>
                      </div>

                      <div className="Arsia--sort--category">
                        <input
                          type="checkbox"
                          className="Arsia-category-checkbox"
                        ></input>
                        <p>
                          Sarees <span>125</span>{" "}
                        </p>
                      </div>
                      <div className="Arsia--sort--category">
                        <input
                          type="checkbox"
                          className="Arsia-category-checkbox"
                        ></input>
                        <p>
                          Dresses <span>68</span>
                        </p>
                      </div>
                      <div className="Arsia--sort--category">
                        <input
                          type="checkbox"
                          className="Arsia-category-checkbox"
                        ></input>
                        <p>
                          Kurtas <span>44</span>
                        </p>
                      </div>
                      <div className="Arsia--sort--category">
                        <input
                          type="checkbox"
                          className="Arsia-category-checkbox"
                        ></input>
                        <p>
                          Lehengas <span>36</span>
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="Arsia--border--sort--line"></div>
                <div>
                  <div
                    className="Arsia--sort--price1"
                    onClick={toggleContentVisibilitySize}
                  >
                    <p>Sizes</p>
                    <div>
                      <img
                        src={isContentVisibleSize ? asup : asdown}
                        alt="Arrow"
                      />
                    </div>
                  </div>
                  {isContentVisibleSize && (
                    <div className="Arsia--sort--price1--content">
                      <div className="Arsia-sort-search--4">
                        <img src={assearch1}></img>
                        <input
                          type="text"
                          placeholder="search"
                          className="Arsia-category-search"
                        ></input>
                      </div>
                      <div className="Arsia--sort--category">
                        <input
                          type="checkbox"
                          className="Arsia-category-checkbox"
                        ></input>
                        <p>Label 1</p>
                      </div>

                      <div className="Arsia--sort--category">
                        <input
                          type="checkbox"
                          className="Arsia-category-checkbox"
                        ></input>
                        <p>Label 2 </p>
                      </div>
                      <div className="Arsia--sort--category">
                        <input
                          type="checkbox"
                          className="Arsia-category-checkbox"
                        ></input>
                        <p>Label 3</p>
                      </div>
                      <div className="Arsia--sort--category">
                        <input
                          type="checkbox"
                          className="Arsia-category-checkbox"
                        ></input>
                        <p>Label 4</p>
                      </div>
                      <div className="Arsia--sort--category">
                        <input
                          type="checkbox"
                          className="Arsia-category-checkbox"
                        ></input>
                        <p>Label 5</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="Arsia--border--sort--line"></div>
                <div>
                  <div
                    className="Arsia--sort--price1"
                    onClick={toggleContentVisibilityColor}
                  >
                    <p>Colors</p>
                    <img
                      src={isContentVisibleColor ? asup : asdown}
                      alt="Arrow"
                    />
                  </div>
                  {isContentVisibleColor && (
                    <div className="Arsia--sort--price1--content">
                      <div className="Arsia-sort-search--4">
                        <img src={assearch1}></img>
                        <input
                          type="text"
                          placeholder="search"
                          className="Arsia-category-search"
                        ></input>
                      </div>
                      <div className="Arsia--sort--category">
                        <input
                          type="checkbox"
                          className="Arsia-category-checkbox"
                        ></input>
                        <p>Label 1</p>
                      </div>

                      <div className="Arsia--sort--category">
                        <input
                          type="checkbox"
                          className="Arsia-category-checkbox"
                        ></input>
                        <p>Label 2 </p>
                      </div>
                      <div className="Arsia--sort--category">
                        <input
                          type="checkbox"
                          className="Arsia-category-checkbox"
                        ></input>
                        <p>Label 3</p>
                      </div>
                      <div className="Arsia--sort--category">
                        <input
                          type="checkbox"
                          className="Arsia-category-checkbox"
                        ></input>
                        <p>Label 4</p>
                      </div>
                      <div className="Arsia--sort--category">
                        <input
                          type="checkbox"
                          className="Arsia-category-checkbox"
                        ></input>
                        <p>Label 5</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div> */}
      </div>
      {isMenuActive && <ArsiaCollectionListView productData={productData} />}
      {isGridActive && <ArsiaCollectionGridView productData={productData} />}

      {!isMenuActive && !isGridActive && (
        <ArsiaCollectionListView productData={productData} />
      )}
    </div>
  );
}
