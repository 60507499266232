import { useQuery } from "@tanstack/react-query";
import { getProduct } from "../../services/apiProduct";

export const useGetProduct = (id) => {
  const { isLoading, error, data } = useQuery({
    queryKey: ["product", id],
    queryFn: () => getProduct(id),
  });

  const { data: product } = data || {};

  return { isLoading, error, product };
};
