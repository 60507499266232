// import React, { useState, useRef, useEffect } from 'react';
// import './Arsia.css';
// import ArsiaHeader from './ArsiaHeader';
// import ascoll from './image/ascoll.png';
// import { Link } from "react-router-dom";
// import ArsiaFooter from './ArsiaFooter';
// import collectionbanner from './image/collectionbanner.jpg';
// import ArsiaHeaderMobile from './Arsia-header-mobile';
// import FooterMobile from './Arsia--footer--mobile';
// import ArsiaSortFilter from './Arsia-sort-filter';
// import { useGetProducts } from "../features/product/useGetProducts";

// export default function ArsiaCollection() {

//   const [isSearchCardOpen, setIsSearchCardOpen] = useState(false);
//   const [searchQuery, setSearchQuery] = useState("");
  
//   const products = useGetProducts();


//     const handleSearchChange = (event) => {
//       setSearchQuery(event.target.value);
//   };
  
//   console.log(products.products, "fgahjfgahfagjhfsag");
  
//   const filteringData = products.products;

//     const filteredProducts = filteringData.filter((product) =>
//           product.name.toLowerCase().includes(searchQuery.toLowerCase())
//         )
  

//   const handleSearchIconClick = () => {
//     setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
//   };

//   const handleArrowLeftClick = () => {
//     setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
//   };
//   const [sidebarVisible, setSidebarVisible] = useState(false);

//   const showSidebar = () => {
//     setSidebarVisible(true);
//   };

//   const hideSidebar = () => {
//     setSidebarVisible(false);
//   };
//   useEffect(() => {
//     // Scroll to the top of the page when navigating to the About page
//     window.scrollTo(0, 0);
//   }, []);

//   return (
//     <div>
//       <div className="Arsia--website--design">
//         <ArsiaHeader handleSearch={handleSearchChange} search={searchQuery} />
//         <div className="Arsia--banner--1--8">
//           <img src={collectionbanner}></img>
//         </div>
//         <div className="Arsia--collection--banner--below--content">
//           <p className="Arsia--collection--banner--below--content--p1">
//             Transitional styles for you.{" "}
//           </p>
//         </div>
//         <div className="Arsia--collection--below--content"></div>
//         <ArsiaSortFilter productFilterData={filteredProducts} />
//         <ArsiaFooter />
//       </div>

//       {/* Arsia--Mobile--view */}
//       <div className="Arsia--website--design--mobile--view">
//         <ArsiaHeaderMobile />
//         <div className="Arsia--banner--1">
//           <img src={ascoll}></img>
//         </div>
//         <div className="Arsia--collection--banner--below--content">
//           <p className="Arsia--collection--banner--below--content--p1">
//             Transitional styles for you.
//           </p>
//         </div>
//         <ArsiaSortFilter />

//         <FooterMobile />
//       </div>
//       {/* Arsia--Mobile--view--end */}
//     </div>
//   );

// }


import React, { useState, useEffect } from "react";
import "./Arsia.css";
import ArsiaHeader from "./ArsiaHeader";
import ascoll from "./image/ascoll.png";
import { Link } from "react-router-dom";
import ArsiaFooter from "./ArsiaFooter";
import collectionbanner from "./image/collectionbanner.jpg";
import ArsiaHeaderMobile from "./Arsia-header-mobile";
import FooterMobile from "./Arsia--footer--mobile";
import ArsiaSortFilter from "./Arsia-sort-filter";
import { useGetProducts } from "../features/product/useGetProducts";

export default function ArsiaCollection() {
  const [isSearchCardOpen, setIsSearchCardOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const { isLoading, error, products } = useGetProducts();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchIconClick = () => {
    setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
  };

  const handleArrowLeftClick = () => {
    setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
  };

  const [sidebarVisible, setSidebarVisible] = useState(false);

  const showSidebar = () => {
    setSidebarVisible(true);
  };

  const hideSidebar = () => {
    setSidebarVisible(false);
  };

  useEffect(() => {
    // Scroll to the top of the page when navigating to the About page
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSearchQuery("");
  },[])

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;



  return (
    <div>
      <div className="Arsia--website--design">
        <ArsiaHeader handleSearch={handleSearchChange} search={searchQuery} />
        <div className="Arsia--banner--1--8">
          <img src={collectionbanner} alt="Collection Banner" />
        </div>
        <div className="Arsia--collection--banner--below--content">
          <p className="Arsia--collection--banner--below--content--p1">
            Transitional styles for you.{" "}
          </p>
        </div>
        <div className="Arsia--collection--below--content"></div>
        <ArsiaSortFilter search={searchQuery} productFilterData={products} />
        <ArsiaFooter />
      </div>

      {/* Arsia--Mobile--view */}
      <div className="Arsia--website--design--mobile--view">
        <ArsiaHeaderMobile  handleSearch={handleSearchChange} search={searchQuery}/>
        <div className="Arsia--banner--1">
          <img src={ascoll} alt="Mobile Banner" />
        </div>
        <div className="Arsia--collection--banner--below--content">
          <p className="Arsia--collection--banner--below--content--p1">
            Transitional styles for you.
          </p>
        </div>
        <ArsiaSortFilter search={searchQuery} productFilterData={products} />
        <FooterMobile />
      </div>
      {/* Arsia--Mobile--view--end */}
    </div>
  );
}
