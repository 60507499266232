import { composeWithDevTools } from "@redux-devtools/extension";
import { createStore, combineReducers } from "redux";

import userReducer from "./features/user/userSlice";
import cartReducer from "./features/cart/cartSlice";

const rootReducer = combineReducers({
  user: userReducer,
  cart: cartReducer,
});

const store = createStore(rootReducer, composeWithDevTools());

export default store;
