import React, { useState, useRef, useEffect } from 'react';
import './Arsia.css';
import ArsiaHeader from './ArsiaHeader';

import { Link } from "react-router-dom";
import ArsiaFooter from './ArsiaFooter';

import laila from './image/laila.jpg';
import blackdrama from './image/blackdrama.jpg';
import khubsurat from './image/khubsurat.jpg';
import ArsiaHeaderMobile from './Arsia-header-mobile';
import FooterMobile from './Arsia--footer--mobile';
import khoobsuratbelow from './image/khoobsuratbelow.jpg';
import fizacoll from './image/fizacoll.jpg';
import ashandmobile from './image/ashandmobile.jpg';

export default function ArsiaHandPicks() {

    const [isSearchCardOpen, setIsSearchCardOpen] = useState(false);

  const handleSearchIconClick = () => {
    setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
  };

  const handleArrowLeftClick = () => {
    setIsSearchCardOpen(!isSearchCardOpen); // Toggle the state
  };
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const showSidebar = () => {
    setSidebarVisible(true);
  };

  const hideSidebar = () => {
    setSidebarVisible(false);
  };
  useEffect(() => {
    // Scroll to the top of the page when navigating to the About page
    window.scrollTo(0, 0);
  }, []);

    return (
        <div>
        <div className='Arsia--website--design'>
            <div className='Arsia--header'>
                <p>Orders at Arshiya  Seghal are packed with love. </p>
            </div>
            <ArsiaHeader/>
            <div className='Arsia--banner--1'>
                <img src={khoobsuratbelow}></img>
            </div>
            <div className='Arsia--collection--banner--below--content'>
                <p className='Arsia--collection--banner--below--content--p1'> More Than Fashion – It's a Mood Revolution</p>
            </div>
            <div className='Arsia--collection--below--content'></div>
            {/* <div className='Arsia--collection--below--content--filter'>
                <div className='Arsia--collection--below--content--filter--1'>
                    <img src={Menu}></img>
                    <img src={grid}></img>
                    <div className='Arsia--collection--border-line'></div>
                </div>
                <div className='Arsia--collection--below--content--filter--2'>
                    <div className='Arsia--collection--below--content--filter--2--sort--by'>
                        <p>Sort By: Featured</p>
                    </div>
                    <div className='Arsia--collection--below--content--filter--2--feature'>
                        <p>Filter</p>
                    </div>
            </div>
            </div> */}
            <div className='Arsia--collection--below--content--part'>
                <div className='Arsia--collection--all-cards--1'>
                  <Link to={"/laila-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection'>
                        <img src={laila}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Laila | Hazel</p>
                            {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,200</p>
                        </div>
                    </div>
                    </Link>
                    <Link to={"/Black-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection'>
                        <img src={blackdrama}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Black Drama | Hazel</p>
                            {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹14,290</p>
                        </div>
                    </div>
                    </Link>
                  
                    <Link to={"/Fiza-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection'>
                        <img src={fizacoll}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Fiza | Hazel</p>
                            {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,000</p>
                        </div>
                    </div>
                    </Link>
                  
                    <Link to={"/Khubsurat-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection'>
                        <img src={khubsurat}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Khoobsurat | Hazel</p>
                            {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,000</p>
                        </div>
                    </div>
                    </Link>
                   
                    
                </div>
            </div>
          
         <ArsiaFooter/>
            </div>

           {/* Arsia--Mobile--view */}
      <div className="Arsia--website--design--mobile--view">
       <ArsiaHeaderMobile/>
           <div className='Arsia--banner--1'>
                <img src={ashandmobile}></img>
            </div>
            <div className='Arsia--collection--banner--below--content'>
                <p className='Arsia--collection--banner--below--content--p1'>More Than Fashion – It's a Mood Revolution</p>
            </div>
            {/* <div className='Arsia--collection--below--content--filter'>
                <div className='Arsia--collection--below--content--filter--1'>
                    <img src={Menu}></img>
                    <img src={grid}></img>
                    <div className='Arsia--collection--border-line'></div>
                </div>
                <div className='Arsia--collection--below--content--filter--2'>
                    <div className='Arsia--collection--below--content--filter--2--sort--by'>
                        <p>Sort By</p>
                    </div>
                    <div className='Arsia--collection--below--content--filter--2--feature'>
                        <p>Filter</p>
                    </div>
            </div>
            </div> */}
            <div className='Arsia--collection--all-cards--1'>
            <Link to={"/laila-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection--page--8'>
                        <img src={laila}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Laila | Hazel</p>
                            {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,200</p>
                        </div>
                    </div>
                    </Link>
                    <Link to={"/Black-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection--page--8'>
                        <img src={blackdrama}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Black Drama | Hazel</p>
                            {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹14,290</p>
                        </div>
                    </div></Link>
                  
                    <Link to={"/Fiza-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection--page--8'>
                        <img src={fizacoll}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Fiza | Hazel</p>
                            {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,000</p>
                        </div>
                    </div>
                    </Link>
                   
                    <Link to={"/Khubsurat-Hazel"} className='arsia--product--link'>
                    <div className='Arsia--below--content--card--carousal--1--collection--page--8'>
                        <img src={khubsurat}></img>
                        <div className='Arsia--below--content--card--carousal--1--price'>
                            <p className='Arsia--below--content--card--carousal--1--price--p1--2'>Khoobsurat | Hazel</p>
                            {/* <p className='Arsia--below--content--card--carousal--1--price--p2--2'>Xs  S  M  L  XL  XXL   </p> */}
                            <p className='Arsia--below--content--card--carousal--1--price--p3--2'>₹15,000</p>
                        </div>
                    </div>
                    </Link>
                    </div>
      
        
           <FooterMobile/>
      </div>
      {/* Arsia--Mobile--view--end */}
           
        </div>
        
    );

}