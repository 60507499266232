import { objectToFormData } from "../utils/helper";

export const addAddress = async (address) => {
  const token = localStorage.getItem("authToken");

  const myHeaders = new Headers();

  const formdata = objectToFormData({ ...address, Authorization: token });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const res = await fetch(
      "https://admin.arshiyasehgal.com/API/User/addAddress",
      requestOptions
    );
    const data = await res.json();
    console.log(data);
    if (data.status !== true && data.message !== "Data not found")
      throw new Error(data.message);
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getAddress = async () => {
  const token = localStorage.getItem("authToken");
  const myHeaders = new Headers();

  const formdata = objectToFormData({Authorization:token });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const res = await fetch("https://admin.arshiyasehgal.com/API/User/getAddress", requestOptions);
    const data = await res.json();
    console.log(data);
    if (data.status !== true && data.message !== "Data not found")
      throw new Error(data.message);
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const deleteAddress = async (address_id) => {
  const token = localStorage.getItem("authToken");
  const myHeaders = new Headers();

  const formdata = objectToFormData({ address_id, Authorization: token });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  try {
    const res = await fetch("https://admin.arshiyasehgal.com/API/User/deleteAddress", requestOptions);
    const data = await res.json();
    console.log(data);
    if (data.status !== true) throw new Error(data.message);
    return data;
  } catch (err) {
    throw new Error(err.message);
  }
};
