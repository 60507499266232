import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";

import { signUp as apiSignUP } from "../../services/apiUser";

export const useSignUp = () => {
  const queryClient = useQueryClient();

  const { mutate: signUp, isPending: isSignUp } = useMutation({
    mutationFn: apiSignUP,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
      //   localStorage.setItem("authToken", res.token);
      toast.success(res.message);
    },
    onError: (err) => {
      console.log(err);
      toast.error(err.message);
    },
  });

  return { signUp, isSignUp };
};
