import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addAddress as apiAddAddredss } from "../../services/apiAddress";
import { toast } from "react-hot-toast";

export const useAddAddress = () => {
  const queryClient = useQueryClient();

  const { mutate: addAddress, isPending: isAdding } = useMutation({
    mutationFn: (data) => apiAddAddredss(data),
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["address"],
      });
      console.log(res);
      toast.success("Address add successfully.");
    },
    onError: (err) => {
      console.log(err);
      toast.error(err.message);
    },
  });

  return { addAddress, isAdding };
};
