import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";

import { otpVerify as apiOtpVerify } from "../../services/apiUser";
import { userAuthenticate, userVerify } from "./userSlice";

export const useOtpVerify = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate: verifyOtp, isPending: isVerify } = useMutation({
    mutationFn: apiOtpVerify,
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
      localStorage.setItem("isAuthenticate", true);
      toast.success("OTP verify successfully.");
      dispatch(userAuthenticate(true));
      dispatch(userVerify(res.data.user));
    },
    onError: (err) => {
      console.log(err);
      toast.error("OTP invailed please enter correct OTP");
    },
  });

  return { verifyOtp, isVerify };
};
